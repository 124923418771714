import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Col, Form, Input, Modal, notification, Row, Skeleton, Switch } from "antd";
import dayjs from "dayjs";
import { RRule } from "rrule";

import AppointmentTypePicker from "@/components/AppointmentTypePicker";
import LocationTransfer from "@/components/LocationTransfer";
import MonthPicker from "@/components/MonthPicker";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import ContractVisitsQuery from "../graphql/ContractVisitsQuery";
import { mapFormValuesToFlags, removeFlagsFromFormValues } from "../mapFormValuesToFlags";
import { FlagsInputBlock } from "./FlagsInputBlock";
import FrequencySelect from "./FrequencySelect";
import RecurrencesPreviewTable from "./RecurrencesPreviewTable";

interface AddVisitModalProps {
  contractId: string;
  onClose: () => void;
}

export default function AddVisitModal({ contractId, onClose }: AddVisitModalProps) {
  const [formInstance] = Form.useForm();

  const { data } = useQuery(ContractQuery, { variables: { contractId } });
  const [addVisitMutationAsync, { loading: isSubmitting }] = useMutation(AddVisitMutation, {
    refetchQueries: [ContractVisitsQuery],
  });

  const _startingOn = Form.useWatch("startingOn", formInstance);
  const _endingOn = Form.useWatch("endingOn", formInstance);
  const _interval = Form.useWatch("interval", formInstance);
  const _overrideDefaultFlags = Form.useWatch("overrideDefaultFlags", formInstance);

  const handleOnSubmit = async ({ locationIds, startingOn, endingOn, ...submitProps_ }) => {
    const submitProps = removeFlagsFromFormValues(submitProps_, "flags");

    if (submitProps.overrideDefaultFlags) {
      const [flags] = mapFormValuesToFlags(submitProps_, "flags");
      submitProps.flags = flags;
    }

    submitProps.recurrence = new RRule({
      interval: submitProps.interval,
      bymonth: [startingOn.month() + 1],
      freq: RRule.YEARLY,
    }).toString();

    delete submitProps.interval;

    try {
      await Promise.all(
        locationIds.map(locationId =>
          addVisitMutationAsync({
            variables: {
              contractId,
              locationId,
              // set the starting date to the first day of the month
              startingOn: startingOn as dayjs.Dayjs,
              endingOn: endingOn ? (endingOn as dayjs.Dayjs) : null,
              ...submitProps,
            },
          })
        )
      );

      notification.success({ message: "Bezoek is toegevoegd" });
      onClose();
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const relationId: string = data?.contract.relation.id;
  const contractEndingOn: string | null = data?.contract.endingOn;

  return (
    <Modal confirmLoading={isSubmitting} onCancel={onClose} onOk={() => formInstance.submit()} title="Bezoek toevoegen" open width="75%">
      <Form
        form={formInstance}
        initialValues={{ interval: 1, overrideDefaultFlags: false }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={handleOnSubmit}
      >
        {undefined === relationId ? (
          <Skeleton />
        ) : (
          <Row justify="space-between">
            <Col span={11}>
              <Form.Item label="Locaties" name="locationIds" rules={[{ required: true }]}>
                <LocationTransfer relationId={relationId} listStyle={{ width: "50%", minHeight: 300 }} />
              </Form.Item>
              <Form.Item label="Opmerking" name="comment">
                <Input.TextArea rows={3} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Soort afspraak" name="appointmentTypeId" rules={[{ required: true }]}>
                <AppointmentTypePicker />
              </Form.Item>
              <Form.Item label="Eerste afspraak" name="startingOn" rules={[{ required: true }]}>
                <MonthPicker format="MMMM, YYYY" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                label="Stopzetten per"
                name="endingOn"
                rules={[
                  { required: !!contractEndingOn },
                  {
                    validator: (_, value) => {
                      if (!value || !contractEndingOn) return Promise.resolve();
                      if ((value as dayjs.Dayjs).isAfter(contractEndingOn))
                        return Promise.reject(new Error("Moet voor einddatum van contract zijn"));

                      return Promise.resolve();
                    },
                  },
                ]}
                extra={
                  contractEndingOn
                    ? `Kan niet later zijn dan de einddatum van het contract (${dayjs(contractEndingOn).format("LL")})`
                    : undefined
                }
              >
                <MonthPicker allowClear format="MMMM, YYYY" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item label="Frequentie" name="interval" rules={[{ required: true }]}>
                <FrequencySelect />
              </Form.Item>
              <Form.Item name="overrideDefaultFlags" label="Andere instellingen" rules={[{ required: true }]}>
                <Switch />
              </Form.Item>
              {_overrideDefaultFlags && <FlagsInputBlock prefix="flags" />}
              {_startingOn && _interval && (
                <div style={{ paddingLeft: 52 }}>
                  <RecurrencesPreviewTable
                    startingOn={_startingOn.utcOffset(0, true).startOf("month")}
                    endingOn={_endingOn ? _endingOn.utcOffset(0, true).startOf("month") : undefined}
                    repeat={[(_startingOn as dayjs.Dayjs).month() + 1]}
                    interval={_interval}
                  />
                </div>
              )}
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
}

const ContractQuery = gql`
  query ContractQuery($contractId: ID!) {
    contract(id: $contractId) {
      id
      relation {
        id
      }
      endingOn
    }
  }
`;

const AddVisitMutation = gql`
  mutation (
    $contractId: ID!
    $appointmentTypeId: ID!
    $locationId: ID!
    $recurrence: String!
    $startingOn: DateTime!
    $endingOn: DateTime
    $comment: String
    $overrideDefaultFlags: Boolean!
    $flags: [ContractFlag!]
  ) {
    addVisit(
      input: {
        contractId: $contractId
        appointmentTypeId: $appointmentTypeId
        locationId: $locationId
        recurrence: $recurrence
        startingOn: $startingOn
        endingOn: $endingOn
        comment: $comment
        overrideDefaultFlags: $overrideDefaultFlags
        flags: $flags
      }
    ) {
      contract {
        id
        visits {
          id
          appointmentType {
            id
            name
            category {
              id
              name
            }
          }
          startingOn
          endingOn
          location {
            id
            name
            address {
              street
              postalCode
              city
              country
            }
          }
          recurrence
          overrideDefaultFlags
          flags
        }
      }
    }
  }
`;
