import "./layout.css";

import { Card } from "antd";
import * as React from "react";

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div className="login-background">
      <Card bordered={false} style={{ paddingTop: 24, flex: 1 / 3 }}>
        {children}
      </Card>
    </div>
  );
}
