import { ApolloError, gql, useMutation } from "@apollo/client";
import { Button, Card, Form, Input, notification, Steps, Tabs } from "antd";
import { useState } from "react";

import AddressCompleter from "@/components/AddressCompleter";
import AdministrationPicker from "@/components/AdministrationPicker";
import LocalePicker from "@/components/LocalePicker";
import RelationPicker from "@/components/RelationPicker";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

function parseInitialValues({ __typename, ...relation }: object) {
  return {
    ...relation,
    locations: relation.locations.map(({ __typename, ...location }) => ({
      ...location,
      email: undefined,
      primaryEmail: location.email[0] ?? null,
      secondaryEmail: location.email[1] ?? null,
      phoneNumber: location.phoneNumber[0] ?? null,
      mobilePhoneNumber: location.phoneNumber[1] ?? null,
      addressExtraDetails: location.address.extraDetails,
      address: {
        ...location.address,
        __typename: undefined,
        extraDetails: undefined,
      },
    })),
  };
}

export default function ImportRelation() {
  const [form] = Form.useForm();
  const [importAsync, { data, loading: isImporting }] = useMutation(ImportRelationMutation);
  const [registerAsync, { loading: isSubmitting }] = useMutation(RegisterRelationMutation);

  const [initialValues, setInitialValues] = useState<object | undefined>();
  const [currentStep, setCurrentStep] = useState<"receive" | "append">("receive");

  const handleOnImport = async values => {
    if (!values.administrationId || !values.relationCode) {
      return;
    }

    try {
      const relationCode = Number.parseInt(values.relationCode, 10);

      const response = await importAsync({
        variables: {
          ...values,
          relationCode,
        },
      });

      setCurrentStep("append");
      setInitialValues(parseInitialValues({ ...response.data.importRelation.relation, afasCode: relationCode }));
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
      setInitialValues(undefined);
    }
  };

  const handleOnSubmit = async values => {
    try {
      await registerAsync({
        variables: {
          ...values,
          locations: values.locations.map(location => ({
            ...location,
            addressExtraDetails: undefined,
            address: {
              ...location.address,
              extraDetails: location.addressExtraDetails,
            },
          })),
        },
      });

      notification.success({ message: "Relatie geregistreerd" });

      setCurrentStep("receive");
      setInitialValues(undefined);
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <>
      <Steps
        current={currentStep === "receive" ? 0 : 1}
        items={[{ title: "Klantgegevens ophalen" }, { title: "Klantgegevens aanvullen" }]}
        style={{ paddingInline: 42, marginTop: 12, marginBottom: 22 }}
      />
      {/* eslint-disable-next-line prettier/prettier */}
      {currentStep === "receive" && (
        <Form onFinish={handleOnImport} layout="horizontal" labelCol={{ span: 2 }} wrapperCol={{ span: 6 }}>
          <Card>
            <Form.Item name="administrationId" label="Administratie" required>
              <AdministrationPicker />
            </Form.Item>
            <Form.Item name="relationCode" label="AFAS-code" required>
              <Input type="number" min={1} addonAfter="#" />
            </Form.Item>
          </Card>
          <Button loading={isImporting} htmlType="submit" type="primary" style={{ marginTop: 12 }}>
            Zoeken
          </Button>
        </Form>
      )}
      {currentStep === "append" && undefined !== data && (
        <Form form={form} initialValues={initialValues} onFinish={handleOnSubmit} layout="vertical">
          <Card>
            <div style={{ display: "flex", flexDirection: "row", columnGap: 22 }}>
              <div style={{ flex: 1 }}>
                <Form.Item name="name" label="Naam">
                  <Input disabled />
                </Form.Item>
                <Form.Item name="place" label="Vestigingslocatie">
                  <Input disabled />
                </Form.Item>
                <Form.Item name="afasCode" label="AFAS">
                  <Input disabled type="number" addonAfter="#" />
                </Form.Item>
                <Form.Item name="belongsToId" label="Dealer-klant van">
                  <RelationPicker />
                </Form.Item>
              </div>
              <div style={{ flex: 1 }}>
                <Form.Item name="planningComment" label="Planning-notities">
                  <Input.TextArea />
                </Form.Item>

                <Form.Item name="onSiteComment" label="Buitendienst-notities">
                  <Input.TextArea />
                </Form.Item>
              </div>
            </div>
          </Card>
          <Form.List name="locations">
            {elements => (
              <Card style={{ marginTop: 22 }}>
                <Tabs
                  tabPosition="left"
                  items={elements.map(({ key, name: formIndex }) => {
                    const name = form.getFieldValue(["locations", formIndex, "name"]);

                    return {
                      label: name,
                      key: String(key),
                      children: (
                        <>
                          <div style={{ display: "flex", flexDirection: "row", columnGap: 22 }}>
                            <div style={{ flex: 1 }}>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Form.Item
                                  label="AFAS"
                                  name={[formIndex, "afasCode"]}
                                  style={{ width: "20%" }}
                                  normalize={value => Number(value)}
                                >
                                  <Input className="input-number-hidden-spinbox" inputMode="numeric" type="number" />
                                </Form.Item>
                                <div style={{ width: 10 }} />
                                <Form.Item
                                  label="Naam"
                                  name={[formIndex, "name"]}
                                  required
                                  style={{ flexGrow: 1 }}
                                  rules={[{ required: true }]}
                                >
                                  <Input />
                                </Form.Item>
                              </div>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                <Form.Item label="Adres" name={[formIndex, "address"]} required rules={[{ required: true }]}>
                                  <AddressCompleter />
                                </Form.Item>
                                <Form.Item label="Toevoegingen (gebouw, ...)" name={[formIndex, "addressExtraDetails"]}>
                                  <Input />
                                </Form.Item>
                              </div>
                              <Form.Item label="Contactpersoon" name={[formIndex, "contactPerson"]} required rules={[{ required: true }]}>
                                <Input />
                              </Form.Item>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                <Form.Item label="Telefoonnummer" name={[formIndex, "phoneNumber"]} required>
                                  <Input type="tel" />
                                </Form.Item>
                                <Form.Item label="Mobiele telefoonnummer" name={[formIndex, "mobilePhoneNumber"]}>
                                  <Input type="tel" />
                                </Form.Item>
                              </div>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                <Form.Item
                                  label="Eerste e-mail adres"
                                  name={[formIndex, "primaryEmail"]}
                                  required
                                  rules={[{ required: true }]}
                                >
                                  <Input type="email" />
                                </Form.Item>
                                <Form.Item label="Tweede e-mail adres" name={[formIndex, "secondaryEmail"]}>
                                  <Input type="email" />
                                </Form.Item>
                              </div>
                              <Form.Item label="Taal" name={[formIndex, "locale"]} required rules={[{ required: true }]}>
                                <LocalePicker />
                              </Form.Item>
                            </div>
                            <div style={{ flex: 1 }}>
                              <Form.Item label="Planning-notities" name={[formIndex, "planningComment"]}>
                                <Input.TextArea rows={3} />
                              </Form.Item>
                              <Form.Item label="Buitendienst-notities" name={[formIndex, "onSiteComment"]}>
                                <Input.TextArea rows={3} />
                              </Form.Item>
                            </div>
                          </div>
                        </>
                      ),
                    };
                  })}
                />
              </Card>
            )}
          </Form.List>
          <Button loading={isSubmitting} htmlType="submit" type="primary" style={{ marginTop: 12 }}>
            Opslaan
          </Button>
        </Form>
      )}
    </>
  );
}

const ImportRelationMutation = gql`
  mutation ($administrationId: ID!, $relationCode: Int!) {
    importRelation(input: { administrationId: $administrationId, code: $relationCode }) {
      relation {
        id
        name
        place
        locations {
          name
          contactPerson
          phoneNumber
          email
          locale
          address {
            street
            postalCode
            city
            country
          }
        }
      }
    }
  }
`;

const RegisterRelationMutation = gql`
  mutation ($name: String!, $place: String!, $afasCode: Int, $belongsToId: ID, $locations: [RegisterRelationLocationInput!]!) {
    registerRelation(input: { name: $name, place: $place, afasCode: $afasCode, belongsToId: $belongsToId, locations: $locations }) {
      relation {
        id
      }
    }
  }
`;
