import { FormInstance } from "antd/lib";

export function createChangedFormValues(values: Record<string, unknown>, isFieldTouched: FormInstance["isFieldTouched"]) {
  const keys = Object.keys(values);
  const initialValue = {} as typeof values;

  return keys.reduce((accumulator, field) => {
    if (isFieldTouched(field)) {
      accumulator[field] = values[field];
    }

    return accumulator;
  }, initialValue);
}
