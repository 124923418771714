import { gql, useQuery } from "@apollo/client";
import { Select, SelectProps } from "antd";
import { BaseSelectRef } from "rc-select";
import { forwardRef, useMemo } from "react";

interface ProductTypeConsumablePickerProps extends Omit<SelectProps, "onChange" | "value"> {
  consumableForId: string;
  onChange?: (productTypeId: string | undefined, productType?: Record<string, string | boolean>) => void;
  value?: string;
}

function ProductTypeConsumablePicker(
  { consumableForId, onChange, value, ...restProps }: ProductTypeConsumablePickerProps,
  ref?: React.Ref<BaseSelectRef>
) {
  const { data, loading } = useQuery(ProductTypeQuery, {
    variables: { consumableForId },
    onCompleted: ({ productTypes }) => {
      if (undefined === value) return;

      const match = productTypes.find(p => p.id === value);
      if (undefined === match) onChange?.(undefined);
    },
  });

  const options = useMemo(
    () =>
      [...(data?.productTypes ?? [])]
        .filter(o => false === o.isHidden)
        .sort((a, b) => {
          if (typeof a.code !== typeof b.code) {
            if (typeof a.code === "string") return 1;
            if (typeof b.code === "string") return -1;

            return 0;
          }

          return Number(a.code) - Number(b.code);
        })
        .map(o => {
          let label = o.description;
          if (null !== o.code) label = `(${o.code}) ${label}`;

          return { value: o.id, label };
        }) ?? [],
    [data]
  );

  const handleOnChange = (productTypeId: string) => {
    const productType = data?.productTypes.find(o => o.id === productTypeId);
    if (undefined === productType) return;

    onChange?.(productTypeId, productType);
  };

  return <Select {...restProps} ref={ref} onChange={handleOnChange} options={options} loading={loading} value={value} />;
}

const ProductTypeQuery = gql`
  query ($consumableForId: ID!) {
    productTypes(consumableForId: $consumableForId) {
      id
      code
      description
      serialCodeExpr
      productionBatchExpr
      isExpirationDateRequired
      isManufacturingDateRequired
      hasConsumables
      hasBrand
      majorMaintenanceInterval
      expectedLifespan
      isHidden
    }
  }
`;

export default forwardRef(ProductTypeConsumablePicker);
