import { Table } from "antd";
import dayjs from "dayjs";
import React from "react";
import { FormattedMessage } from "react-intl";

import TableFilterDropdown from "../../../components/TableFilterDropdown";
import simpleTableFilterSorter from "../../../functions/simple-table-filter-sorter";

const rowKeyExtractor = issue => issue.id;

function mapUnique(values) {
  return [...new Map(values.map(item => [item.value, item])).values()];
}

function collectRelationsForFilter(relations) {
  return mapUnique(
    relations.map(relation => ({
      text: relation.name,
      value: relation.id,
    }))
  ).sort(simpleTableFilterSorter);
}

function collectRelationsAfasCodesForFilter(relations) {
  return mapUnique(
    relations.map(relation => ({
      text: null !== relation.afasCode ? relation.afasCode.toString() : null,
      value: relation.id,
    }))
  ).sort(simpleTableFilterSorter);
}

const collectUniqueLocations = issues =>
  [...new Set(issues.map(item => item.location.name))].map(item => ({ text: item, value: item })).sort(simpleTableFilterSorter);

const collectUsersOn = field => issues =>
  [...new Set(issues.map(item => item[field]))]
    .filter(item => item !== null)
    .map(item => ({ text: item.username, value: item.id }))
    .sort(simpleTableFilterSorter);

export default function IssueTable({ onClick, onTableChange, loading, issues }) {
  const relationColumn = issues.map(i => i.relation);

  const columns = [
    {
      title: <FormattedMessage id="issue.createdOn" />,
      dataIndex: "createdOn",
      render: createdOn => dayjs(createdOn).format("DD/MM/YYYY"),
      defaultSortOrder: "descend",
      sorter: (b, a) => new Date(b.createdOn) - new Date(a.createdOn),
    },
    {
      title: <FormattedMessage id="relation.afasCode" />,
      dataIndex: "relation",
      render: relation => relation.afasCode,
      filterDropdown: props => <TableFilterDropdown {...props} dataSource={collectRelationsAfasCodesForFilter(relationColumn)} />,
      onFilter: (value, record) => record.relation.id === value,
    },
    {
      title: <FormattedMessage id="relation" />,
      dataIndex: ["relation", "name"],
      filterDropdown: props => <TableFilterDropdown {...props} dataSource={collectRelationsForFilter(relationColumn)} />,
      onFilter: (value, record) => record.relation.id === value,
    },
    {
      title: <FormattedMessage id="location" />,
      dataIndex: ["location", "name"],
      onFilter: (value, record) => record.location.name === value,
      filterDropdown: props => <TableFilterDropdown {...props} dataSource={collectUniqueLocations(issues)} />,
    },
    {
      title: <FormattedMessage id="issue.subject" />,
      dataIndex: "subject",
    },
    {
      title: <FormattedMessage id="issue.createdBy" />,
      dataIndex: ["createdBy", "username"],
      onFilter: (value, record) => record.createdBy.id === value,
      filterDropdown: props => <TableFilterDropdown {...props} dataSource={collectUsersOn("createdBy")(issues)} />,
    },
    {
      title: "Bestemd voor",
      dataIndex: ["assignedTo", "username"],
      onFilter: (value, record) => record.assignedTo.id === value,
      filterDropdown: props => <TableFilterDropdown {...props} dataSource={collectUsersOn("assignedTo")(issues)} />,
    },
    {
      title: <FormattedMessage id="issue.status" />,
      dataIndex: "status",
      render: status => <FormattedMessage id={`issue.status.${status}`} />,
      filters: [
        {
          text: <FormattedMessage id="issue.status.STATUS_RAISED" />,
          value: "STATUS_RAISED",
        },
        {
          text: <FormattedMessage id="issue.status.STATUS_PENDING" />,
          value: "STATUS_PENDING",
        },
        {
          text: <FormattedMessage id="issue.status.STATUS_RESOLVED" />,
          value: "STATUS_RESOLVED",
        },
      ],
    },
  ];

  const onRow = record => ({
    onClick: () => onClick(record.id),
  });

  return (
    <Table
      bordered
      columns={columns}
      dataSource={issues}
      loading={loading}
      onChange={onTableChange}
      onRow={onRow}
      rowKey={rowKeyExtractor}
    />
  );
}
