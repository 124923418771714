import { gql } from "@apollo/client";

export default gql`
  query ($relationId: ID!) {
    relation(id: $relationId) {
      id
      locations {
        id
        name
        deletedAt
        address {
          street
          postalCode
          city
          country
        }
      }
    }
  }
`;
