import { gql } from "@apollo/client";

export default gql`
  mutation ($administrationId: ID!, $worksheetId: ID!) {
    generateDirectInvoice(input: { administrationId: $administrationId, worksheetId: $worksheetId }) {
      worksheet {
        id
        invoicedOn
      }
    }
  }
`;
