import * as React from "react";

import initialValues from "./initial-values";

type InitialValues = typeof initialValues;
interface AuthenticationContextProps extends InitialValues {
  resetPassword: (token: string, plainPassword: string) => Promise<void>;
  checkPasswordResetToken: (token: string) => Promise<string>;
  requestPasswordReset: (emailAddress: string) => Promise<void>;
  loginUser: (emailAddress: string, password: string) => Promise<void>;
  logoutUser: () => Promise<void>;
}

const AuthenticationContext = React.createContext<AuthenticationContextProps>({
  ...initialValues,
  loginUser: () => Promise.reject(),
  logoutUser: () => Promise.reject(),
  requestPasswordReset: () => Promise.reject(),
  checkPasswordResetToken: () => Promise.reject(),
  resetPassword: () => Promise.reject(),
});

export default AuthenticationContext;
