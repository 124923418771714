import { SearchOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Flex, Input, Select, Space, Table } from "antd";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import Highlighter from "react-highlight-words";

import EditProductModal from "@/components/EditProductModal";
import PageHeader from "@/components/PageHeader";
import formatProductDescription from "@/functions/format-product-description";
import useDebounce from "@/hooks/use-debounce";

export default function SearchProduct() {
  const [searchQuery, setSearchQuery] = useState("");
  const [field, setField] = useState<"serialCode" | "productionBatch">("serialCode");
  const debouncedSearchQuery = useDebounce(searchQuery);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [selectedProductId, setSelectedProductId] = useState<string>();

  const { data, loading } = useQuery(SearchQuery, {
    variables: { offset, limit: PAGE_SIZE, ...filters, [field]: debouncedSearchQuery },
    skip: searchQuery.length < 3,
  });

  const columns = useMemo(
    () => [
      {
        title: "Relatie",
        key: "location",
        children: [
          {
            title: "Relatie",
            dataIndex: ["location", "relation"],
            render: relation => (relation.afasCode !== null ? `(${relation.afasCode}) ${relation.name}` : relation.name),
          },
          {
            title: "Locatie",
            dataIndex: ["location", "name"],
          },
        ],
      },
      {
        title: "Product",
        key: "product",
        children: [
          {
            title: "Type",
            key: "productType",
            render: (_, record) => formatProductDescription(record),
          },
          {
            title: "Serienummer",
            dataIndex: "serialCode",
            render: serialCode =>
              serialCode ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                  searchWords={[searchQuery]}
                  autoEscape
                  textToHighlight={serialCode}
                />
              ) : (
                serialCode
              ),
          },
        ],
      },
      {
        title: "Onderhoud",
        key: "maintenance",
        children: [
          {
            title: "Laatst gecontroleerd",
            key: "lastCheckedOn",
            render: product => dayjs(product.lastCheckedOn).format("DD-MM-YYYY"),
          },
          {
            title: "Status",
            dataIndex: "condition",
            filters: STATUS_LIST,
            render: condition => STATUS_LIST.find(s => s.value === condition)?.text ?? "Onbekend",
          },
          {
            title: "Monteur",
            dataIndex: ["latestWorksheetItem", "worksheet", "employee", "username"],
          },
        ],
      },
    ],
    [searchQuery]
  );

  return (
    <>
      {undefined !== selectedProductId && (
        // eslint-disable-next-line prettier/prettier
        <EditProductModal onClose={() => setSelectedProductId(undefined)} productId={selectedProductId} />
      )}
      <Space direction="vertical">
        <PageHeader bordered title="Zoeken">
          <Flex align="center" gap="small">
            <Select
              onChange={nextValue => {
                setField(nextValue);
              }}
              options={[
                { label: "Serienummer", value: "serialCode" },
                { label: "Productiebatch", value: "productionBatch" },
              ]}
              style={{
                minWidth: 200,
              }}
              value={field}
            />
            =
            <Input onChange={e => setSearchQuery(e.target.value)} suffix={<SearchOutlined />} value={searchQuery} style={{ width: 300 }} />
          </Flex>
        </PageHeader>
        <Table
          bordered
          columns={columns}
          dataSource={data?.servicedProducts?.edges ?? []}
          loading={loading}
          onChange={(pagination, filters, _sorter) => {
            setOffset(((pagination.current ?? 1) - 1) * (pagination.pageSize ?? PAGE_SIZE));

            setFilters(current => ({
              ...current,
              condition: (filters["condition"] as string[]) ?? DEFAULT_FILTERS.condition,
            }));
          }}
          onRow={useCallback(record => ({ onClick: () => setSelectedProductId(record.id) }), [setSelectedProductId])}
          rowKey={product => product.id}
          title={() => `Zoekresultaten (totaal gevonden: ${data?.servicedProducts?.totalCount ?? 0})`}
          pagination={{ total: data?.servicedProducts?.totalCount ?? 0, pageSize: PAGE_SIZE }}
        />
      </Space>
    </>
  );
}

const SearchQuery = gql`
  query ($serialCode: String, $productionBatch: String, $condition: [ProductCondition!], $offset: Int!, $limit: Int!) {
    servicedProducts(serialCode: $serialCode, productionBatch: $productionBatch, condition: $condition, offset: $offset, limit: $limit) {
      totalCount
      edges {
        id
        productType {
          id
          code
          description
        }
        brand
        optionalDescription
        serialCode
        location {
          id
          name
          relation {
            id
            afasCode
            name
          }
        }
        lastCheckedOn
        condition
        latestWorksheetItem {
          id
          worksheet {
            id
            employee {
              id
              username
            }
          }
        }
      }
    }
  }
`;

const PAGE_SIZE = 10;

const STATUS_LIST = [
  { text: "Goed", value: "CONDITION_GOOD" },
  { text: "Actie vereist", value: "CONDITION_ACTION_REQUIRED" },
  { text: "Verwijderd", value: "CONDITION_DESTROYED" },
  { text: "Onbekend", value: "CONDITION_UNKNOWN" },
];

const DEFAULT_FILTERS = {
  condition: ["CONDITION_GOOD", "CONDITION_ACTION_REQUIRED", "CONDITION_UNKNOWN"],
};
