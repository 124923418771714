import { ToolOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Checkbox, Form, Input, Modal, notification } from "antd";

import EmployeePicker from "@/components/EmployeePicker";
import LocationPicker from "@/components/LocationPicker";
import MarkdownEditor from "@/components/MarkdownTextInput";
import RelationPicker from "@/components/RelationPicker";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import ApppointmentPicker from "./AppointmentPicker";
import RelatedProductsSelector from "./RelatedProductsSelector";

interface RegisterIssueModalValues {
  relationId?: string;
  locationId?: string;
  relatedAppointmentId?: string;
  assignedEmployeeId?: string;
  subject?: string;
  comment?: string;
  publiclyAccessible?: string;
}

interface RegisterIssueModalProps {
  onClose: () => void;
  initialValues?: RegisterIssueModalValues;
}

export default function RegisterIssueModal({ onClose, initialValues }: RegisterIssueModalProps) {
  const [formInstance] = Form.useForm();

  const relationId = Form.useWatch("relationId", formInstance);
  const locationId = Form.useWatch("locationId", formInstance);
  const relatedAppointmentId = Form.useWatch("relatedAppointmentId", formInstance);

  const [registerIssueAsync, { loading }] = useMutation(RegisterIssueMutation);

  const { data } = useQuery(WorksheetQuery, {
    variables: { appointmentId: relatedAppointmentId },
    skip: undefined === relatedAppointmentId,
  });

  const handleOnSubmit = async (values: Record<string, unknown>) => {
    try {
      await registerIssueAsync({
        variables: {
          relationId,
          locationId,
          ...values,
        },
      });

      onClose();
      notification.success({ message: "Melding is toegevoegd" });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Modal
      centered
      confirmLoading={loading}
      onCancel={onClose}
      onOk={() => formInstance.submit()}
      styles={{
        body: {
          overflowY: "auto",
          maxHeight: window.innerHeight - 168,
        },
      }}
      title="Melding registeren"
      open
    >
      <Form form={formInstance} onFinish={handleOnSubmit} layout="vertical" initialValues={initialValues}>
        <Form.Item name="relationId" label="Relatie" rules={[{ required: true }]}>
          <RelationPicker />
        </Form.Item>
        <Form.Item name="locationId" label="Locatie" rules={[{ required: true }]}>
          {undefined === relationId ? <Input disabled /> : <LocationPicker relationId={relationId} />}
        </Form.Item>
        <Form.Item name="relatedProducts" label="Gerelateerde producten">
          {undefined !== relationId && undefined !== locationId ? (
            <RelatedProductsSelector multiple relationId={relationId} locationId={locationId} />
          ) : (
            <Input disabled />
          )}
        </Form.Item>
        <Form.Item
          name="relatedAppointmentId"
          label="Gerelateerde afspraak"
          extra={
            undefined !== relatedAppointmentId &&
            undefined !== data && (
              <>
                <ToolOutlined />
                <span style={{ margin: "0 4px" }}>
                  {data.worksheet
                    ? `Onderhoudsbeurt gevonden met ${data.worksheet.numberOfItems} werkzaamheden, ${data.worksheet.numberOfRelatedIssues} meldingen`
                    : "Geen onderhoudsbeurt gevonden"}
                </span>
              </>
            )
          }
        >
          {undefined === locationId ? <Input disabled /> : <ApppointmentPicker relationId={relationId} locationId={locationId} />}
        </Form.Item>
        <Form.Item name="subject" label="Titel" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item valuePropName="initialValue" name="comment" label="Melding" rules={[{ required: true }]}>
          <MarkdownEditor />
        </Form.Item>
        <Form.Item name="assignedEmployeeId" label="Toewijzen aan" rules={[{ required: true }]}>
          <EmployeePicker />
        </Form.Item>
        <Form.Item name="publiclyAccessible" valuePropName="checked" initialValue={false}>
          <Checkbox>Zichtbaar voor relatie in klantenportaal</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}

const WorksheetQuery = gql`
  query ($appointmentId: ID!) {
    worksheet(appointmentId: $appointmentId) {
      id
      numberOfItems
      numberOfRelatedIssues
    }
  }
`;

const RegisterIssueMutation = gql`
  mutation (
    $relationId: ID!
    $locationId: ID!
    $relatedAppointmentId: ID
    $relatedProducts: [ID!]
    $subject: String!
    $comment: String!
    $publiclyAccessible: Boolean!
    $assignedEmployeeId: ID!
  ) {
    raiseIssue(
      input: {
        relationId: $relationId
        locationId: $locationId
        relatedAppointmentId: $relatedAppointmentId
        relatedProductIds: $relatedProducts
        subject: $subject
        comment: $comment
        publiclyAccessible: $publiclyAccessible
        assignedEmployeeId: $assignedEmployeeId
      }
    ) {
      issue {
        id
      }
    }
  }
`;
