import { gql } from "@apollo/client";

export default gql`
  query IssuesQuery($assignedToId: ID!, $status: [IssueStatus!]) {
    issues(assignedToId: $assignedToId, status: $status) {
      id
      relation {
        id
        name
        afasCode
      }
      location {
        id
        name
      }
      createdOn
      createdBy {
        id
        username
      }
      subject
      status
    }
  }
`;
