import { CloseCircleOutlined, FilePdfOutlined, LoadingOutlined } from "@ant-design/icons";
import { graphql } from "@apollo/client/react/hoc";
import { Button } from "antd";
import React, { Component } from "react";

import FetchFileMutation from "./FetchFileMutation";

class File extends Component {
  state = {
    hasError: false,
    isDownloading: false,
  };

  UNSAFE_componentWillMount() {
    const { startImmediately } = this.props;

    if (startImmediately) {
      this.downloadFile();
    }
  }

  downloadFile = () => {
    const { download, file, onCompleted } = this.props;

    this.setState({
      isDownloading: true,
    });

    download(file)
      .then(({ data: { fetchFileLink } }) => {
        this.setState({
          isDownloading: false,
        });

        window.open(fetchFileLink.href, "_blank");
        onCompleted();
      })
      .catch(() => {
        this.setState({
          isDownloading: false,
          hasError: true,
        });
      });
  };

  render() {
    const { name } = this.props.file;
    const { isDownloading, hasError } = this.state;

    return (
      <Button type="primary" onClick={this.downloadFile}>
        {!hasError && (
          <span>
            {isDownloading ? <LoadingOutlined style={{ fontSize: 14 }} /> : <FilePdfOutlined style={{ fontSize: 14 }} />}
            {name}
          </span>
        )}
        {hasError && (
          <span style={{ margin: 5 }}>
            <CloseCircleOutlined style={{ fontSize: 14 }} /> {name}
          </span>
        )}
      </Button>
    );
  }
}

File.defaultProps = {
  startImmediately: false,
};

export default graphql(FetchFileMutation, {
  props: ({ mutate }) => ({
    download: ({ id }) =>
      mutate({
        variables: {
          input: {
            id,
          },
        },
      }),
  }),
})(File);
