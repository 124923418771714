import { Modal, Row } from "antd";
import React from "react";

import File from "./File";

const Downloader = ({ files, onClose }) => {
  if (files.length < 1) {
    return null;
  }

  return (
    <Modal title="Bestanden downloaden" footer={null} open onCancel={onClose} width="350px">
      <Row type="flex" justify="center">
        {files.map(file => (
          <div key={file.id} style={{ marginBottom: "10px" }}>
            <File key={file.id} file={file} onCompleted={onClose} startImmediately={files.length === 1} />
          </div>
        ))}
      </Row>
    </Modal>
  );
};

export default Downloader;
