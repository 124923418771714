import * as React from "react";

import AppointmentModal from "../AppointmentModal";
import IssueModal from "./IssueModal";

interface ShowIssueModalProps {
  onClose: () => void;
  issueId: string;
}

export default function ShowIssueModal({ onClose, issueId }: ShowIssueModalProps) {
  const [currentModal, setCurrentModal] = React.useState<"issue" | "appointment">("issue");
  const [relatedAppointmentId, setRelatedAppointmentId] = React.useState<string | undefined>();

  const handleOnRelatedWorksheetClick = (worksheetId: string) => {
    window.open("/worksheets/" + worksheetId)?.focus();
  };

  const handleOnRelatedAppointmentClick = (appointmentId: string) => {
    setRelatedAppointmentId(appointmentId);
    setCurrentModal("appointment");
  };

  return currentModal === "issue" ? (
    <IssueModal
      onClose={onClose}
      onRelatedAppointmentClick={handleOnRelatedAppointmentClick}
      onRelatedWorksheetClick={handleOnRelatedWorksheetClick}
      issueId={issueId}
    />
  ) : undefined !== relatedAppointmentId ? (
    <AppointmentModal appointmentId={relatedAppointmentId} onClose={onClose} />
  ) : (
    <React.Fragment />
  );
}
