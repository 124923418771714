import { gql } from "@apollo/client";

export default gql`
  query ($id: ID!) {
    relation(id: $id) {
      id
      name
      place
      afasCode
      planningComment
      onSiteComment
      invoiceComment
      belongsTo {
        id
        name
        afasCode
      }
      responsibleEmployee {
        id
        username
      }
      locations {
        id
        name
        afasCode
        contactPerson
        phoneNumber
        mobilePhoneNumber
        primaryEmail
        secondaryEmail
        locale
        planningComment
        onSiteComment
        invoiceComment
        deletedAt
        address {
          street
          postalCode
          city
          country
          coordinates {
            latitude
            longitude
          }
        }
      }
    }
  }
`;
