import { gql } from "@apollo/client";

export default gql`
  mutation ($worksheetId: ID!) {
    sendWorksheetReportToRelation(input: { id: $worksheetId }) {
      worksheet {
        id
        reportSentOn
      }
    }
  }
`;
