import { ContactsOutlined, TeamOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Tabs } from "antd";
import { useState } from "react";

import CreateUserModal from "@/components/CreateUserModal";
import UpdateUserModal from "@/components/UpdateUserModal";

import UserTable from "./components/UserTable";

export default function ListUsers() {
  const [visibleTab, setVisibleTab] = useState<keyof typeof USER_GROUPS>("GROUP_EMPLOYEES");
  const [isCreateUserModalVisible, setIsCreateUserModalVisible] = useState(false);
  const [activeUserId, setActiveUserId] = useState<string | undefined>();
  const [isActive, setIsActive] = useState(true);

  const { data, loading } = useQuery(UsersQuery, {
    variables: {
      onlyActive: isActive,
      userGroupId: USER_GROUPS[visibleTab],
    },
  });

  const handleOnClick = (userId: string) => {
    setActiveUserId(userId);
  };

  return (
    <>
      {undefined !== activeUserId && (
        <UpdateUserModal onClose={() => setActiveUserId(undefined)} userId={activeUserId} userType={visibleTab} />
      )}
      {/* eslint-disable-next-line prettier/prettier */}
      {isCreateUserModalVisible && (
        <CreateUserModal
          onClose={() => setIsCreateUserModalVisible(false)}
          userType={visibleTab}
        />
      )}
      <Tabs
        onChange={activeKey => setVisibleTab(activeKey as typeof visibleTab)}
        items={[
          {
            key: "GROUP_EMPLOYEES",
            label: (
              <>
                <TeamOutlined />
                Medewerkers
              </>
            ),
            children: (
              <UserTable
                dataSource={data?.users ?? []}
                loading={loading}
                onClick={handleOnClick}
                onCreateClick={() => setIsCreateUserModalVisible(true)}
                onIsActiveChange={() => setIsActive(!isActive)}
                isActive={isActive}
              />
            ),
          },
          {
            key: "GROUP_CUSTOMERS",
            label: (
              <>
                <ContactsOutlined />
                Klanten
              </>
            ),
            children: (
              <UserTable
                dataSource={data?.users ?? []}
                loading={loading}
                onClick={handleOnClick}
                onCreateClick={() => setIsCreateUserModalVisible(true)}
                onIsActiveChange={() => setIsActive(!isActive)}
                isActive={isActive}
              />
            ),
          },
        ]}
      />
    </>
  );
}

const USER_GROUPS = {
  GROUP_EMPLOYEES: 2,
  GROUP_CUSTOMERS: 3,
};

const UsersQuery = gql`
  query ($userGroupId: ID, $onlyActive: Boolean) {
    users(userGroupId: $userGroupId, onlyActive: $onlyActive) {
      id
      email
      username
      locale
      lastSeenAt
      enabled
    }
  }
`;
