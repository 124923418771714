import { gql } from "@apollo/client";

export default gql`
  mutation ($scheduledBreakRuleId: ID!, $scheduledBreakId: ID!, $startTime: DateTime!, $endTime: DateTime!) {
    updateScheduledBreak(
      input: { scheduledBreakRuleId: $scheduledBreakRuleId, scheduledBreakId: $scheduledBreakId, startTime: $startTime, endTime: $endTime }
    ) {
      scheduledBreak {
        id
        startTime
        endTime
        isPristine
      }
    }
  }
`;
