import { Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import PageHeader from "@/components/PageHeader";
import RelationPicker from "@/components/RelationPicker";

import LinkedUsersTabPaneContainer from "./containers/LinkedUsersTabPaneContainer";
import ProductsTabPaneContainer from "./containers/ProductsTabPaneContainer";
import RelationTabPaneContainer from "./containers/RelationTabPaneContainer";

export default function ViewRelation() {
  const navigation = useNavigate();
  const _params = useParams() as Record<string, string>;
  const params = _params?.["*"]?.split("/").filter(value => value?.length > 0);

  const currentRelationId = params?.[0] ?? undefined;
  const currentLocationId = params?.length > 1 ? params?.[params.length - 1] : undefined;

  // Redirect old URLs
  if (currentRelationId !== undefined && currentRelationId.startsWith("view")) {
    const searchParams = new URLSearchParams(window.location.search);
    const relationId = searchParams.get("relationId");
    const locationId = searchParams.get("locationId");

    if (relationId !== null && locationId !== null) {
      navigation(`/relations/${relationId}/locations/${locationId}`);
      return null;
    }
  }

  const handleSelectRelation = (relationId: string) => {
    navigation(`/relations/${relationId}`);
  };

  const handleSelectLocation = (locationId: string) => {
    if (currentRelationId === undefined) {
      return;
    }

    navigation(`/relations/${currentRelationId}/locations/${locationId}`, {
      replace: currentLocationId === undefined,
    });
  };

  return (
    <>
      <PageHeader bordered title="Zoek een relatie">
        <RelationPicker allowClear onChange={handleSelectRelation} value={currentRelationId} style={{ width: 300 }} />
      </PageHeader>
      {undefined !== currentRelationId && (
        <Tabs
          className="customer-tabs"
          style={{ marginTop: 10 }}
          items={[
            {
              key: "details",
              label: "Klantgegevens",
              children: (
                <RelationTabPaneContainer
                  key={currentRelationId}
                  onChangeLocation={handleSelectLocation}
                  relationId={currentRelationId}
                  locationId={currentLocationId}
                />
              ),
            },
            {
              key: "products",
              label: "Geregistreerde producten",
              children: <ProductsTabPaneContainer key={currentRelationId} relationId={currentRelationId} />,
            },
            {
              key: "users",
              label: "Gekoppelde gebruikers",
              children: <LinkedUsersTabPaneContainer key={currentRelationId} relationId={currentRelationId} />,
            },
          ]}
        />
      )}
    </>
  );
}
