import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import { ButtonProps } from "antd/lib/button";
import * as React from "react";

export default function DownloadButton({ onClick, ...restProps }: Omit<ButtonProps, "onClick"> & { onClick: () => Promise<void> }) {
  const [loading, setLoading] = React.useState(false);

  const handleOnClick = async () => {
    setLoading(true);

    onClick()
      .then(() => setLoading(false))
      .catch(error => {
        notification.error({ description: error.message, message: "Woops! Kon rapportage niet downloaden" });
        setLoading(false);
      });
  };

  return <Button {...restProps} loading={loading} icon={<CloudDownloadOutlined />} onClick={handleOnClick} />;
}
