import { gql, useQuery } from "@apollo/client";
import { Modal, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

interface PastAndFutureAppointmentsModalProps {
  onClose: () => void;
  onClick: (record: object) => void;
  locationId: string;
  relationId: string;
}

export default function PastAndFutureAppointmentsModal({ onClose, onClick, locationId, relationId }: PastAndFutureAppointmentsModalProps) {
  const [offset, setOffset] = useState(0);
  const { data, loading } = useQuery(PastAndFutureAppointmentsQuery, { variables: { locationId, relationId, offset } });

  return (
    <Modal centered bodyStyle={{ padding: 0, margin: 0 }} onCancel={() => onClose()} footer={null} open title={null} width="45%">
      <Table
        bordered
        columns={COLUMNS}
        dataSource={data?.pastAndFutureAppointments.edges}
        loading={loading}
        title={() => <span className="ant-modal-title">Onderhoudsbeurten</span>}
        className="table-clickable-rows"
        rowKey={record => `${record.appointmentId}+${record.worksheetId}`}
        onChange={pagination => {
          setOffset(((pagination.current ?? 1) - 1) * (pagination.pageSize ?? PAGE_SIZE));
        }}
        onRow={record => ({
          onClick: () => onClick(record),
        })}
        pagination={{ total: data?.pastAndFutureAppointments.totalCount ?? undefined, pageSize: PAGE_SIZE }}
      />
    </Modal>
  );
}

const PAGE_SIZE = 10;

const COLUMNS: ColumnsType<any> = [
  {
    title: "Datum",
    render: record => dayjs(record.startTime ?? record.prospectiveDate).format("LL"),
    key: "date",
  },
  {
    title: "Tijd",
    key: "time",
    render: record => {
      if (null === record.startTime || null === record.endTime) {
        return null;
      }

      return `${dayjs(record.startTime).format("HH:mm")} - ${dayjs(record.endTime).format("HH:mm")}`;
    },
  },
  {
    title: "Soort",
    dataIndex: "appointmentType",
    render: appointmentType => appointmentType?.name ?? "-",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: status => <FormattedMessage id={`appointment.status.${status}`} />,
  },
  {
    title: "Medewerker",
    dataIndex: ["employee", "username"],
  },
];

const PastAndFutureAppointmentsQuery = gql`
  query ($relationId: ID!, $locationId: ID!, $offset: Int!) {
    pastAndFutureAppointments(relationId: $relationId, locationId: $locationId, offset: $offset) {
      totalCount
      edges {
        appointmentId
        worksheetId
        startTime
        endTime
        prospectiveDate
        appointmentType {
          id
          name
        }
        employee {
          id
          username
        }
        status
      }
    }
  }
`;
