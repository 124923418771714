import { gql, useQuery } from "@apollo/client";
import { Table } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import formatProductDescription from "@/functions/format-product-description";

interface ProductsSummaryCardProps {
  appointment: any;
}

export default function ProductsSummaryCard({ appointment }: ProductsSummaryCardProps) {
  const { data: productsSummaryData } = useQuery(ProductSummaryQuery, {
    variables: { relationId: appointment.relation.id, locationId: appointment.location.id },
  });

  const { data: replaceableProductsData } = useQuery(ReplaceableProductsQuery, {
    variables: { locationId: appointment.location.id, onDate: appointment.startTime ?? appointment.prospectiveDate },
  });

  const replaceableProductsGrouped = React.useMemo(() => {
    return groupReplaceableProducts(replaceableProductsData?.servicedProductsReplaceable ?? []);
  }, [replaceableProductsData]);

  return (
    <>
      {undefined !== productsSummaryData && productsSummaryData.servicedProductsSummary.length > 0 && (
        <Table
          bordered
          columns={productsSummaryColumns}
          dataSource={productsSummaryData.servicedProductsSummary}
          title={() => "Product samenvatting"}
          rowKey={element => (element as any).productType.id}
          pagination={productsSummaryData.servicedProductsSummary.length > 5 ? { pageSize: 5 } : false}
          style={{ marginBottom: 12 }}
          size="small"
        />
      )}
      {replaceableProductsGrouped.length > 0 && (
        <Table
          bordered
          columns={productsSummaryColumns}
          dataSource={replaceableProductsGrouped}
          title={() => "Producten aan vervanging/ groot onderhoud toe"}
          rowKey={element => element.productType.id}
          pagination={replaceableProductsGrouped.length > 5 ? { pageSize: 5 } : false}
          style={{ marginBottom: 12 }}
          size="small"
        />
      )}
      {appointment.consumableGoods.length > 0 && (
        <Table
          bordered
          columns={productsSummaryColumns}
          dataSource={appointment.consumableGoods}
          rowKey={element => (element as any).productType.id}
          title={() => "Producten mee te nemen"}
          pagination={appointment.consumableGoods.length > 5 ? { pageSize: 5 } : false}
          size="small"
        />
      )}
    </>
  );
}

const productsSummaryColumns = [
  {
    title: <FormattedMessage id="product.productType" />,
    render: product => formatProductDescription(product),
    key: "productType",
  },
  {
    title: <FormattedMessage id="amount" />,
    dataIndex: "amount",
  },
];

interface ReplaceableProduct {
  productType: {
    id: string;
  };
}

function groupReplaceableProducts(products: ReplaceableProduct[]) {
  const retVal: Record<string, ReplaceableProduct & { amount: number }> = {};

  for (const product of products) {
    if (product.productType.id in retVal) {
      retVal[product.productType.id].amount += 1;
      continue;
    }

    retVal[product.productType.id] = {
      ...product,
      amount: 1,
    };
  }

  return Object.values(retVal);
}

const ProductSummaryQuery = gql`
  query ($relationId: ID!, $locationId: ID!) {
    servicedProductsSummary(relationId: $relationId, locationId: $locationId, skipDestroyed: true) {
      productType {
        id
        code
        description
      }
      amount
    }
  }
`;

const ReplaceableProductsQuery = gql`
  query ($locationId: ID!, $onDate: DateTime!) {
    servicedProductsReplaceable(locationId: $locationId, onDate: $onDate) {
      id
      productType {
        id
        code
        description
      }
    }
  }
`;
