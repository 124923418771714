import { Card, Typography } from "antd";
import * as React from "react";

interface PageHeaderProps {
  bordered?: boolean;
  children: React.ReactNode;
  title: string;
}

export default function PageHeader({ bordered, children, title }: PageHeaderProps) {
  return (
    <Card bordered={bordered}>
      <Typography.Title style={{ marginTop: 0 }} level={3}>
        {title}
      </Typography.Title>
      {children}
    </Card>
  );
}
