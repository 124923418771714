import { Dayjs } from "dayjs";

type Changes = {
  year: number;
  month: number;
  date: number;
};

export default function updateDayjsDate(instance: Dayjs, changes: Changes) {
  return instance.set("year", changes.year).set("month", changes.month).set("date", changes.date);
}
