import Constants from "@/constants";

import { refreshJwtToken } from "./refresh-jwt-token";

export async function loadWhoami() {
  const response0 = await loadWhoamiInternal();

  if (response0.status === 401) {
    if (!localStorage.getItem("hasActiveSession")) return undefined;

    // attempt silent sign-on
    if (false === (await refreshJwtToken())) return undefined;

    const response1 = await loadWhoamiInternal();
    if (!response1.ok) return undefined;

    return response1.json() as Promise<WhoamiResponse>;
  }

  return response0.json() as Promise<WhoamiResponse>;
}

async function loadWhoamiInternal() {
  return fetch(Constants.backendUrl + "/users/whoami", {
    method: "POST",
    credentials: "include",
  });
}

interface WhoamiResponse {
  user: {
    id: number;
    email: string;
    locale: string;
    groups: string[];
  };
}
