import * as React from "react";

function Header(props) {
  return <div className="styled-section-header" {...props} />;
}

function Section(props) {
  return <section className="styled-section" {...props} />;
}

Section.Header = Header;
export default Section;
