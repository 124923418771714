import { gql, useQuery } from "@apollo/client";
import { Select } from "antd";
import { useMemo } from "react";

interface AssignedEmployeePickerProps {
  currentlyAssignedUserId: string | undefined;
  onChange?: (value: string) => void;
  value?: string;
}

export default function AssignedEmployeePicker({ currentlyAssignedUserId, onChange, value }: AssignedEmployeePickerProps) {
  const { data, loading } = useQuery(EmployeesQuery);
  const dataSource = useMemo(() => sortEmployees(data?.employees ?? []), [data]);

  return (
    <Select
      dropdownMatchSelectWidth={false}
      loading={loading}
      onChange={onChange}
      showSearch
      optionFilterProp="children"
      value={value}
      style={{ minWidth: 175 }}
    >
      {dataSource.map(employee => (
        <Select.Option key={employee.id} value={employee.id} disabled={currentlyAssignedUserId === employee.id}>
          {employee.username}
        </Select.Option>
      ))}
    </Select>
  );
}

function sortEmployees(dataSource: Array<{ id: string; username: string }>) {
  return [...dataSource].sort((first, second) => first.username.localeCompare(second.username));
}

const EmployeesQuery = gql`
  query ($groupId: String) {
    employees(group: $groupId, skipDisabled: true) {
      id
      username
    }
  }
`;
