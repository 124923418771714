import { ExclamationCircleOutlined, UndoOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Checkbox, Form, Input, Modal, notification, Skeleton, Switch } from "antd";
import dayjs from "dayjs";
import { RRule } from "rrule";

import AppointmentTypePicker from "@/components/AppointmentTypePicker";
import { createChangedFormValues } from "@/functions/create-changed-form-values";

import ContractVisitsQuery from "../graphql/ContractVisitsQuery";
import { mapFormValuesToFlags, removeFlagsFromFormValues } from "../mapFormValuesToFlags";
import FrequencySelect from "./FrequencySelect";
import MonthPicker from "./MonthInYearPicker";
import RecurrencesPreviewTable from "./RecurrencesPreviewTable";
import RescheduleFromPicker from "./RescheduleFromPicker";

interface UpdateBatchVisitsModalProps {
  contractId: string;
  onClose: () => void;
  onExtendVisits: () => void;
  visitIds: string[];
}

const CHANGED_PROPS_VALUES = {
  appointmentTypeId: "Soort afspraak",
  interval: "Frequentie",
  repeat: "Herhalen in",
  comment: "Opmerking",
  includesDisposables: "Incl. disposables",
  rescheduleFrom: "(aanpassen per)",
  flags: "Instellingen",
};

function returnAsArray(value: number | number[] | undefined | null) {
  if (!value) return [];
  return Array.isArray(value) ? value : [value];
}

export default function UpdateBatchVisitsModal({ contractId, onClose, onExtendVisits, visitIds }: UpdateBatchVisitsModalProps) {
  const [formInstance] = Form.useForm();

  const { data } = useQuery(ContractVisitsQuery, { variables: { contractId } });
  const [updateVisitAsync, { loading: isSubmitting }] = useMutation(UpdateVisitMutation, {
    refetchQueries: [ContractVisitsQuery],
  });

  const interval = Form.useWatch("interval", formInstance);
  const repeat = Form.useWatch("repeat", formInstance);
  const isRecurrenceChanged = formInstance.isFieldTouched("interval") || formInstance.isFieldTouched("repeat");
  const overrideDefaultFlags = Form.useWatch("overrideDefaultFlags", formInstance);

  const relationId: string = data?.contract.relation.id;
  const visits: Array<Record<string, any>> = data?.contract.visits.filter(v => visitIds.includes(v.id)) ?? [];

  const handleOnSubmit = async values => {
    const changedProps = createChangedFormValues(values, formInstance.isFieldTouched);
    const changedPropsSanitized = removeFlagsFromFormValues(changedProps, "flags");
    const keysOfChangedProps = Object.keys(changedPropsSanitized);

    // Add `flags` if they were changed too
    if (changedProps.overrideDefaultFlags) {
      const [flags] = mapFormValuesToFlags(values);

      changedPropsSanitized.flags = flags;
      keysOfChangedProps.push("flags");
    }

    if (keysOfChangedProps.length < 1) {
      return onClose();
    }

    // not picked up by `isFieldTouched` if not changed from its initialValue
    if (values.rescheduleFrom) {
      changedProps.rescheduleFrom = values.rescheduleFrom;
    }

    Modal.confirm({
      title: `Wil je ${visitIds.length} bezoek(en) aanpassen?`,
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <p>Je gaat de volgende gegevens aanpassen:</p>
          <ul>
            {keysOfChangedProps.map(value => (
              <li key={value}>{CHANGED_PROPS_VALUES[value]}</li>
            ))}
          </ul>
        </>
      ),
      onOk: async () => {
        const mutatePromises = visitIds.map(visitId => {
          const visitProps = visits.find(v => v.id === visitId) as Record<string, any>;
          const currChangedProps = { ...changedPropsSanitized };

          if (currChangedProps.interval || currChangedProps.repeat) {
            let currentByMonth = RRule.parseString(visitProps.recurrence)?.bymonth;
            if (undefined === currentByMonth || null === currentByMonth) currentByMonth = dayjs(visitProps.startingOn).month() + 1;

            currChangedProps.recurrence = new RRule({
              interval: currChangedProps.interval ?? RRule.parseString(visitProps.recurrence).interval ?? 1,
              bymonth: currChangedProps.repeat ?? returnAsArray(currentByMonth).map(monthIndex => monthIndex.toString()),
              freq: RRule.YEARLY,
            }).toString();

            delete currChangedProps.interval;
            delete currChangedProps.repeat;
          }

          return updateVisitAsync({ variables: { contractId, visitId, ...currChangedProps } });
        });

        await Promise.all(mutatePromises);
        notification.success({ message: "Bezoeken zijn aangepast" });
        onClose();
      },
    });
  };

  return (
    <Modal
      confirmLoading={isSubmitting}
      onCancel={onClose}
      onOk={() => formInstance.submit()}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button key="extend" onClick={onExtendVisits} danger icon={<UndoOutlined />}>
            Einddatum aanpassen
          </Button>
          <div>
            <Button key="cancel" onClick={onClose}>
              Annuleer
            </Button>
            <Button key="ok" loading={isSubmitting} onClick={() => formInstance.submit()} type="primary">
              OK
            </Button>
          </div>
        </div>
      }
      title="Bezoeken aanpassen"
      open
    >
      {undefined === relationId ? (
        <Skeleton />
      ) : (
        <Form form={formInstance} labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} onFinish={handleOnSubmit}>
          <Form.Item label="Soort afspraak" name="appointmentTypeId">
            <AppointmentTypePicker />
          </Form.Item>
          <Form.Item label="Frequentie" name="interval">
            <FrequencySelect />
          </Form.Item>
          <Form.Item label="Herhalen in" name="repeat">
            <MonthPicker />
          </Form.Item>
          <Form.Item name="overrideDefaultFlags" label="Andere instellingen">
            <Switch />
          </Form.Item>
          {overrideDefaultFlags && (
            <Form.Item label="Instellingen">
              <Form.Item name="flags.FLAG_INCLUDES_DISPOSABLES" valuePropName="checked" style={{ marginBottom: 0 }}>
                <Checkbox>Inclusief disposables</Checkbox>
              </Form.Item>
              <Form.Item name="flags.FLAG_INVOICE_AFTERWARDS" valuePropName="checked" style={{ marginBottom: 0 }}>
                <Checkbox>Onderhoud apart factureren</Checkbox>
              </Form.Item>
            </Form.Item>
          )}
          {isRecurrenceChanged && (
            <Form.Item initialValue="CURRENT_CALENDAR_YEAR" label="Aanpassen per" name="rescheduleFrom" rules={[{ required: true }]}>
              <RescheduleFromPicker />
            </Form.Item>
          )}
          <Form.Item label="Opmerking" name="comment">
            <Input.TextArea rows={3} />
          </Form.Item>
          {interval && repeat && (
            <div style={{ paddingLeft: 52 }}>
              <RecurrencesPreviewTable repeat={repeat} interval={interval} />
            </div>
          )}
        </Form>
      )}
    </Modal>
  );
}

const UpdateVisitMutation = gql`
  mutation (
    $contractId: ID!
    $visitId: ID!
    $rescheduleFrom: RescheduleRecurrencesFromMode
    $appointmentTypeId: ID
    $recurrence: String
    $overrideDefaultFlags: Boolean
    $flags: [ContractFlag!]
  ) {
    updateVisit(
      input: {
        contractId: $contractId
        visitId: $visitId
        rescheduleFrom: $rescheduleFrom
        appointmentTypeId: $appointmentTypeId
        overrideDefaultFlags: $overrideDefaultFlags
        recurrence: $recurrence
        flags: $flags
      }
    ) {
      contract {
        id
      }
    }
  }
`;
