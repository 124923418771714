import { SearchOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Card, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

const COLUMNS: ColumnsType<any> = [
  {
    title: "Datum",
    render: record => dayjs(record.startTime ?? record.prospectiveDate).format("LL"),
    key: "date",
  },
  {
    title: "Tijd",
    key: "time",
    render: record => {
      if (null === record.startTime || null === record.endTime) return null;
      return `${dayjs(record.startTime).format("HH:mm")} - ${dayjs(record.endTime).format("HH:mm")}`;
    },
  },
  {
    title: "Soort",
    dataIndex: "appointmentType",
    render: appointmentType => appointmentType?.name ?? "-",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: status => <FormattedMessage id={`appointment.status.${status}`} />,
  },
];

interface AppointmentsCardProps {
  onClick: (record: object) => void;
  onMoreClick: () => void;
  locationId: string;
  relationId: string;
}

export default function AppointmentsCard({ onClick, onMoreClick, locationId, relationId }: AppointmentsCardProps) {
  const { data, loading } = useQuery(PastAndFutureAppointmentsOverviewQuery, { variables: { relationId, locationId } });

  return (
    <Card
      bodyStyle={{ padding: 0, margin: -1 }}
      title="Onderhoudsbeurten"
      actions={[
        <span key="list" role="button" onClick={onMoreClick}>
          <SearchOutlined />
          Alles bekijken
        </span>,
      ]}
    >
      <Table
        loading={loading}
        className="table-clickable-rows"
        rowKey={record => `${record.appointmentId}+${record.worksheetId}`}
        bordered
        columns={COLUMNS}
        dataSource={data?.pastAndFutureAppointmentsOverview ?? []}
        pagination={false}
        onRow={record => ({
          onClick: () => onClick(record),
        })}
      />
    </Card>
  );
}

const PastAndFutureAppointmentsOverviewQuery = gql`
  query ($relationId: ID!, $locationId: ID!) {
    pastAndFutureAppointmentsOverview(relationId: $relationId, locationId: $locationId) {
      appointmentId
      worksheetId
      startTime
      endTime
      prospectiveDate
      appointmentType {
        id
        name
      }
      status
    }
  }
`;
