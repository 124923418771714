import { Card, DatePicker } from "antd";
import dayjs from "dayjs";
import queryString from "query-string";
import * as React from "react";

import EmployeePicker from "@/components/EmployeePicker";
import Constants from "@/constants";
import downloadAttachment from "@/functions/download-attachment";

import DownloadButton from "./DownloadButton";

export default function InventoryRequirementsTechniciansSpreadsheet() {
  const [{ start, end }, setPeriod] = React.useState<Record<string, dayjs.Dayjs | null | undefined>>({ start: null, end: null });
  const [employeeIds, setEmployeeIds] = React.useState<string[]>([]);

  const handleOnDownload = () => {
    if (!start || !end) return Promise.resolve();

    const queryParams = {
      "start-date": start.format("YYYY-MM-DD"),
      "end-date": end.format("YYYY-MM-DD"),
      employees: employeeIds,
    };

    return downloadAttachment(
      `reports/inventory-requirements-technicians?` + queryString.stringify(queryParams, { arrayFormat: "bracket" })
    );
  };

  return (
    <Card title="Voorraadbehoefte onderhoud per onderhoudsmedewerker">
      <div style={{ marginBottom: 8 }}>
        <span>Medewerkers: </span>
        <EmployeePicker
          allowSelectAll
          onChange={value => {
            setEmployeeIds(value as string[]);
          }}
          mode="multiple"
          groupId={Constants.groupIds.GROUP_SERVICE_EMPLOYEES}
          style={{ width: 300 }}
          value={employeeIds}
        />
      </div>
      <div style={{ marginBottom: 8 }}>
        <span>Periode: </span>
        <DatePicker.RangePicker
          onChange={period => {
            setPeriod({
              start: period?.[0] ?? start,
              end: period?.[1] ?? end,
            });
          }}
        />
      </div>

      <DownloadButton type="primary" onClick={handleOnDownload} disabled={!start || !end || employeeIds.length < 1}>
        Downloaden
      </DownloadButton>
    </Card>
  );
}
