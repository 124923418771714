import pick from "lodash/pick";

export interface Address {
  street: string | null;
  postalCode: string | null;
  city: string | null;
  country: string | null;
}

export default function formatAddress(address: Address) {
  const props = pick(address, ["street", "postalCode", "city"]);

  return Object.values(props)
    .filter(value => value !== null && value.length > 0)
    .join(", ");
}
