import { TimePicker as AntTimePicker, TimePickerProps } from "antd";
import dayjs from "dayjs";
import * as React from "react";

function disabledTime() {
  return { disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 21, 22, 23] };
}

export default React.forwardRef(function TimePicker(props: Omit<TimePickerProps, "disabledTimes">, ref) {
  const [numberOfOnSelects, setNumberOfOnSelects] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOnOpenChange = (nextValue: boolean) => {
    setIsOpen(nextValue);
    setNumberOfOnSelects(0);
  };

  const handleOnSelect = (value: dayjs.Dayjs) => {
    props.onSelect?.(value);

    const nextNumberOfOnSelects = numberOfOnSelects + 1;
    setNumberOfOnSelects(nextNumberOfOnSelects);

    if (nextNumberOfOnSelects % 2 !== 0) return;

    props.onChange?.(value, dayjs(value).format("HH:mm"));
    setIsOpen(false);
  };

  return (
    <AntTimePicker
      ref={ref}
      disabledTime={disabledTime}
      onOpenChange={handleOnOpenChange}
      onSelect={handleOnSelect}
      open={isOpen}
      {...props}
    />
  );
});
