import { gql, useQuery } from "@apollo/client";
import { Alert } from "antd";
import dayjs from "dayjs";

interface MaybePriorContractAlertProps {
  relationId: string;
}

export default function MaybePriorContractAlert({ relationId }: MaybePriorContractAlertProps) {
  const { data } = useQuery(PriorContractQuery, { variables: { relationId } });

  if (undefined === data || null === data?.contract) return null;
  const endingOn = data.contract.endingOn ? dayjs(data.contract.endingOn).format("LL") : "geen einddatum";

  return (
    <Alert
      showIcon
      type="error"
      message="Klant heeft bestaand contract"
      description={`Klant heeft al een lopend contract. Dit contract moet eerst stopgezet worden voor een nieuwe te registreren, of de start datum van het nieuwe contract moet na de einddatum van het bestaande contract (${endingOn}) liggen.`}
    />
  );
}

const PriorContractQuery = gql`
  query ($relationId: ID!) {
    contract(relationId: $relationId) {
      id
      endingOn
    }
  }
`;
