import "./index.css";

import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";

import LocaleProvider from "@/components/LocaleProvider";
import Constants from "@/constants";

import DefibrionApp from "./App";
import { AuthenticationContextProvider } from "./authorization";
import configureClient from "./configure-apollo";

Sentry.init({
  enabled: Constants.environment === "prod",
  dsn: "https://f44fefb5f605484fa386011969259da6@o81216.ingest.sentry.io/4504113158225920",
  tracesSampleRate: 0.0,
});

// eslint-disable-next-line prettier/prettier
const client = configureClient(
  Constants.backendUrl + "/graphql/employees/batch",
  Constants.backendUrl + "/graphql/employees"
);

function createSentryDialogOptions() {
  return { user: { email: localStorage.getItem("userEmail") ?? undefined } };
}

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<span>Woops! Something went wrong</span>} showDialog dialogOptions={createSentryDialogOptions}>
      <ApolloProvider client={client}>
        <AuthenticationContextProvider>
          <LocaleProvider>
            <DefibrionApp />
          </LocaleProvider>
        </AuthenticationContextProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(<App />);
