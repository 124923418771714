import { gql, useQuery } from "@apollo/client";
import { Divider, Space, TreeSelect, TreeSelectProps } from "antd";
import dayjs from "dayjs";

import formatProductDescription from "@/functions/format-product-description";

interface RelatedProductsSelector<ValueType> extends TreeSelectProps<ValueType> {
  relationId: string;
  locationId: string;
}

function formatProductNodeTitle(product: any) {
  return (
    <>
      <div>{formatProductDescription(product)}</div>
      {(null !== product.serialCode || null !== product.productionBatch || null !== product.replacementDate) && (
        <Space direction="horizontal" size={4} split={<Divider type="vertical" />}>
          {null !== product.serialCode && <span>Serienummer: {product.serialCode}</span>}
          {null !== product.productionBatch && <span>Productiebatch: {product.productionBatch}</span>}
          {null !== product.replacementDate && <span>Vervangdatum: {dayjs(product.replacementDate).format("MM-YYYY")}</span>}
        </Space>
      )}
    </>
  );
}

export default function RelatedProductsSelector<ValueType>({ relationId, locationId, ...restProps }: RelatedProductsSelector<ValueType>) {
  const { data, loading } = useQuery(ServicedProductsQuery, {
    variables: {
      relationId,
      locationId,
      condition: ["CONDITION_GOOD", "CONDITION_ACTION_REQUIRED", "CONDITION_UNKNOWN"],
    },
  });

  return (
    <TreeSelect loading={loading} {...restProps}>
      {(data?.servicedProducts.edges ?? []).map(product => {
        return (
          <TreeSelect.TreeNode key={product.id} value={product.id} title={formatProductNodeTitle(product)}>
            {product.parts.map(part => (
              <TreeSelect.TreeNode key={part.id} value={part.id} title={formatProductNodeTitle(part)} />
            ))}
          </TreeSelect.TreeNode>
        );
      })}
    </TreeSelect>
  );
}

const ServicedProductsQuery = gql`
  query ($relationId: ID!, $locationId: ID!, $condition: [ProductCondition!]) {
    servicedProducts(relationId: $relationId, locationId: $locationId, condition: $condition) {
      totalCount
      edges {
        id
        productType {
          id
          code
          description
        }
        brand
        optionalDescription
        serialCode
        expirationDate
        productionBatch
        locationDescription
        replacementDate
        condition
        parts {
          id
          productType {
            id
            code
            description
          }
          brand
          optionalDescription
          serialCode
          expirationDate
          productionBatch
          locationDescription
          replacementDate
          condition
        }
      }
    }
  }
`;
