import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import * as React from "react";

export default function Spinner() {
  return (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      style={{
        position: "absolute",
        zIndex: 1000,
        top: 10,
        right: 10,
      }}
    />
  );
}
