import * as React from "react";

import PrepareInviteModal from "../PrepareInviteModal";
import InnerAppointmentModal from "./AppointmentModal";

interface AppointmentModalProps {
  appointmentId: string;
  onClose: () => void;
}

export default function AppointmentModal({ appointmentId, onClose }: AppointmentModalProps) {
  const [mode, setMode] = React.useState<"appointment" | "invite">("appointment");

  return (
    <>
      <InnerAppointmentModal
        appointmentId={appointmentId}
        onClose={onClose}
        onInviteClick={() => setMode("invite")}
        visible={mode === "appointment"}
      />
      {mode === "invite" && (
        <PrepareInviteModal
          appointmentId={appointmentId}
          onClose={() => {
            setMode("appointment");
          }}
        />
      )}
    </>
  );
}
