import React from "react";
import { FormattedMessage } from "react-intl";

interface OptionalInformationProps {
  id?: string;
  component?: keyof React.ReactHTML;
  label?: string;
  value?: React.ReactNode;
}

export default function OptionalInformation({ id, component = "span", label, value }: OptionalInformationProps) {
  if (typeof value === "undefined" || !value) return null;

  return (
    <div style={{ paddingBottom: 10 }}>
      <p style={{ fontSize: 14, fontWeight: "bolder", marginBottom: 0 }}>
        {undefined !== label ? label : <FormattedMessage id={id as string} />}
      </p>
      {React.createElement(component, {}, value)}
    </div>
  );
}
