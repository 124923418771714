import { QuestionCircleOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { Form, Modal, notification } from "antd";
import * as React from "react";

import MonthPicker from "@/components/MonthPicker";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

interface TerminateContractModalProps {
  contractId: string;
  onClose: () => void;
}

export default function TerminateContractModal({ contractId, onClose }: TerminateContractModalProps) {
  const [formInstance] = Form.useForm();
  const [terminateContractAsync, { loading }] = useMutation(TerminateContractMutation);

  const handleOnTerminate = async ({ terminateOn }: { terminateOn: dayjs.Dayjs }) => {
    try {
      await terminateContractAsync({ variables: { contractId, terminateOn } });
      onClose();

      notification.success({
        message: "Contract is stopgezet",
      });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Modal confirmLoading={loading} onCancel={onClose} onOk={() => formInstance.submit()} okType="danger" title="Contract eindigen" open>
      <p>
        <QuestionCircleOutlined />
        <span style={{ marginInline: 8 }}>Weet u zeker dat u dit contract wilt stopzetten?</span>
      </p>
      <p>Als u het contract wilt stopzetten moet u een einddatum opgeven.</p>
      <Form form={formInstance} onFinish={handleOnTerminate} layout="vertical">
        <Form.Item label="Contract stopzetten op" name="terminateOn" rules={[{ required: true }]}>
          <MonthPicker />
        </Form.Item>
      </Form>
    </Modal>
  );
}

const TerminateContractMutation = gql`
  mutation ($contractId: ID!, $terminateOn: DateTime) {
    terminateContract(input: { id: $contractId, terminateOn: $terminateOn }) {
      contract {
        id
        endingOn
        visits {
          id
          endingOn
        }
      }
    }
  }
`;
