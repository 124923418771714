import { gql, useQuery } from "@apollo/client";
import { Form, Input, Skeleton } from "antd";
import * as React from "react";

import useDebouncedState from "@/hooks/use-debounced-state";

interface EmailEditorTabProps {
  emailTemplateId: string;
  subject: string;
  content: string;
}

interface EmailEditorRef {
  isChanged: boolean;
  getEditorValues: () => { subject: string; content: string };
}

function EmailEditorTab(
  { emailTemplateId, subject: initialSubject, content: initialContent }: EmailEditorTabProps,
  ref: React.Ref<EmailEditorRef>
) {
  const [subject, delayedSubject, setSubject] = useDebouncedState(initialSubject);
  const [content, delayedContent, setContent] = useDebouncedState(initialContent);

  const textAreaRef = React.useRef<React.ElementRef<typeof Input.TextArea>>(null);
  const outputAreaRef = React.useRef<HTMLDivElement>(null);

  React.useImperativeHandle(
    ref,
    () => ({
      isChanged: subject !== initialSubject || content !== initialContent,
      getEditorValues: () => ({ subject, content }),
    }),
    [subject, content]
  );

  const TEXT_AREA_HEIGHT = window.innerHeight - 375;
  const { data, loading } = useQuery(RenderTemplateQuery, {
    variables: { emailTemplateId, subject: delayedSubject, content: delayedContent },
    skip: subject.length < 1 && content.length < 1,
  });

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: 8, justifyContent: "space-between" }}>
      <div style={{ width: "calc(50% - 8px)" }}>
        <Form layout="vertical" initialValues={{ subject, content }}>
          <Form.Item name="subject" label="Onderwerp">
            <Input onChange={e => setSubject(e.target.value)} value={subject} />
          </Form.Item>
          <Form.Item name="content" label="Inhoud">
            <Input.TextArea
              ref={textAreaRef}
              onChange={e => setContent(e.target.value)}
              onScroll={event => {
                const currentScrollRatio =
                  event.currentTarget.scrollTop / (event.currentTarget.scrollHeight - event.currentTarget.offsetHeight);
                const outputScrollHeight = (outputAreaRef.current?.scrollHeight ?? 0) - (outputAreaRef.current?.offsetHeight ?? 0);

                outputAreaRef.current?.scrollTo({ top: currentScrollRatio * outputScrollHeight });
              }}
              value={content}
              style={{ height: TEXT_AREA_HEIGHT, overflowY: "scroll" }}
              rows={23}
            />
          </Form.Item>
        </Form>
      </div>
      <div style={{ width: "calc(50% - 8px)" }}>
        {loading ? (
          <Skeleton />
        ) : (
          <Form layout="vertical">
            <Form.Item label="Onderwerp">
              <Input disabled value={data?.renderTemplatePreview.subject} />
            </Form.Item>
            <Form.Item label="Inhoud">
              <div
                ref={outputAreaRef}
                dangerouslySetInnerHTML={{ __html: data?.renderTemplatePreview.content }}
                style={{
                  borderColor: "#d9d9d9",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderRadius: 4,
                  padding: 0,
                  margin: 0,
                  overflowY: "scroll",
                  height: TEXT_AREA_HEIGHT,
                }}
              />
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
}

const RenderTemplateQuery = gql`
  query ($emailTemplateId: ID!, $subject: String!, $content: String!) {
    renderTemplatePreview(emailTemplateId: $emailTemplateId, subject: $subject, content: $content) {
      subject
      content
    }
  }
`;

export default React.forwardRef(EmailEditorTab);
