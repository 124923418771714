import { EditOutlined, SyncOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Card, Col, notification, Row } from "antd";
import { FormattedMessage } from "react-intl";

import OptionalInformation from "@/components/OptionalInformation";

import RelationQuery from "../../graphql/RelationQuery";

interface RelationCardContainerProps {
  onEditClick: () => void;
  relationId: string;
}

export default function RelationCardContainer({ onEditClick, relationId }: RelationCardContainerProps) {
  const { data } = useQuery(RelationQuery, { variables: { id: relationId } });
  const [synchronizeAsync, { loading: isSynchronizing }] = useMutation(SyncMutation);

  const handleOnSyncPress = async () => {
    try {
      await synchronizeAsync({ variables: { id: relationId } });

      notification.success({
        message: "Relatie gesynchroniseerd!",
      });
    } catch (error) {
      notification.error({
        message: "Fout tijdens sychroniseren!",
        description: (error as Error).message,
      });
    }
  };

  const relation = data?.relation ?? undefined;

  if (!relation) {
    return <Card loading title={<FormattedMessage id="relation.details" />} />;
  }

  return (
    <Card
      title={<FormattedMessage id="relation.details" />}
      actions={[
        <Button key="edit" onClick={onEditClick} type="text" icon={<EditOutlined />}>
          <FormattedMessage id="updateRelation" />
        </Button>,
        <Button
          key="synchronize"
          disabled={isSynchronizing}
          onClick={handleOnSyncPress}
          type="text"
          loading={isSynchronizing}
          icon={<SyncOutlined />}
        >
          <FormattedMessage id="relation.sync" />
        </Button>,
      ]}
    >
      <Row justify="space-between" gutter={1}>
        <Col span={12}>
          <OptionalInformation id="relation.name" value={relation.name} />
          <OptionalInformation id="relation.place" value={relation.place} />
          {null !== relation.belongsTo && (
            <OptionalInformation id="relation.belongsTo" value={`(${relation.belongsTo.afasCode}) ${relation.belongsTo.name}`} />
          )}
        </Col>
        <Col span={12}>
          <OptionalInformation id="relation.afasCode" value={relation.afasCode} />
          <OptionalInformation id="relation.responsibleEmployee" value={relation.responsibleEmployee?.username ?? "-"} />
        </Col>
      </Row>
      {(relation.planningComment || relation.onSiteComment) && (
        <Row justify="space-between" gutter={1}>
          {relation.planningComment && (
            <Col span={12}>
              <OptionalInformation id="relation.planningComment" value={relation.planningComment} />
            </Col>
          )}
          {relation.onSiteComment && (
            <Col span={12}>
              <OptionalInformation id="relation.onSiteComment" value={relation.onSiteComment} />
            </Col>
          )}
        </Row>
      )}
      {relation.invoiceComment && (
        <Row justify="space-between" gutter={1}>
          <Col span={12}>
            <OptionalInformation label="Facturatie notities" value={relation.invoiceComment} />
          </Col>
        </Row>
      )}
    </Card>
  );
}

const SyncMutation = gql`
  mutation ($id: ID!) {
    synchronizeRelation(input: { id: $id }) {
      relation {
        id
        name
        place
        responsibleEmployee {
          id
          username
        }
        locations {
          id
          name
          contactPerson
          primaryEmail
          secondaryEmail
          phoneNumber
          mobilePhoneNumber
          address {
            street
            postalCode
            city
            country
          }
        }
      }
    }
  }
`;
