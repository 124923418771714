import { Alert, Form, Input, Tooltip } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Store } from "antd/lib/form/interface";

import AddressCompleter from "@/components/AddressCompleter";
import LocalePicker from "@/components/LocalePicker";

interface LocationFormProps<T extends Store> {
  disabled?: boolean;
  form?: FormInstance<T>;
  initialValues?: T;
  onFinish?: (values: T) => Promise<void>;
  showAddressCorrectionHint: boolean;
}

export default function LocationForm<T extends Store>({
  disabled,
  form,
  initialValues,
  onFinish,
  showAddressCorrectionHint,
}: LocationFormProps<T>) {
  return (
    <Form disabled={disabled} form={form} layout="vertical" initialValues={initialValues} onFinish={onFinish}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", columnGap: 10 }}>
        <Form.Item label="AFAS" name="afasCode" style={{ width: "20%" }} normalize={value => Number(value)}>
          <Input className="input-number-hidden-spinbox" inputMode="numeric" disabled={!!initialValues?.afasCode} type="number" />
        </Form.Item>
        <Form.Item label="Naam" name="name" required rules={[{ required: true }]} style={{ flexGrow: 1 }}>
          <Input />
        </Form.Item>
      </div>
      <Form.Item
        label="Adres"
        name="address"
        required
        rules={[{ required: true }]}
        extra={
          showAddressCorrectionHint && (
            <Alert
              showIcon
              type="info"
              message={
                <span>
                  Alleen bedoeld om adres te corrigeren. Gebruik de <kbd>Verhuizen</kbd> knop als de locatie verhuisd is.{" "}
                  <Tooltip title={differenceBetweenAddressChangeAndMovingExplanation} placement="bottomLeft">
                    <a>Wat maakt het uit?</a>
                  </Tooltip>
                </span>
              }
              style={{ marginTop: 8 }}
            />
          )
        }
      >
        <AddressCompleter />
      </Form.Item>
      <Form.Item label="Adres toevoegingen (gebouw, ...)" name="addressExtraDetails">
        <Input />
      </Form.Item>
      <Form.Item label="Contactpersoon" name="contactPerson" required rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
        <Form.Item label="Telefoonnummer" name="phoneNumber" required>
          <Input type="tel" />
        </Form.Item>
        <Form.Item label="Mobiele telefoonnummer" name="mobilePhoneNumber">
          <Input type="tel" />
        </Form.Item>
      </div>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
        <Form.Item label="Eerste e-mail adres" name="primaryEmail" required rules={[{ required: true }]}>
          <Input type="email" />
        </Form.Item>
        <Form.Item label="Tweede e-mail adres" name="secondaryEmail">
          <Input type="email" />
        </Form.Item>
      </div>
      <Form.Item label="Taal" name="locale" required rules={[{ required: true }]}>
        <LocalePicker />
      </Form.Item>
      <Form.Item label="Planning notities" name="planningComment">
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item label="Buitendienst notities" name="onSiteComment">
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item label="Facturatie notities" name="invoiceComment">
        <Input.TextArea rows={3} />
      </Form.Item>
    </Form>
  );
}

const differenceBetweenAddressChangeAndMovingExplanation = `
Als je een locatie 'Verhuist' wordt er een nieuwe locatie aangemaakt.
Alle toekomstige afspraken en geinstalleerde producten worden meegenomen naar de nieuwe locatie.
De oude locatie blijft bestaan met alle historische gegevens (afspraken, onderhoudsbeurten) en wordt als verwijderd gemarkeerd.
Dit zorgt voor een duidelijke opbouw van de klanten administratie.
`.trim();
