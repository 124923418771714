import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Button, Card, Table } from "antd";
import dayjs from "dayjs";
import React from "react";
import { FormattedMessage } from "react-intl";

import useAuthState from "@/authorization/use-auth-state";

import IssuesQuery from "../graphql/IssuesQuery";

const columns = [
  {
    title: <FormattedMessage id="issue.createdOn" />,
    dataIndex: "createdOn",
    render: createdOn => dayjs(createdOn).format("DD/MM/YYYY"),
  },
  {
    title: <FormattedMessage id="relation.afasCode" />,
    dataIndex: "relation",
    render: relation => relation.afasCode,
  },
  {
    title: <FormattedMessage id="relation" />,
    dataIndex: ["relation", "name"],
  },
  {
    title: <FormattedMessage id="location" />,
    dataIndex: ["location", "name"],
  },
  {
    title: <FormattedMessage id="issue.subject" />,
    dataIndex: "subject",
  },
  {
    title: <FormattedMessage id="issue.createdBy" />,
    dataIndex: ["createdBy", "username"],
  },
  {
    title: <FormattedMessage id="issue.status" />,
    dataIndex: "status",
    render: status => <FormattedMessage id={`issue.status.${status}`} />,
  },
];

interface IssueListCard {
  onClick: (issue: unknown) => void;
  onCreateClick: () => void;
  onShowAllClick: () => void;
}

export default function IssueListCard({ onClick, onCreateClick, onShowAllClick }: IssueListCard) {
  // eslint-disable-next-line prettier/prettier
  const { user: { id: userId } } = useAuthState();

  const { data, loading } = useQuery(IssuesQuery, {
    variables: {
      assignedToId: userId,
      status: ["STATUS_RAISED", "STATUS_PENDING"],
    },
  });

  return (
    <Card
      bodyStyle={{ padding: 0, margin: -1 }}
      title="Meldingen"
      actions={[
        <Button key="list" onClick={onShowAllClick} type="link">
          <SearchOutlined />
          Alles bekijken
        </Button>,
        <Button key="register" role="button" onClick={onCreateClick} type="link">
          <PlusOutlined />
          Registreren
        </Button>,
      ]}
    >
      <Table
        loading={loading}
        className="table-clickable-rows"
        rowKey="id"
        bordered
        columns={columns}
        dataSource={data?.issues ?? []}
        pagination={false}
        onRow={record => ({
          onClick: () => onClick(record.id),
        })}
      />
    </Card>
  );
}
