import { CloseOutlined } from "@ant-design/icons";
import { Card, Row, Statistic, Timeline } from "antd";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import * as React from "react";

import formatAddress from "@/functions/format-address";

interface RoutingModePaneProps {
  appointments: [any, any];
  onCloseClick: () => void;
}

declare global {
  interface Window {
    google: any;
  }
}

dayjs.extend(duration);

export default function RoutingModePane({ appointments: [appointmentA, appointmentB], onCloseClick }: RoutingModePaneProps) {
  const [{ distance, duration }, setDistanceMatrix] = React.useState({ distance: 0, duration: 0 });
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);

    const origin = appointmentA.location.address.coordinates;
    const destination = appointmentB.location.address.coordinates;

    new window.google.maps.DistanceMatrixService().getDistanceMatrix(
      {
        origins: [new window.google.maps.LatLng(origin.latitude, origin.longitude)],
        destinations: [new window.google.maps.LatLng(destination.latitude, destination.longitude)],
        travelMode: "DRIVING",
      },
      (response, status) => {
        if (status !== "OK") return;

        setLoading(false);
        setDistanceMatrix({
          distance: response.rows[0].elements[0].distance.value,
          duration: response.rows[0].elements[0].duration.value,
        });
      }
    );
  }, [appointmentA, appointmentB]);

  const renderAppointmentAsTimelineItem = ({ relation, location }) => (
    <Timeline.Item>
      <div>{`${relation.afasCode ? `(${relation.afasCode})` : ""} ${relation.name}`.trim()}</div>
      <div className="ant-steps-item-description">
        {relation.name !== location.name && <div>{location.name}</div>}
        <div>{formatAddress(location.address)}</div>
      </div>
    </Timeline.Item>
  );

  return (
    <Card
      loading={loading}
      style={{
        position: "absolute",
        borderRadius: 8,
        zIndex: 999,
        top: 10,
        right: 10,
        minWidth: 320,
        width: "20%",
      }}
      extra={
        <a onClick={onCloseClick} role="button">
          <CloseOutlined />
        </a>
      }
      title="Reistijd bepalen"
    >
      <Timeline>
        {renderAppointmentAsTimelineItem(appointmentA)}
        {renderAppointmentAsTimelineItem(appointmentB)}
      </Timeline>
      <Row justify="space-between">
        <Statistic title="Afstand" value={`${(distance / 1000).toFixed(1)} km`} />
        <Statistic title="Reistijd" value={`${dayjs.duration(duration, "seconds").asMinutes().toFixed(0)} minuten`} />
      </Row>
    </Card>
  );
}
