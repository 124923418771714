import { gql, useQuery } from "@apollo/client";
import { Table } from "antd";
import dayjs from "dayjs";
import truncate from "lodash/truncate";
import { useMemo, useState } from "react";

import AppointmentModal from "@/components/AppointmentModal";
import { formatAppointmentType } from "@/functions/appointment-util";
import formatAddress from "@/functions/format-address";

const QUERY_VARIABLES = { status: ["STATUS_SCHEDULED"], endTime: dayjs().add(-1, "days") };
export default function OverdueAppointments() {
  const { data, loading } = useQuery(OverdueQuery, { variables: QUERY_VARIABLES });
  const [isShowingAppointmentWithId, setIsShowingAppointmentWithId] = useState<string | undefined>();
  const dataSource = useMemo(() => [...(data?.appointments ?? [])].sort((a, b) => new Date(b.startTime) - new Date(a.startTime)), [data]);

  return (
    <>
      {isShowingAppointmentWithId !== undefined && (
        <AppointmentModal appointmentId={isShowingAppointmentWithId} onClose={() => setIsShowingAppointmentWithId(undefined)} />
      )}
      <Table
        bordered
        columns={[
          {
            title: "Datum",
            dataIndex: "startTime",
            width: 150,
            render: startTime => dayjs(startTime).format("LL"),
          },
          {
            title: "Tijd",
            render: ({ endTime, startTime }) => `${dayjs(startTime).format("HH:mm")} - ${dayjs(endTime).format("HH:mm")}`,
          },
          {
            title: "Toegewezen aan",
            dataIndex: ["employee", "username"],
          },
          {
            title: "Relatie",
            dataIndex: ["relation"],
            render: relation => `(${relation.afasCode}) ${relation.name}`,
          },
          {
            title: "Adres",
            dataIndex: ["location"],
            render: location => (
              <>
                <span style={{ display: "block" }}>{location.name}</span>
                <em style={{ display: "block", marginTop: 4 }}>
                  {truncate(formatAddress(location.address), { length: 75, separator: /,? +/ })}
                </em>
              </>
            ),
          },
          {
            title: "Soort",
            dataIndex: "appointmentType",
            render: appointmentType => formatAppointmentType(appointmentType),
          },
        ]}
        dataSource={dataSource}
        loading={loading}
        onRow={record => ({
          onClick: () => setIsShowingAppointmentWithId(record.id),
        })}
        title={() => "Overzicht van alle afspraken voor meer dan twee dagen geleden die noch bevestigd noch geannuleerd zijn."}
        rowKey="id"
      />
    </>
  );
}

const OverdueQuery = gql`
  query ($status: [AppointmentStatus!], $endTime: DateTime!) {
    appointments(status: $status, endTime: $endTime) {
      id
      appointmentType {
        id
        name
        category {
          id
          name
        }
      }
      relation {
        id
        afasCode
        name
      }
      location {
        id
        name
        address {
          street
          postalCode
          city
          country
        }
      }
      employee {
        id
        username
      }
      startTime
      endTime
    }
  }
`;
