import { Layout } from "antd";
import { useEffect, useMemo } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Constants from "@/constants";
import routes from "@/routes";

import Header from "./Header";
import SideMenu from "./SideMenu";

function reduceMenu(routes_: typeof routes, parent: string[] = ["/"]): React.ReactNode {
  return routes_.flatMap(({ path, children = undefined, component: Component }) => {
    if (!Array.isArray(children) && undefined !== Component) {
      const currentPath = [...parent, path].join("/").substring(1);

      return <Route key={currentPath} element={<Component />} path={currentPath} />;
    }

    return reduceMenu(children as typeof routes, [...parent, path]);
  });
}

function findBreadcrumb(routes_: typeof routes, routeParts: string[]): string[] {
  const breadcrumbs = routes_.find(route => route.path === routeParts[0]);
  if (breadcrumbs === undefined) return [];

  return [breadcrumbs.name, ...findBreadcrumb(breadcrumbs.children ?? [], routeParts.slice(1))];
}

export default function AdminPortal() {
  const location = useLocation();
  const routeMapping = useMemo(() => reduceMenu(routes), []);

  useEffect(() => {
    const breadcrumbs = findBreadcrumb(routes, location.pathname.substring(1).split("/"));
    document.title = Constants.appName + " | " + breadcrumbs.join(" > ");
  }, [location.pathname]);

  return (
    <Layout>
      <Header />
      <Layout>
        <SideMenu />
        <Layout style={{ padding: "12px 12px 12px" }}>
          <Layout.Content style={{ backgroundColor: "white", borderRadius: 2, padding: 12, minHeight: window.innerHeight - 124 }}>
            <Routes>{routeMapping}</Routes>
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
