import { graphql } from "@apollo/client/react/hoc";

import IssueTable from "../components/IssueTable";
import ListIssuesQuery from "../graphql/ListIssuesQuery";

export default graphql(ListIssuesQuery, {
  props: ({ data: { loading = true, issues = [] } }) => ({
    loading,
    issues,
  }),
})(IssueTable);
