import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import AuthenticationContext from "@/authorization/context";
import AdminPanel from "@/containers/AdminPanel";
import Login from "@/containers/NotAuthenticated/Login";
import ResetPassword from "@/containers/NotAuthenticated/ResetPassword";
import Scheduler from "@/containers/Scheduler";

export default function DefibrionApp() {
  const { isAuthenticated } = React.useContext(AuthenticationContext);

  return (
    <BrowserRouter>
      {isAuthenticated ? (
        <Routes>
          <Route element={<Scheduler />} path="/scheduler" />
          <Route element={<AdminPanel />} path="*" />
        </Routes>
      ) : (
        <Routes>
          <Route element={<ResetPassword />} path="/reset-password" />
          <Route element={<Login />} path="/login" />
          <Route element={<Navigate to="/login" />} path="*" />
        </Routes>
      )}
    </BrowserRouter>
  );
}
