import { DatePicker } from "antd";
import { MonthPickerProps } from "antd/lib/date-picker";

export default function MonthPicker({ onChange, ...restProps }: MonthPickerProps) {
  const handleOnChange: MonthPickerProps["onChange"] = (date, dateString) => {
    onChange?.(date?.utcOffset(0, true).startOf("month") ?? null, dateString);
  };

  return <DatePicker.MonthPicker {...restProps} onChange={handleOnChange} />;
}
