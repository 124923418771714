import { Card } from "antd";
import * as React from "react";

import downloadAttachment from "@/functions/download-attachment";

import DownloadButton from "./DownloadButton";

export default function AppointmentOverviewSpreadsheet() {
  return (
    <Card title="Afspraken per relatie">
      <DownloadButton onClick={() => downloadAttachment("reports/appointment-overview")} type="primary">
        Downloaden
      </DownloadButton>
    </Card>
  );
}
