import { gql } from "@apollo/client";

export default gql`
  mutation (
    $id: ID!
    $allowMissingFields: Boolean
    $locationId: ID
    $productTypeId: ID
    $serialCode: String
    $productionBatch: String
    $expirationDate: DateTime
    $replacementDate: DateTime
    $locationDescription: String
    $optionalDescription: String
    $optionalProperties: [StringMapInput!]
    $brand: String
    $installedOn: DateTime
    $lastCheckedOn: DateTime
    $lastMajorMaintenanceOn: DateTime
    $condition: ProductCondition
    $parts: [UpdateServicedProductPartInput!]
    $manufacturingDate: DateTime
    $position: Int
  ) {
    updateServicedProduct(
      input: {
        id: $id
        allowMissingFields: $allowMissingFields
        locationId: $locationId
        productTypeId: $productTypeId
        serialCode: $serialCode
        productionBatch: $productionBatch
        expirationDate: $expirationDate
        replacementDate: $replacementDate
        locationDescription: $locationDescription
        optionalDescription: $optionalDescription
        optionalProperties: $optionalProperties
        brand: $brand
        installedOn: $installedOn
        lastCheckedOn: $lastCheckedOn
        lastMajorMaintenanceOn: $lastMajorMaintenanceOn
        condition: $condition
        manufacturingDate: $manufacturingDate
        position: $position
        parts: $parts
      }
    ) {
      product {
        ...ProductFields
        parts {
          ...ProductFields
        }
      }
    }
  }

  fragment ProductFields on ServicedProduct {
    id
    productType {
      id
      code
      description
      serialCodeExpr
      productionBatchExpr
      isExpirationDateRequired
      isManufacturingDateRequired
      majorMaintenanceInterval
      hasConsumables
      hasBrand
      expectedLifespan
    }
    brand
    serialCode
    productionBatch
    manufacturingDate
    expirationDate
    locationDescription
    optionalDescription
    position
    optionalProperties
    installedOn
    lastCheckedOn
    lastMajorMaintenanceOn
    replacementDate
    condition
  }
`;
