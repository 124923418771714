import { Popover, PopoverProps } from "antd";

interface MaybePopoverProps extends PopoverProps {
  if: boolean;
}

export default function MaybePopover({ if: if_, ...restProps }: MaybePopoverProps) {
  if (!if_) return restProps.children;

  return <Popover {...restProps} />;
}
