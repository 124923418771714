import { InboxOutlined, SwapOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Form, Modal, notification, Radio, Skeleton, Upload } from "antd";
import { useState } from "react";

import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

interface ReplaceWorksheetFileModalProps {
  onClose: () => void;
  worksheetId: string;
}

interface FormProps {
  currentFileId: string;
}

export default function ReplaceWorksheetFileModal({ onClose, worksheetId }: ReplaceWorksheetFileModalProps) {
  const [form] = Form.useForm();

  const { data } = useQuery(WorksheetQuery, { variables: { worksheetId } });
  const [replaceWorksheetFileAsync, { loading: replaceWorksheetFileLoading }] = useMutation(ReplaceWorksheetFileMutation);

  const [attachments, setAttachments] = useState<FileList | null>(null);

  const handleOnSubmit = async ({ currentFileId }: FormProps) => {
    if (null === attachments) return;

    try {
      await replaceWorksheetFileAsync({ variables: { worksheetId, currentFileId, replacementFile: attachments[0] } });
      notification.success({ message: "Bestand is aangepast" });

      onClose();
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Modal
      confirmLoading={replaceWorksheetFileLoading}
      onCancel={onClose}
      onOk={() => form.submit()}
      okButtonProps={{ icon: <SwapOutlined /> }}
      okText="Vervangen"
      title="Bestand vervangen"
      open
    >
      {undefined === data ? (
        <Skeleton />
      ) : (
        <Form form={form} initialValues={{ currentFileId: data.worksheet.files[0].id }} onFinish={handleOnSubmit} layout="vertical">
          <Form.Item name="currentFileId" label="Welk bestand wil je vervangen?">
            <Radio.Group>
              {data.worksheet.files.map(file => (
                <Radio key={file.id} value={file.id}>
                  {file.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item label="Welk bestand wil je gebruiken?">
            <Upload.Dragger
              beforeUpload={() => false}
              multiple
              onChange={({ fileList }) => {
                if (fileList.length < 1) return setAttachments(null);

                const dataTransfer = new DataTransfer();
                fileList.forEach(f => dataTransfer.items.add(f.originFileObj as never));
                setAttachments(dataTransfer.files);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Klik of sleep een bestand om toe te voegen als bijlage</p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}

const WorksheetQuery = gql`
  query ($worksheetId: ID!) {
    worksheet(id: $worksheetId) {
      id
      files {
        id
        name
      }
    }
  }
`;

const ReplaceWorksheetFileMutation = gql`
  mutation ($worksheetId: ID!, $currentFileId: ID!, $replacementFile: UploadedFile!) {
    replaceWorksheetFile(input: { worksheetId: $worksheetId, currentFileId: $currentFileId, replacementFile: $replacementFile }) {
      worksheet {
        id
        files {
          id
        }
      }
    }
  }
`;
