import * as React from "react";

export type MarkerType = "default" | "star" | "square" | "circle" | "hexagon";
export type MarkerRecord = { marker: MarkerType; relationName: string; opacity: number };

interface MarkersContext {
  markers: Record<string, MarkerRecord>;
  setMarkerTo(relationId: string, v: MarkerRecord | undefined): void;
}

export default React.createContext<MarkersContext>({
  markers: {},
  setMarkerTo: () => {
    return;
  },
});
