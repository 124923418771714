import { UnorderedListOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Select } from "antd";
import { SelectProps, SelectValue } from "antd/lib/select";
import React from "react";

interface AppointmentTypePickerProps<T> extends Omit<SelectProps<T>, "loading"> {
  allowSelectCategory?: boolean;
  disableDeleted?: boolean;
  onlyCategories?: boolean;
  preloadWithAppointmentType?: { id: string; name: string; parentCategoryId?: string; deletedAt: string | null };
}

export default function AppointmentTypePicker<T = SelectValue>({
  allowSelectCategory = false,
  disableDeleted = true,
  onlyCategories = false,
  preloadWithAppointmentType,
  ...props
}: AppointmentTypePickerProps<T>) {
  const { data, loading } = useQuery(AppointmentTypesQuery);

  return (
    <Select {...props} optionLabelProp="title" loading={loading}>
      {React.useMemo(() => {
        return data?.appointmentTypes.map(pt => {
          if (!onlyCategories && Array.isArray(pt.subTypes) && pt.subTypes.length > 0) {
            let subAppointmentTypes = pt.subTypes;
            // eslint-disable-next-line prettier/prettier
            if (preloadWithAppointmentType?.parentCategoryId === pt.id && !subAppointmentTypes.find(st => st.id === preloadWithAppointmentType?.id))
              subAppointmentTypes = [...subAppointmentTypes, preloadWithAppointmentType];

            return (
              <Select.OptGroup key={pt.id} label={pt.name}>
                {subAppointmentTypes.map(st => (
                  <Select.Option disabled={disableDeleted && null !== st.deletedAt} key={st.id} value={st.id} title={st.name}>
                    {st.name}
                  </Select.Option>
                ))}
                {allowSelectCategory && (
                  <Select.Option value={pt.id} title={pt.name}>
                    <UnorderedListOutlined />
                    {"  "}
                    <span>Alle {pt.name.toLocaleLowerCase()} afspraken</span>
                  </Select.Option>
                )}
              </Select.OptGroup>
            );
          }

          return (
            <Select.Option key={pt.id} disabled={disableDeleted && null !== pt.deletedAt} title={pt.name} value={pt.id}>
              {pt.name}
            </Select.Option>
          );
        });
      }, [allowSelectCategory, data, preloadWithAppointmentType, disableDeleted])}
    </Select>
  );
}

const AppointmentTypesQuery = gql`
  query {
    appointmentTypes {
      id
      name
      subTypes {
        id
        name
        deletedAt
      }
    }
  }
`;
