import { gql, useLazyQuery } from "@apollo/client";
import { Select, SelectProps, Spin } from "antd";
import * as React from "react";

import useDebounce from "@/hooks/use-debounce";

interface MultipleRelationPickerProps extends Omit<SelectProps, "onChange" | "value"> {
  onClear?: () => void;
  onChange?: (value: string[]) => void;
}

interface SelectedValue {
  label: React.ReactNode;
  value: string;
}

export default function MultipleRelationPicker({ onChange, ...restProps }: MultipleRelationPickerProps) {
  const [searchQueryAsync, { loading }] = useLazyQuery<{ relations: RelationProps[] }>(SearchQuery);
  const [dataSource, setDataSource] = React.useState<RelationProps[]>([]);
  const [values, setValues] = React.useState<SelectedValue[]>([]);
  const [query, setQuery] = React.useState("");
  const debouncedQuery = useDebounce(query);

  React.useEffect(() => {
    if (!debouncedQuery || (Number.isNaN(parseInt(debouncedQuery)) && debouncedQuery.length < 3)) return;

    async function main() {
      const response = await searchQueryAsync({ variables: { value: debouncedQuery } });
      const relations = response.data?.relations;
      if (undefined !== relations) setDataSource(relations);
    }

    main();
  }, [debouncedQuery]);

  const handleOnChange = (nextValue: Array<{ label: React.ReactNode; value: string }>) => {
    setValues(nextValue);
    onChange?.(nextValue.map(v => v.value));
  };

  return (
    <Select
      {...restProps}
      filterOption={false}
      labelInValue
      mode="multiple"
      notFoundContent={loading ? <Spin size="small" /> : null}
      onChange={handleOnChange}
      onSearch={value => setQuery(value)}
      value={values}
    >
      {dataSource.map(relation => (
        <Select.Option
          key={relation.id}
          value={relation.id}
          label={`${relation.afasCode ? `(${relation.afasCode})` : ""} ${relation.name}`.trim()}
        >
          <div>{`${relation.afasCode ? `(${relation.afasCode})` : ""} ${relation.name}`.trim()}</div>
          <small>{relation.place}</small>
        </Select.Option>
      ))}
    </Select>
  );
}

interface RelationProps {
  id: string;
  name: string;
  place: string;
  afasCode: string | null;
}

const SearchQuery = gql`
  query ($value: String!) {
    relations(searchParam: $value) {
      id
      name
      place
      afasCode
    }
  }
`;
