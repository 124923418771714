import Constants from "@/constants";

export async function refreshJwtToken() {
  const response = await fetch(Constants.backendUrl + "/users/refresh_check", {
    method: "POST",
    credentials: "include",
  });

  return response.ok;
}
