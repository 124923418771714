import { gql, useQuery } from "@apollo/client";
import { Divider, Space, Table } from "antd";
import * as React from "react";

import { isInstallation } from "@/functions/appointment-util";
import formatProductDescription from "@/functions/format-product-description";

interface ServicedProductReplaceable {
  id: string;
  productType: {
    id: string;
    description: string;
  };
}

function groupReplaceableProducts(products: ServicedProductReplaceable[]) {
  const retVal: Record<string, ServicedProductReplaceable & { amount: number }> = {};

  for (const product of products) {
    if (product.productType.id in retVal) {
      retVal[product.productType.id].amount += 1;
      continue;
    }

    retVal[product.productType.id] = {
      ...product,
      amount: 1,
    };
  }

  return Object.values(retVal);
}

export default function ProductsTables({ appointment }) {
  const { data: servicedProductsSummaryData, loading: servicedProductsSummaryLoading } = useQuery(ServicedProductsSummaryQuery, {
    variables: { relationId: appointment.relation.id, locationId: appointment.location.id },
    skip: isInstallation(appointment),
  });

  const { data: servicedProductsReplaceableData, loading: servicedProductsReplaceableLoading } = useQuery<{
    servicedProductsReplaceable: ServicedProductReplaceable[];
  }>(ServicedProductsReplaceableQuery, {
    variables: { locationId: appointment.location.id, onDate: appointment.startTime ?? appointment.prospectiveDate },
  });

  const groupedReplaceableProducts = React.useMemo(
    () => groupReplaceableProducts(servicedProductsReplaceableData?.servicedProductsReplaceable ?? []),
    [servicedProductsReplaceableData]
  );

  return (
    <>
      <Divider>Producten</Divider>
      <Space direction="vertical" size="middle">
        <Table
          columns={[
            {
              ellipsis: true,
              title: "Product type",
              render: product => formatProductDescription(product),
            },
            {
              align: "right",
              title: "Aantal",
              dataIndex: "amount",
              width: "15%",
            },
          ]}
          dataSource={servicedProductsSummaryData?.servicedProductsSummary ?? []}
          loading={servicedProductsSummaryLoading}
          pagination={servicedProductsSummaryData?.servicedProductsSummary?.length > 10 ? undefined : false}
          rowKey={row => (row as any).productType.id}
          title={() => "Producten op locatie"}
          size="small"
        />
        {groupedReplaceableProducts.length > 0 && (
          <Table
            columns={[
              {
                ellipsis: true,
                title: "Product type",
                render: product => formatProductDescription(product),
              },
              {
                align: "right",
                title: "Aantal",
                dataIndex: "amount",
                width: "15%",
              },
            ]}
            dataSource={groupedReplaceableProducts}
            loading={servicedProductsReplaceableLoading}
            pagination={groupedReplaceableProducts.length > 10 ? undefined : false}
            rowKey={row => (row as any).id}
            title={() => "Producten aan vervanging/ groot onderhoud toe"}
            size="small"
          />
        )}
        {appointment.consumableGoods.length > 0 && (
          <Table
            columns={[
              {
                title: "Product type",
                dataIndex: ["productType", "description"],
              },
              {
                align: "right",
                title: "Aantal",
                dataIndex: "amount",
                width: "15%",
              },
            ]}
            dataSource={appointment.consumableGoods}
            pagination={appointment.consumableGoods.length > 10 ? undefined : false}
            rowKey={row => (row as any).id}
            title={() => "Producten mee te nemen"}
            size="small"
          />
        )}
      </Space>
    </>
  );
}

const ServicedProductsSummaryQuery = gql`
  query ($relationId: ID!, $locationId: ID!) {
    servicedProductsSummary(relationId: $relationId, locationId: $locationId, skipDestroyed: true) {
      productType {
        id
        code
        description
      }
      amount
    }
  }
`;

const ServicedProductsReplaceableQuery = gql`
  query ($locationId: ID!, $onDate: DateTime!) {
    servicedProductsReplaceable(locationId: $locationId, onDate: $onDate) {
      id
      productType {
        id
        code
        description
      }
    }
  }
`;
