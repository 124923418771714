import * as React from "react";

import ShowIssueModal from "@/components/ShowIssueModal";

import IssueTableContainer from "./containers/IssueTableContainer";

export default function ListIssues() {
  const [status, setStatus] = React.useState(["STATUS_PENDING", "STATUS_RAISED"]);
  const [selectedIssueId, setSelectedIssueId] = React.useState<string | undefined>();

  const handleOnClick = (issueId: string) => {
    setSelectedIssueId(issueId);
  };

  return (
    <>
      <IssueTableContainer
        onClick={handleOnClick}
        onTableChange={(_, filters) => {
          if (!filters.status) return;
          setStatus(filters.status);
        }}
        status={status}
      />
      {/* eslint-disable-next-line prettier/prettier */}
      {undefined !== selectedIssueId && (
        <ShowIssueModal
          onClose={() => setSelectedIssueId(undefined)}
          issueId={selectedIssueId}
        />
      )}
    </>
  );
}
