import "./index.css";

import { Col, Row } from "antd";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import useAuthState from "@/authorization/use-auth-state";
import Gravatar from "@/components/Gravatar";
import RegisterIssueModal from "@/components/RegisterIssueModal";
import Section from "@/components/Section";
import ShowIssueModal from "@/components/ShowIssueModal";

import IssueListCard from "./components/IssueListCard";

export default function Dashboard() {
  const navigate = useNavigate();
  // eslint-disable-next-line prettier/prettier
  const { user: { email } } = useAuthState();

  const [raiseIssueModalVisible, setRaiseIssueModalVisible] = React.useState(false);
  const [selectedIssueId, setSelectedIssueId] = React.useState();

  const handleOnIssueClick = issueId => {
    setSelectedIssueId(issueId);
  };

  const handleOnShowAllClick = () => {
    navigate("/issues");
  };

  return (
    <>
      {undefined !== selectedIssueId && <ShowIssueModal onClose={() => setSelectedIssueId(undefined)} issueId={selectedIssueId} />}
      {raiseIssueModalVisible && <RegisterIssueModal onClose={() => setRaiseIssueModalVisible(false)} />}
      <Row>
        <Section className="pageHeaderContent">
          <div className="avatar">
            <span className="ant-avatar ant-avatar-circle ant-avatar-lg ant-avatar-image">
              <Gravatar email={email} size={72} />
            </span>
          </div>
          <div className="content">
            <p className="contentTitle">Welcome back!</p>
            <p>What shall we put here?</p>
          </div>
        </Section>
      </Row>
      <Row justify="space-between" gutter={32} style={{ marginTop: 15 }}>
        <Col md={16}>
          <IssueListCard
            onClick={handleOnIssueClick}
            onCreateClick={() => setRaiseIssueModalVisible(true)}
            onShowAllClick={handleOnShowAllClick}
          />
        </Col>
      </Row>
    </>
  );
}
