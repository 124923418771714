import dayjs from "dayjs";
import { Event } from "react-big-calendar";

import AgendaEvent from "./AgendaEvent";

interface RelationAgendaProps {
  events: Array<Event & { __appointment: object }>;
  endAccessor: (event: Event) => Date;
  onSelectEvent: (event: { __appointmentId: string | undefined; __isShowingRouteOnMap: boolean; startTime: Date; endTime: Date }) => void;
  startAccessor: (event: Event) => Date;
}

export default function RelationAgenda({ events, endAccessor, onSelectEvent, startAccessor }: RelationAgendaProps) {
  return (
    <div className="rbc-calendar" style={{ minHeight: 700, width: "100%" }}>
      <div className="rbc-agenda-view">
        <table className="rbc-agenda-table">
          <thead>
            <tr>
              <th className="rbc-header">Datum</th>
              <th className="rbc-header">Tijd</th>
              <th className="rbc-header">Afspraak</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event, index) => {
              return (
                <tr key={index}>
                  <td className="rbc-agenda-date-cell">{dayjs(startAccessor(event)).format("DD-MM-YYYY")}</td>
                  <td className="rbc-agenda-time-cell">
                    <span>
                      {dayjs(startAccessor(event)).format("HH:mm")} — {dayjs(endAccessor(event)).format("HH:mm")}
                    </span>
                  </td>
                  <td className="rbc-agenda-event-cell">
                    <AgendaEvent event={event} onSelectEvent={onSelectEvent} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
