import { Col, Row } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import AppointmentModal from "@/components/AppointmentModal";

import EditLocationModal from "../components/EditLocationModal";
import MoveLocationModal from "../components/MoveLocationModal";
import PastAndFutureAppointmentsModal from "../components/PastAndFutureAppointmentsModal";
import RegisterLocationModal from "../components/RegisterLocationModal";
import AppointmentsCard from "../components/RelationTabPaneCard/AppointmentsCard";
import ContractCard from "../components/RelationTabPaneCard/ContractCard";
import LocationCard from "../components/RelationTabPaneCard/LocationCard";
import LocationsCardContainer from "../components/RelationTabPaneCard/LocationsCard";
import UpdateRelationModal from "../components/UpdateRelationModal";
import RelationCard from "../components/RelationTabPaneCard/RelationCard";

interface RelationTabPaneContainerProps {
  onChangeLocation: (locationId: string) => void;
  relationId: string;
  locationId?: string;
}

export default function RelationTabPaneContainer({ onChangeLocation, relationId, locationId }: RelationTabPaneContainerProps) {
  const navigate = useNavigate();

  const [isAppointmentOpenWithId, setIsAppointmentOpenWithId] = useState<string | undefined>();
  const [isAppointmentListOpen, setIsAppointmentListOpen] = useState(false);
  const [isMoveLocationModalOpen, setIsMoveLocationModalOpen] = useState(false);
  const [isRegisterLocationModalOpen, setIsRegisterLocationModalOpen] = useState(false);
  const [isUpdateLocationModalOpen, setIsUpdateLocationModalOpen] = useState(false);
  const [isUpdateRelationModalOpen, setIsUpdateRelationModalOpen] = useState(false);

  const handleOpenAppointment = (record: object) => {
    if (typeof record.worksheetId === "string") {
      return navigate("/worksheets/" + record.worksheetId);
    }

    setIsAppointmentOpenWithId(record.appointmentId);
    setIsAppointmentListOpen(false);
  };

  const handleOpenContract = (contractId: string) => {
    navigate("/contracts/" + contractId);
  };

  return (
    <>
      {undefined !== isAppointmentOpenWithId && (
        <AppointmentModal appointmentId={isAppointmentOpenWithId} onClose={() => setIsAppointmentOpenWithId(undefined)} />
      )}

      {isAppointmentListOpen && undefined !== locationId && (
        <PastAndFutureAppointmentsModal
          onClick={handleOpenAppointment}
          onClose={() => setIsAppointmentListOpen(false)}
          relationId={relationId}
          locationId={locationId}
        />
      )}

      {isMoveLocationModalOpen && undefined !== locationId && (
        <MoveLocationModal relationId={relationId} locationId={locationId} onClose={() => setIsMoveLocationModalOpen(false)} />
      )}

      {isRegisterLocationModalOpen && (
        <RegisterLocationModal relationId={relationId} onClose={() => setIsRegisterLocationModalOpen(false)} />
      )}

      {isUpdateLocationModalOpen && undefined !== locationId && (
        <EditLocationModal relationId={relationId} locationId={locationId} onClose={() => setIsUpdateLocationModalOpen(false)} />
      )}

      {isUpdateRelationModalOpen && undefined !== locationId && (
        <UpdateRelationModal relationId={relationId} onClose={() => setIsUpdateRelationModalOpen(false)} />
      )}

      <Row gutter={24} justify="space-between">
        <Col span={12} style={{ display: "flex", flexDirection: "column", rowGap: 24 }}>
          <RelationCard key="relation-card" relationId={relationId} onEditClick={() => setIsUpdateRelationModalOpen(true)} />
          <ContractCard key="contract-card" onClick={handleOpenContract} relationId={relationId} />
          {undefined !== locationId && (
            <AppointmentsCard
              key="appointments-card"
              onClick={record => handleOpenAppointment(record)}
              onMoreClick={() => setIsAppointmentListOpen(true)}
              relationId={relationId}
              locationId={locationId}
            />
          )}
        </Col>
        <Col span={12} style={{ display: "flex", flexDirection: "column", rowGap: 24 }}>
          <LocationsCardContainer
            key="locations-card"
            relationId={relationId}
            locationId={locationId}
            onAdd={() => setIsRegisterLocationModalOpen(true)}
            onChange={onChangeLocation}
          />
          {undefined !== locationId && (
            <LocationCard
              relationId={relationId}
              locationId={locationId}
              onEditPress={() => setIsUpdateLocationModalOpen(true)}
              onMovePress={() => setIsMoveLocationModalOpen(true)}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
