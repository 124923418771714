import * as React from "react";

export default function useDebouncedState<T>(initialValue: T, timeoutMs = 1000) {
  const timeoutId = React.useRef<NodeJS.Timer>();

  const [immediateState, setImmediateState] = React.useState(initialValue);
  const [delayedState, setDelayedState] = React.useState(immediateState);

  const setStateDebouced = (value: T) => {
    if (undefined !== timeoutId.current) clearTimeout(timeoutId.current);

    setImmediateState(value);
    timeoutId.current = setTimeout(() => setDelayedState(value), timeoutMs);
  };

  return [immediateState, delayedState, setStateDebouced] as const;
}
