import { useApolloClient } from "@apollo/client";
import { Flex, Spin } from "antd";
import { useEffect, useMemo, useState } from "react";

import Constants from "@/constants";

import AuthenticationContext from "./context";
import initialValues from "./initial-values";
import { loadWhoami } from "./load-whoami";
import { refreshJwtToken } from "./refresh-jwt-token";

export function AuthenticationContextProvider({ children }: { children: React.ReactNode }) {
  const apolloClient = useApolloClient();

  const [isBooting, setIsBooting] = useState(true);
  const [state, setState] = useState(initialValues);

  useEffect(() => {
    (async function main() {
      const response = await loadWhoami();

      // no active session known
      if (response === undefined) {
        setIsBooting(false);
        return;
      }

      // user is logged in.
      setState(current => ({ ...current, isAuthenticated: true, user: { id: response.user.id, email: response.user.email } }));
      setIsBooting(false);
    })();
  }, []);

  const handleOnResetPassword = async (token: string, plainPassword: string) => {
    setState(state => ({
      ...state,
      isFetching: true,
    }));

    try {
      const response = await fetch(Constants.backendUrl + "/users/reset-password", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `_confirmationToken=${encodeURIComponent(token)}&_password=${encodeURIComponent(plainPassword)}`,
      });

      if (!response.ok) throw new Error("Could not reset password");
    } finally {
      setState(state => ({
        ...state,
        isFetching: false,
      }));
    }
  };

  const handleOnCheckPasswordResetToken = async (confirmationToken: string) => {
    setState(state => ({
      ...state,
      isFetching: true,
    }));

    try {
      const response = await fetch(Constants.backendUrl + "/users/confirmation-token?v=" + confirmationToken);
      if (!response.ok) throw new Error("Could not find user for token");

      const content = (await response.json()) as { user: { email: string } };
      if (!content.user?.email) throw new Error("Could not find user for token");

      return content.user.email;
    } finally {
      setState(state => ({
        ...state,
        isFetching: false,
      }));
    }
  };

  const handleOnRequestPasswordReset = async (emailAddress: string) => {
    setState(state => ({
      ...state,
      isFetching: true,
    }));

    try {
      const response = await fetch(Constants.backendUrl + "/users/request-password-reset", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `_username=${encodeURIComponent(emailAddress)}`,
      });

      if (!response.ok) throw new Error("Could not request new password");
    } finally {
      setState(state => ({
        ...state,
        isFetching: false,
      }));
    }
  };

  const handleOnLogin = async (emailAddress: string, password: string) => {
    setState(state => ({
      ...state,
      isFetching: true,
      isAuthenticated: false,
      user: { id: 0, email: emailAddress },
    }));

    try {
      const response = await fetch(Constants.backendUrl + "/users/login_check", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `_username=${encodeURIComponent(emailAddress)}&_password=${encodeURIComponent(password)}`,
        credentials: "include",
      });

      const content = (await response.json()) as { email: string; user_id: string } | { code: number; message: string };
      if (content.code && content.code === 401) throw new Error(content.message);

      if (!content.email) throw new Error("Could not obtain access token");
      if (!content.is_emp) throw new Error("You are not an employee");

      localStorage.setItem("hasActiveSession", "1");

      setState(state => ({
        ...state,
        isAuthenticated: true,
        isFetching: false,
        user: { id: parseInt(content.user_id), email: content.email },
      }));
    } catch (error) {
      setState(state => ({
        ...state,
        isAuthenticated: false,
        isFetching: false,
        user: { id: 0, email: undefined },
      }));

      throw error;
    }
  };

  const handleOnLogout = async () => {
    try {
      await fetch(Constants.backendUrl + "/users/logout", {
        credentials: "include",
      });
    } catch (_error) {
      // ..
    }

    localStorage.removeItem("userJwtToken");
    localStorage.removeItem("refreshJwtToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("hasSession");
    apolloClient.resetStore();

    setState(state => ({
      ...state,
      isAuthenticated: false,
      isFetching: false,
      user: {
        id: 0,
        email: initialValues.user.email,
      },
    }));
  };

  const authContextValue = useMemo(
    () => ({
      ...state,
      loginUser: handleOnLogin,
      logoutUser: handleOnLogout,
      requestPasswordReset: handleOnRequestPasswordReset,
      checkPasswordResetToken: handleOnCheckPasswordResetToken,
      resetPassword: handleOnResetPassword,
    }),
    [state]
  );

  return (
    <AuthenticationContext.Provider value={authContextValue}>
      {isBooting ? (
        <Flex align="center" justify="center" style={{ height: "100vh", width: "100vw" }}>
          <Spin />
        </Flex>
      ) : (
        children
      )}
    </AuthenticationContext.Provider>
  );
}

export { refreshJwtToken };
