import { ApolloError } from "@apollo/client/errors";
import { notification } from "antd";

export default function mapGraphQLErrorsToNotifications({ graphQLErrors }: ApolloError) {
  graphQLErrors.forEach(error => {
    notification.error({
      message: "Fout tijdens het ophalen van de gegevens.",
      description: error.message,
    });
  });
}
