import { cloneElement } from "react";

export default function InformationItem({ icon, value }: { icon: React.ReactElement; value?: React.ReactNode }) {
  if (!value) return null;

  return (
    <div>
      {cloneElement(icon, { style: { fontSize: "0.9rem" } })}
      <span style={{ fontSize: "0.85rem", marginLeft: 12 }}>{value}</span>
    </div>
  );
}
