import { gql, useQuery } from "@apollo/client";
import { Transfer, TransferProps } from "antd";
import { useMemo } from "react";

// Default list of groups that should not be choosable.
const UNAVAILABLE_GROUPS = ["GROUP_CUSTOMERS", "GROUP_EMPLOYEES"];

export default function UserGroupTransfer({ value, ...props }: Omit<TransferProps<{ key: string; title: string }>, "dataSource">) {
  const { data } = useQuery(UserGroupQuery);
  const dataSource = useMemo(
    () => data?.userGroups.filter(v => !UNAVAILABLE_GROUPS.includes(v.name)).map(v => ({ key: v.id, title: v.name })) ?? [],
    [data]
  );

  return (
    <Transfer
      {...props}
      dataSource={dataSource}
      render={item => item.title}
      targetKeys={value}
      titles={["Beschikbaar", "Actief"]}
      showSearch={false}
      showSelectAll={false}
    />
  );
}

const UserGroupQuery = gql`
  query {
    userGroups {
      id
      name
    }
  }
`;
