import { Card } from "antd";
import * as React from "react";

import downloadAttachment from "@/functions/download-attachment";

import DownloadButton from "./DownloadButton";

export default function IssuesSpreadsheet() {
  return (
    <Card title="Alle meldingen">
      <DownloadButton type="primary" onClick={() => downloadAttachment("reports/issues")}>
        Downloaden
      </DownloadButton>
    </Card>
  );
}
