import { CheckOutlined } from "@ant-design/icons";
import { ApolloError, gql, useApolloClient, useMutation } from "@apollo/client";
import { Button, Card, DatePicker, Form, Input, notification, Space } from "antd";
import { useRef, useState } from "react";

import AppointmentTypePicker from "@/components/AppointmentTypePicker";
import LocationPicker from "@/components/LocationPicker";
import MarkdownTextInput from "@/components/MarkdownTextInput";
import ProductTypePicker from "@/components/ProductTypePicker";
import RelationPicker from "@/components/RelationPicker";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import ConsumableGoodsTable from "./ConsumableGoodsTable";

interface RequestAppointmentInput {
  relationId: string;
  locationId: string;
  appointmentTypeId: string;
  prospectiveDate: Date;
  plannerComment: string | null;
  comment: string | null;
}

type ProductType = { id: string; code: string; description: string };
export default function RequestOtherAppointment() {
  const [form] = Form.useForm();
  const client = useApolloClient();
  const [requestAppointmentAsync, { loading }] = useMutation(RequestAppointmentMutation);

  const productTypePickerRef = useRef<React.ElementRef<typeof ProductTypePicker>>(null);
  const [isSelectingProductType, setIsSelectingProductType] = useState(false);
  const [consumableGoods, setConsumableGoods] = useState<Array<{ productType: ProductType; amount: number }>>([]);

  const handleOnSubmit = async (values: RequestAppointmentInput) => {
    try {
      await requestAppointmentAsync({
        variables: {
          ...values,
          consumableGoods: consumableGoods
            .filter(element => element.amount > 0)
            .map(element => ({
              productTypeId: parseInt(element.productType.id, 10),
              amount: element.amount,
            })),
        },
      });

      notification.success({
        message: "Afspraak aangevraagd",
        description: "Uw afspraak is aangevraagd.",
      });

      form.resetFields();
      setConsumableGoods([]);
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const handleOnSelectProductType = (productTypeId: string) => {
    const productType = client.readFragment({
      id: "ProductType:" + productTypeId,
      fragment: gql`
        fragment ConsumableGood_ProductType on ProductType {
          id
          code
          description
        }
      `,
    });

    setConsumableGoods(current => [...current, { productType, amount: 1 }]);
    setIsSelectingProductType(false);
  };

  return (
    <Form onFinish={handleOnSubmit} form={form} layout="horizontal" labelCol={{ span: 4 }}>
      <Space direction="vertical">
        <Card bodyStyle={{ display: "flex", flexDirection: "row", gap: 8 }}>
          <div style={{ flex: 1 }}>
            <Form.Item name="relationId" label="Relatie" required rules={[{ required: true }]}>
              <RelationPicker />
            </Form.Item>
            <Form.Item name="locationId" label="Locatie" required rules={[{ required: true }]}>
              <LocationPicker relationId={Form.useWatch("relationId", form)} />
            </Form.Item>
            <Form.Item name="appointmentTypeId" label="Soort" required rules={[{ required: true }]}>
              <AppointmentTypePicker />
            </Form.Item>
            <Form.Item name="prospectiveDate" label="Beoogde datum" required rules={[{ required: true }]}>
              <DatePicker />
            </Form.Item>
          </div>
          <div style={{ flex: 1 }}>
            <Form.Item name="plannerComment" label="Planning notities" labelCol={{ span: 6 }}>
              <MarkdownTextInput />
            </Form.Item>
            <Form.Item label="Buitendienst notities" name="onSiteComment" labelCol={{ span: 6 }}>
              <Input.TextArea rows={3} />
            </Form.Item>
          </div>
        </Card>
        <Card bodyStyle={{ padding: 0 }} title="Producten om mee te nemen">
          <ConsumableGoodsTable
            caption={
              <>
                {!isSelectingProductType ? (
                  <Button
                    onClick={() => {
                      setIsSelectingProductType(true);
                      setTimeout(() => productTypePickerRef.current?.focus(), 500);
                    }}
                  >
                    {consumableGoods.length < 1 ? "Eerste product toevoegen" : "Nog een product toevoegen"}
                  </Button>
                ) : (
                  <ProductTypePicker
                    ref={productTypePickerRef}
                    dropdownMatchSelectWidth={false}
                    onChange={handleOnSelectProductType}
                    onClear={() => setIsSelectingProductType(false)}
                    style={{ width: 350 }}
                  />
                )}
              </>
            }
            data={consumableGoods}
            onChange={setConsumableGoods}
          />
        </Card>
        <Button loading={loading} htmlType="submit" type="primary" icon={<CheckOutlined />}>
          Aanvragen
        </Button>
      </Space>
    </Form>
  );
}

const RequestAppointmentMutation = gql`
  mutation (
    $appointmentTypeId: ID!
    $relationId: ID!
    $locationId: ID!
    $prospectiveDate: DateTime!
    $onSiteComment: String
    $plannerComment: String
    $consumableGoods: [ConsumableGoodInput!]
  ) {
    requestAppointment(
      input: {
        appointmentTypeId: $appointmentTypeId
        relationId: $relationId
        locationId: $locationId
        prospectiveDate: $prospectiveDate
        onSiteComment: $onSiteComment
        plannerComment: $plannerComment
        consumableGoods: $consumableGoods
      }
    ) {
      appointment {
        id
      }
    }
  }
`;
