import { ApolloError, gql, useMutation } from "@apollo/client";
import { Form, Input, Modal, notification } from "antd";

import LocalePicker from "@/components/LocalePicker";
import RelationPicker from "@/components/RelationPicker";
import UserGroupTransfer from "@/components/UserGroupTransfer";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

interface CreateUserModalProps {
  onClose: () => void;
  relationId?: string;
  userType: "GROUP_EMPLOYEES" | "GROUP_CUSTOMERS";
}

export default function CreateUserModal({ onClose, relationId, userType }: CreateUserModalProps) {
  const [formInstance] = Form.useForm();
  const [createUserAsync, { loading: isLoading }] = useMutation(
    userType === "GROUP_EMPLOYEES" ? RegisterEmployeeMutation : RegisterCustomerMutation
  );

  const handleOnSubmit = async values => {
    try {
      await createUserAsync({ variables: values });
      onClose();

      notification.success({ message: "Gebruiker is aangemaakt" });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Modal confirmLoading={isLoading} onOk={() => formInstance.submit()} onCancel={() => onClose()} open title="Gebruiker aanmaken">
      <Form form={formInstance} onFinish={handleOnSubmit} initialValues={{ accessibleRelationId: relationId }} layout="vertical">
        <Form.Item name="username" label="Gebruikersnaam" required rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label="E-mail" required rules={[{ required: true }]}>
          <Input type="email" />
        </Form.Item>
        <Form.Item name="locale" label="Taal" required rules={[{ required: true }]}>
          <LocalePicker />
        </Form.Item>
        {userType === "GROUP_EMPLOYEES" ? (
          <Form.Item name="userGroupIds" label="Gebruikersgroepen">
            <UserGroupTransfer />
          </Form.Item>
        ) : (
          <Form.Item name="accessibleRelationId" label="Gekoppelde relatie" required rules={[{ required: true }]}>
            <RelationPicker />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}

const RegisterCustomerMutation = gql`
  mutation ($username: String!, $email: String!, $locale: String!, $accessibleRelationId: ID!) {
    registerCustomer(input: { username: $username, email: $email, locale: $locale, accessibleRelationId: $accessibleRelationId }) {
      customer {
        id
        username
      }
    }
  }
`;

const RegisterEmployeeMutation = gql`
  mutation ($username: String!, $email: String!, $locale: String!, $userGroupIds: [ID!]) {
    registerEmployee(input: { username: $username, email: $email, locale: $locale, userGroupIds: $userGroupIds }) {
      employee {
        id
        username
      }
    }
  }
`;
