import "dayjs/locale/nl";

import { ConfigProvider } from "antd";
import nlNL from "antd/es/locale/nl_NL";
import { ConfigProviderProps } from "antd/lib/config-provider";
import dayjs from "dayjs";
import { IntlProvider } from "react-intl";

import nlMessages from "@/localization/nl_NL";

dayjs.locale("nl");
export default function AppLocaleProvider(props: Omit<ConfigProviderProps, "locale">) {
  return (
    <IntlProvider locale="nl" textComponent="span" messages={nlMessages}>
      <ConfigProvider {...props} locale={nlNL} />
    </IntlProvider>
  );
}
