export default function formatDuration(dateInterval: string) {
  const regex = /P(\d+Y)?(\d+M)?(\d+D)?T(\d+H)?(\d+M)?(\d+S)?/;

  const match = regex.exec(dateInterval);
  if (!match) {
    return "Invalid date interval format";
  }

  const [, years, months, days, hours, minutes, seconds] = match;
  const parts = [
    { value: years, unit: "year" },
    { value: months, unit: "month" },
    { value: days, unit: "day" },
    { value: hours, unit: "hour" },
    { value: minutes, unit: "minute" },
    { value: seconds, unit: "second" },
  ];

  const duration = parts
    .filter(part => part.value)
    .filter(part => !part.value.startsWith("00"))
    .map(part => {
      const value = parseInt(part.value);
      const unit = value === 1 ? part.unit : part.unit + "s";
      return `${value} ${unit}`;
    });

  return duration.join(", ");
}
