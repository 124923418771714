import { gql, useQuery } from "@apollo/client";
import { Alert, Table } from "antd";
import dayjs from "dayjs";
import * as React from "react";
import { RRule } from "rrule";

interface RecurrencesPreviewTableProps {
  visitId?: string;
  startingOn?: dayjs.Dayjs;
  endingOn?: dayjs.Dayjs;
  interval: number;
  isRecurrenceChanged?: boolean;
  repeat: number[];
  rescheduleFrom?: "CURRENT_CALENDAR_YEAR" | "NEXT_CALENDAR_YEAR";
}

// eslint-disable-next-line prettier/prettier
export default function RecurrencesPreviewTable({ visitId, startingOn, endingOn, interval, isRecurrenceChanged, repeat, rescheduleFrom }: RecurrencesPreviewTableProps) {
  const recurrence = React.useMemo(() => new RRule({ interval, bymonth: repeat, freq: RRule.YEARLY }).toString(), [interval, repeat]);

  const { data, loading } = useQuery(GenerateRecurrencesPreviewQuery, {
    skip: undefined === interval || undefined === repeat,
    variables:
      false === isRecurrenceChanged && undefined !== visitId
        ? {
            visitId,
          }
        : {
            visitId,
            recurrence,
            rescheduleFrom,
            startingOn,
            endingOn,
          },
  });

  const plannedUntil = data?.generateRecurrencesPreview?.plannedUntil ?? undefined;
  const dataSource = React.useMemo(
    () => [
      ...(data?.generateRecurrencesPreview?.keepAppointments ?? []).map(value => ({
        date: value.startTime ?? value.prospectiveDate,
        status: value.status,
      })),
      ...(data?.generateRecurrencesPreview.recurrences ?? []).map(value => ({
        date: value,
        status: undefined,
      })),
    ],
    [data]
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
      {data?.generateRecurrencesPreview.warnForMissingPotentialAppointment && (
        <Alert
          showIcon
          message="Een afspraak in het huidige jaar wordt overgeslagen. Dit gebeurt als herhaalmaand al is geweest in het huidige jaar. Afspraken in het verleden worden niet aangemaakt."
          type="warning"
        />
      )}
      <Table
        bordered
        columns={[
          { title: "Datum", dataIndex: "date", render: value => dayjs(value).format("LL") },
          { title: "Status", dataIndex: "status", render: value => (undefined !== value ? STATUS_LABEL_MAP[value] : "Nog te plannen") },
        ]}
        dataSource={dataSource}
        loading={loading}
        rowClassName={record => {
          return record.status !== undefined ? "recurrences-keep-appointment" : "";
        }}
        rowKey={record => record.date.toString()}
        title={() => (
          // eslint-disable-next-line prettier/prettier
        <span>{`Voorvertoning afspraken ${undefined !== plannedUntil ? `(gepland tot: ${dayjs(plannedUntil).format("LL")})` : ""}`}</span>
        )}
        size="small"
        pagination={false}
      />
    </div>
  );
}

const STATUS_LABEL_MAP = {
  STATUS_REQUESTED: "Aangevraagd",
  STATUS_SCHEDULED: "Ingeroosterd",
  STATUS_CONFIRMED: "Bevestigd",
  STATUS_CANCELLED: "Geannuleerd",
};

const GenerateRecurrencesPreviewQuery = gql`
  query ($visitId: ID, $recurrence: String, $startingOn: DateTime, $endingOn: DateTime, $rescheduleFrom: RescheduleRecurrencesFromMode) {
    generateRecurrencesPreview(
      visitId: $visitId
      recurrence: $recurrence
      startingOn: $startingOn
      endingOn: $endingOn
      rescheduleFrom: $rescheduleFrom
    ) {
      plannedUntil
      recurrences
      warnForMissingPotentialAppointment
      keepAppointments {
        prospectiveDate
        startTime
        status
      }
    }
  }
`;
