import { FrownOutlined, SearchOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Button, Card, Col, Row } from "antd";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

import OptionalInformation from "@/components/OptionalInformation";

interface ContractCardProps {
  onClick: (contractId: string) => void;
  relationId: string;
}

export default function ContractCard({ onClick, relationId }: ContractCardProps) {
  const { data, loading } = useQuery(ContractQuery, { variables: { relationId } });
  const contract = data?.contract;

  return (
    <Card
      loading={loading}
      title="Contract"
      extra={
        undefined !== contract?.id && (
          <Button type="primary" onClick={() => onClick(contract.id)}>
            <SearchOutlined />
            <FormattedMessage id="view" />
          </Button>
        )
      }
    >
      {undefined === contract || null === contract ? (
        <div style={{ alignItems: "center", display: "flex", justifyContent: "center", columnGap: 8 }}>
          <FrownOutlined style={{ fontSize: 24, display: "block" }} />
          <span>Geen contract gevonden</span>
        </div>
      ) : (
        <Row justify="space-between">
          <Col md={11}>
            <OptionalInformation id="contract.startDate" value={dayjs(contract.startingOn).format("MMMM, YYYY")} />
            {contract.endingOn && <OptionalInformation id="contract.endDate" value={dayjs(contract.endingOn).format("MMMM, YYYY")} />}
          </Col>
        </Row>
      )}
    </Card>
  );
}

const ContractQuery = gql`
  query ($relationId: ID!) {
    contract(relationId: $relationId) {
      id
      startingOn
      endingOn
    }
  }
`;
