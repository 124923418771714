import { PlaySquareOutlined, StopOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Button, Form, Input, Modal, notification, Popconfirm, Skeleton } from "antd";

import Constants from "@/constants";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import UserGroupTransfer from "./UserGroupTransfer";

interface UpdateUserModalProps {
  onClose: () => void;
  userId: string;
  userType: "GROUP_EMPLOYEES" | "GROUP_CUSTOMERS";
}

export default function UpdateUserModal({ onClose, userId, userType }: UpdateUserModalProps) {
  const [formInstance] = Form.useForm();

  const userQuery = useQuery(UserQuery, { variables: { userId } });
  const linkedRelationQuery = useQuery(LinkedRelationQuery, {
    variables: { userId },
    skip: userType === "GROUP_EMPLOYEES",
  });

  const [updateUserGroupsAsync, { loading: isLoading }] = useMutation(UpdateUserUserGroupsMutation);
  const [changeUserStatusAsync] = useMutation(ChangeUserStatusMutation);

  const handleOnChangeActivation = async () => {
    if (userQuery.data === undefined) {
      return;
    }

    try {
      await changeUserStatusAsync({
        variables: {
          userId,
          enabled: !userQuery.data.user.enabled,
        },
      });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const handleOnSubmit = async values => {
    if (userType === "GROUP_CUSTOMERS") {
      return onClose(); // nothing to submit
    }

    try {
      await updateUserGroupsAsync({
        variables: {
          userId,
          userGroupIds: values.userGroupIds,
        },
      });

      onClose();
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const handleOnRequestPassword = async () => {
    if (userQuery.data === undefined) {
      return;
    }

    try {
      await fetch(Constants.backendUrl + "/users/request-password-reset", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `_username=${encodeURIComponent(userQuery.data.user.email)}`,
      });

      notification.success({ message: "Wachtwoord (opnieuw) aangevraagd" });
    } catch (error) {
      notification.error({ message: "Er ging iets mis tijdens het aanvragen!" });
    }
  };

  return (
    <Modal
      footer={
        userQuery.data !== undefined && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button.Group>
              <Popconfirm title="Weet je het zeker?" onConfirm={handleOnChangeActivation}>
                {userQuery.data.user.enabled ? (
                  <Button danger icon={<StopOutlined />}>
                    Deactiveren
                  </Button>
                ) : (
                  <Button danger icon={<PlaySquareOutlined />}>
                    Heractiveren
                  </Button>
                )}
              </Popconfirm>
              <Button onClick={() => handleOnRequestPassword()}>Wachtwoord aanvragen</Button>
            </Button.Group>
            <Button loading={isLoading} onClick={() => formInstance.submit()} type="primary">
              {userType === "GROUP_EMPLOYEES" ? "Opslaan" : "OK"}
            </Button>
          </div>
        )
      }
      onCancel={() => onClose()}
      open
      title="Gebruiker aanpassen"
    >
      {userQuery.data !== undefined ? (
        <Form
          form={formInstance}
          layout="vertical"
          onFinish={handleOnSubmit}
          initialValues={{ userGroupIds: userQuery.data.user.groups.map(group => group.id) }}
        >
          <Form.Item label="Gebruikersnaam">
            <Input disabled value={userQuery.data.user.username} />
          </Form.Item>
          <Form.Item label="E-mail">
            <Input disabled value={userQuery.data.user.email} />
          </Form.Item>
          {userType === "GROUP_EMPLOYEES" ? (
            <>
              <Form.Item name="userGroupIds" label="Gebruikersgroepen">
                <UserGroupTransfer />
              </Form.Item>
            </>
          ) : (
            <Form.Item label="Gekoppelde relatie">
              <Input disabled value={formatLinkedRelation(linkedRelationQuery.data?.linkedRelationByUser?.relation)} />
            </Form.Item>
          )}
        </Form>
      ) : (
        <Skeleton />
      )}
    </Modal>
  );
}

function formatLinkedRelation(value: { name: string; afasCode: string | null } | null | undefined) {
  if (value === undefined || value === null) {
    return null;
  }

  let retVal = value.name;
  if (null !== value.afasCode) retVal = "(" + value.afasCode + ") " + retVal;

  return retVal;
}

const UserQuery = gql`
  query ($userId: ID!) {
    user(id: $userId) {
      id
      username
      email
      locale
      enabled
      groups {
        id
        name
      }
    }
  }
`;

const LinkedRelationQuery = gql`
  query ($userId: ID!) {
    linkedRelationByUser(userId: $userId) {
      relation {
        id
        afasCode
        name
      }
    }
  }
`;

const UpdateUserUserGroupsMutation = gql`
  mutation ($userId: ID!, $userGroupIds: [ID!]) {
    updateUserUserGroups(input: { userId: $userId, userGroupIds: $userGroupIds }) {
      user {
        id
        groups {
          id
          name
        }
      }
    }
  }
`;

const ChangeUserStatusMutation = gql`
  mutation ($userId: ID!, $enabled: Boolean!) {
    changeUserStatus(input: { userId: $userId, enabled: $enabled }) {
      user {
        id
        enabled
      }
    }
  }
`;
