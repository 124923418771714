import { useQuery } from "@apollo/client";
import { Table } from "antd";
import * as React from "react";

import EmailTemplateEditor from "./EmailTemplateEditor";
import EmailTemplatesQuery from "./EmailTemplatesQuery";

export default function ListEmailTemplates() {
  const { data, loading } = useQuery(EmailTemplatesQuery);
  const [emailTempleEditorVisibleFor, setEmailTemplateEditorVisibleFor] = React.useState<string | undefined>();

  const handleOnClick = (emailTemplateId: string) => {
    setEmailTemplateEditorVisibleFor(emailTemplateId);
  };

  return (
    <>
      {undefined !== emailTempleEditorVisibleFor && (
        // eslint-disable-next-line prettier/prettier
        <EmailTemplateEditor
          emailTemplateId={emailTempleEditorVisibleFor}
          onClose={() => setEmailTemplateEditorVisibleFor(undefined)}
        />
      )}
      <Table
        bordered
        className="table-clickable-rows"
        columns={[
          { dataIndex: "name", title: "Naam" },
          { dataIndex: "locales", title: "Beschikbaar in", render: value => value.join(", ") },
        ]}
        dataSource={data?.emailTemplates ?? []}
        loading={loading}
        onRow={record => ({
          onClick: () => handleOnClick(record.id),
        })}
        rowKey={element => element.id}
      />
    </>
  );
}
