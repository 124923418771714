import { CheckOutlined, MailOutlined } from "@ant-design/icons";
import { ApolloError, useMutation } from "@apollo/client";
import { Button, Col, Row } from "antd";
import { Event } from "react-big-calendar";

import { formatAppointmentType } from "@/functions/appointment-util";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import ConfirmAppointmentMutation from "../graphql/ConfirmAppointmentMutation";
import SendInvitationMutation from "../graphql/SendInvitationMutation";

interface AgendaEventProps {
  event: Event & { __appointment: object };
  onSelectEvent: (event: Event) => void;
}

export default function AgendaEvent({ event, onSelectEvent }: AgendaEventProps) {
  const [confirmAppointmentAsync, { loading: isConfirmLoading }] = useMutation(ConfirmAppointmentMutation);
  const [sendInviteAsync, { loading: isInviteLoading }] = useMutation(SendInvitationMutation);
  const { id: appointmentId, appointmentType, relation, invitationSentOn, status } = event.__appointment;

  const handleOnInvite = async () => {
    try {
      await sendInviteAsync({
        variables: { appointmentId, showSpecificTimeInInvitation: true },
      });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const handleOnConfirm = async () => {
    try {
      await confirmAppointmentAsync({
        variables: { appointmentId },
      });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Row justify="space-between" style={{ cursor: "pointer" }}>
      <Col span={10} onClick={() => onSelectEvent(event)}>
        <span>{`${relation.afasCode} - ${relation.name}`}</span>
        <span style={{ display: "block", marginTop: 8 }}>{formatAppointmentType(appointmentType)}</span>
      </Col>
      <Col span={8} style={{ textAlign: "right" }}>
        <Button.Group>
          <Button
            disabled={invitationSentOn || status === "STATUS_CONFIRMED"}
            loading={isInviteLoading}
            icon={<MailOutlined />}
            size="small"
            onClick={handleOnInvite}
          />
          <Button
            disabled={status === "STATUS_CONFIRMED"}
            loading={isConfirmLoading}
            icon={<CheckOutlined />}
            size="small"
            onClick={handleOnConfirm}
          />
        </Button.Group>
      </Col>
    </Row>
  );
}
