import { NamePath } from "antd/es/form/interface";

export default function filterTouchedValues<T extends Record<string, unknown>>(c: (name: NamePath) => boolean, values: T): Partial<T> {
  const retVal: Partial<T> = {};

  Object.keys(values).forEach(fieldName => {
    if (!c(fieldName)) return;

    retVal[fieldName] = values[fieldName];
  });

  return retVal;
}
