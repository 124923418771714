import { LoadingOutlined } from "@ant-design/icons";
import { useEffect } from "react";

export default function PendingAppointments() {
  useEffect(() => {
    const handleOpenScheduler = () => {
      window.open(`${window.location.protocol}//${window.location.host}/scheduler`, "scheduler");
    };

    const timerId = setTimeout(handleOpenScheduler, 1000);
    return () => clearTimeout(timerId);
  }, []);

  return <LoadingOutlined />;
}
