import { graphql } from "@apollo/client/react/hoc";
import { Transfer } from "antd";
import truncate from "lodash/truncate";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import formatAddress from "@/functions/format-address";

import RelationQuery from "./RelationQuery";

// Transfer component expects key variable instead of id.
const mapLocations = locations =>
  locations.map(location => ({
    ...location,
    key: location.id,
  }));

const renderLocation = location => (
  <>
    <span>{location.name}</span>
    <small style={{ fontSize: "74%", display: "block", overflow: "hidden", textOverflow: "ellipsis" }}>
      {truncate(formatAddress(location.address), { length: 40 })}
    </small>
  </>
);

const filterOption = (inputValue, { name, address }) => {
  const lowercaseInput = inputValue.toLocaleLowerCase();

  return name.toLocaleLowerCase().indexOf(lowercaseInput) > -1 || formatAddress(address).toLocaleLowerCase().indexOf(lowercaseInput) > -1;
};

const LocationTransfer = ({ locations = [], onChange, value, ...rest }) => (
  <Transfer
    {...rest}
    dataSource={mapLocations(locations)}
    filterOption={filterOption}
    titles={[<FormattedMessage key="available" id="available" />, <FormattedMessage key="selected" id="selected" />]}
    render={renderLocation}
    showSearch
    onChange={onChange}
    targetKeys={value}
  />
);

export default graphql(RelationQuery, {
  skip: ownProps => !ownProps.relationId,
  props: ({ data: { relation } }) => ({
    locations: relation ? relation.locations.filter(l => !l.deletedAt) : [],
  }),
})(LocationTransfer);
