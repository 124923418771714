import { Radio, RadioGroupProps } from "antd";
import * as React from "react";

const SEGMENT_OPTIONS = [
  { value: 1, label: "1x per jaar" },
  { value: 2, label: "1x per 2 jaar" },
  { value: 4, label: "1x per 4 jaar" },
];

function FrequencySelect(props: Omit<RadioGroupProps, "ref">, ref: React.Ref<HTMLDivElement> | undefined) {
  return (
    <Radio.Group {...props} ref={ref} buttonStyle="solid" style={{ display: "flex" }}>
      {SEGMENT_OPTIONS.map(option => (
        <Radio.Button key={option.value} value={option.value} style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
          {option.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
}

export default React.forwardRef(FrequencySelect);
