const prevEmail = localStorage.getItem("userEmail") ?? undefined;
const prevId = localStorage.getItem("userId") ?? undefined;
const hasPreviousSession = prevId !== undefined;

const initialValues = {
  isFetching: false,
  isAuthenticated: hasPreviousSession,
  user: {
    id: prevId !== undefined ? parseInt(prevId) : undefined,
    email: prevEmail,
  },
};

export default initialValues;
