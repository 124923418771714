import { Checkbox, Divider, Input } from "antd";
import React from "react";

interface TableFilterDropdownProps {
  confirm: () => void;
  dataSource: Array<{ text: string; value: string }> | undefined;
  onClear?: () => void;
  onSearch?: (query: string) => void;
  initialValue?: string;
  selectedKeys: string[];
  setSelectedKeys: (keys: string[]) => void;
}

export default function TableFilterDropdown({
  confirm,
  dataSource,
  onClear,
  onSearch,
  initialValue,
  selectedKeys,
  setSelectedKeys,
}: TableFilterDropdownProps) {
  const [query, setQuery] = React.useState<string | undefined>(initialValue);

  const handleOnToggleSelect = (value: { value: string }) => {
    if (selectedKeys && selectedKeys[0] === value.value) {
      return setSelectedKeys([]);
    }

    setSelectedKeys([value.value]);
  };

  const handleOnSearch = () => {
    if (undefined !== query) onSearch?.(query);
    confirm();
  };

  const handleOnResetSearch = () => {
    setSelectedKeys([]);
    setQuery(undefined);

    if (undefined !== onClear) {
      onClear();
      confirm();
    }
  };

  return (
    <div className="ant-table-filter-dropdown">
      <div style={{ background: "#fff", padding: 8, borderRadius: "6px 6px 0px 0px" }}>
        <Input.Search onChange={event => setQuery(event.target.value)} placeholder="Vul iets in" value={query} />
      </div>
      {undefined !== dataSource && (
        <>
          <Divider style={{ margin: "1px 0" }} />
          <ul className="ant-dropdown-menu ant-dropdown-menu-without-submenu ant-dropdown-menu-root ant-dropdown-menu-vertical">
            {dataSource
              .filter(value => {
                if (undefined === query) return true;
                if (value.text === null) return false;

                return value.text.toLowerCase().includes(query.toLowerCase());
              })
              .map(value => (
                <li className="ant-dropdown-menu-item" key={value.value} onClick={() => handleOnToggleSelect(value)}>
                  <label className="ant-checkbox-wrapper" htmlFor={value.value}>
                    <Checkbox checked={selectedKeys.includes(value.value)} />
                  </label>
                  <span>{value.text || "-"}</span>
                </li>
              ))}
          </ul>
        </>
      )}
      <div className="ant-table-filter-dropdown-btns">
        <a className="ant-table-filter-dropdown-link confirm" onClick={undefined === dataSource ? handleOnSearch : confirm}>
          {undefined === dataSource ? "Zoeken" : "OK"}
        </a>
        <a className="ant-table-filter-dropdown-link clear" onClick={() => handleOnResetSearch()}>
          Reset
        </a>
      </div>
    </div>
  );
}
