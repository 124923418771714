import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { Spin } from "antd";
import dayjs from "dayjs";
import times from "lodash/times";
import { useEffect, useMemo, useState } from "react";
import { Calendar, dayjsLocalizer, Views } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import AppointmentModal from "@/components/AppointmentModal";
import { formatAppointmentType } from "@/functions/appointment-util";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import AgendaEvent from "./components/AgendaEvent";
import RelationAgenda from "./components/RelationAgenda";
import ScheduleToolbar from "./components/ScheduleToolbar";
import AppointmentsQuery from "./graphql/AppointmentsQuery";
import ScheduleQuery from "./graphql/ScheduleQuery";
import UpdateScheduledBreakMutation from "./graphql/UpdateScheduledBreakMutation";

const DragAndDropCalendar = withDragAndDrop(Calendar);

export default function AppointmentSchedule() {
  const [startDate, setStartDate] = useState(() => {
    const storageVal = localStorage.getItem("@SCHEDULER/DATE");
    if (null === storageVal) {
      return dayjs().startOf("week").toDate();
    } else {
      return dayjs(storageVal).toDate();
    }
  });

  const endDate = useMemo(() => dayjs(startDate).endOf("week").toDate(), [startDate]);
  const [employeeId, setEmployeeId] = useState<string | undefined>(localStorage.getItem("@SCHEDULER/EMPLOYEE") ?? undefined);
  const [selectedView, setSelectedView] = useState<(typeof Views)[keyof typeof Views]>(Views.WORK_WEEK);
  const [appointmentId, setAppointmentId] = useState<string>();
  const [relationId, setRelationId] = useState<string>();

  useEffect(() => {
    if (employeeId === undefined) {
      return;
    }

    localStorage.setItem("@SCHEDULER/DATE", startDate.toISOString());
    localStorage.setItem("@SCHEDULER/EMPLOYEE", employeeId);
  }, [employeeId, startDate]);

  const scheduleQuery = useQuery(ScheduleQuery, {
    variables: {
      employeeId,
      endTime: endDate,
      startTime: startDate,
    },
    skip: employeeId === undefined,
  });

  const searchQuery = useQuery(AppointmentsQuery, {
    variables: { relationId },
    skip: relationId === undefined,
  });

  const [updateScheduledBreakAsync] = useMutation(UpdateScheduledBreakMutation);

  const handleUpdateScheduledBreak = async (scheduledBreakRuleId: string, scheduledBreakId: string, startTime: Date, endTime: Date) => {
    if (scheduledBreakId === undefined) {
      return; //
    }

    try {
      await updateScheduledBreakAsync({
        variables: {
          scheduledBreakRuleId,
          scheduledBreakId,
          startTime,
          endTime,
        },
        optimisticResponse: {
          updateScheduledBreak: {
            scheduledBreak: {
              id: scheduledBreakId,
              startTime,
              endTime,
              isPristine: false,
              __typename: "ScheduledBreak",
            },
            __typename: "UpdateScheduledBreakPayload",
          },
        },
      });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const handleOnSelectEvent = (event: {
    __appointmentId: string | undefined;
    __isShowingRouteOnMap: boolean;
    startTime: Date;
    endTime: Date;
  }) => {
    if (event.__appointmentId !== undefined) {
      setAppointmentId(event.__appointmentId);
    } else if (event.__isShowingRouteOnMap) {
      window.open(`/scheduler?routeEmployeeId=${employeeId}&routeDate=${dayjs(event.startTime).format("YYYY-MM-DD")}`, "_blank");
    }
  };

  const handleOnReload = () => {
    scheduleQuery.refetch();
  };

  const ALL_DAY_DUMMIES = useMemo(
    () =>
      times(5).map((_value, value) => {
        return {
          allDay: true,
          // eslint-disable-next-line prettier/prettier
          startTime: dayjs(startDate)
            .add(value, "days")
            .toDate(),
          endTime: dayjs(startDate)
            .add(value + 1, "days")
            .toDate(),
          title: "Route weergeven op kaart",
          __scheduledBreakId: undefined,
          __scheduledBreakRuleId: undefined,
          __appointmentId: undefined,
          __isShowingRouteOnMap: true,
          __appointment: undefined,
        };
      }),
    [startDate]
  );

  const events = useMemo(() => {
    if (relationId !== undefined) {
      return mapAppointmentsToEvents(searchQuery.data?.appointments ?? []);
    }

    return selectedView === Views.WORK_WEEK
      ? ALL_DAY_DUMMIES.concat(mapAppointmentsToEvents(scheduleQuery.data?.appointments ?? []))
          // eslint-disable-next-line prettier/prettier
        .concat(mapScheduledBreaksToEvents(scheduleQuery.data?.scheduledBreaks ?? []))
      : mapAppointmentsToEvents(scheduleQuery.data?.appointments ?? []);
  }, [searchQuery.data, scheduleQuery.data, relationId, startDate, endDate, selectedView]);

  const calendarEvents = useMemo(() => ({ agenda: { event: AgendaEvent } }), []);

  return (
    <>
      {/* eslint-disable-next-line prettier/prettier */}
      {undefined !== appointmentId && (
        <AppointmentModal
          appointmentId={appointmentId}
          onClose={() => setAppointmentId(undefined)}
        />
      )}
      <ScheduleToolbar
        date={startDate}
        employeeId={employeeId}
        onChangeDate={value => setStartDate(value)}
        onChangeEmployeeId={value => setEmployeeId(value)}
        onChangeRelationId={value => setRelationId(value)}
        onChangeView={value => setSelectedView(value)}
        onReload={handleOnReload}
        relationId={relationId}
        reloading={scheduleQuery.loading}
        view={selectedView}
      />
      <Spin spinning={scheduleQuery.loading}>
        {relationId !== undefined ? (
          <RelationAgenda
            endAccessor={event => event.endTime}
            events={events}
            onSelectEvent={handleOnSelectEvent}
            startAccessor={event => event.startTime}
          />
        ) : (
          <DragAndDropCalendar
            components={calendarEvents}
            date={startDate}
            draggableAccessor={event => event.isDraggable}
            endAccessor={event => event.endTime}
            events={events}
            localizer={LOCALIZER}
            min={START_OF_DAY}
            max={END_OF_DAY}
            messages={{ allDay: undefined }}
            onSelectEvent={handleOnSelectEvent}
            onEventDrop={({ event, start, end }) => {
              if (event.__scheduledBreakRuleId === undefined) return;
              handleUpdateScheduledBreak(event.__scheduledBreakRuleId, event.__scheduledBreakId, start, end);
            }}
            onEventResize={({ event, start, end }) => {
              if (event.__scheduledBreakRuleId === undefined) return;
              handleUpdateScheduledBreak(event.__scheduledBreakRuleId, event.__scheduledBreakId, start, end);
            }}
            startAccessor={event => event.startTime}
            resizable
            resizableAccessor={event => event.isResizable}
            step={30}
            style={{ width: "100%", minHeight: 700 }}
            toolbar={false}
            views={[Views.WORK_WEEK, Views.AGENDA]}
            view={selectedView}
          />
        )}
      </Spin>
    </>
  );
}

const LOCALIZER = dayjsLocalizer(dayjs);

const START_OF_DAY = new Date();
START_OF_DAY.setHours(7, 0);

const END_OF_DAY = new Date();
END_OF_DAY.setHours(18, 0);

function mapScheduledBreaksToEvents(breaks: Record<string, string>[]) {
  return breaks.map(break_ => ({
    allDay: false,
    startTime: dayjs(break_.startTime).toDate(),
    endTime: dayjs(break_.endTime).toDate(),
    title: "Pauze",
    isResizable: true,
    isDraggable: true,
    __scheduledBreakId: break_.id,
    __scheduledBreakRuleId: break_.scheduledBreakRuleId,
    __appointmentId: undefined,
    __isShowingRouteOnMap: false,
    __appointment: undefined,
  }));
}

function mapAppointmentsToEvents(appointments: Record<string, string>[]) {
  return appointments.map(appointment => ({
    allDay: false,
    startTime: dayjs(appointment.startTime).toDate(),
    endTime: dayjs(appointment.endTime).toDate(),
    title: formatTitle(appointment),
    isResizable: false,
    isDraggable: false,
    __scheduledBreakId: undefined,
    __scheduledBreakRuleId: undefined,
    __appointmentId: appointment.id,
    __isShowingRouteOnMap: false,
    __appointment: appointment,
  }));
}

function formatTitle(appointment: Record<string, string>) {
  const { relation, appointmentType, status } = appointment;
  const retVal = `${relation.afasCode} - ${formatAppointmentType(appointmentType)} - ${relation.name}`;

  return status === "STATUS_SCHEDULED" ? `[C] ${retVal}` : retVal;
}
