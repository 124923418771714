import { useState } from "react";

import EditProductModal from "./EditProductModal";
import ShowProductModal from "./ShowProductModal";

interface ProductModalDispatcherProps {
  onClose: () => void;
  productId: string;
}

export default function ProductModalDispatcher({ onClose, productId }: ProductModalDispatcherProps) {
  const [mode, setMode] = useState<"show" | "edit">("show");

  return mode === "show" ? (
    <ShowProductModal onClose={onClose} onEditPress={() => setMode("edit")} productId={productId} />
  ) : (
    <EditProductModal onCancelEditPress={() => setMode("show")} onClose={onClose} productId={productId} />
  );
}
