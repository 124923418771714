import { gql, useQuery } from "@apollo/client";
import { Select, SelectProps } from "antd";
import dayjs from "dayjs";
import { useIntl } from "react-intl";

import { formatAppointmentType } from "@/functions/appointment-util";

interface ApppointmentPickerProps extends SelectProps<string> {
  relationId: string;
  locationId: string;
}

export default function ApppointmentPicker({ relationId, locationId, ...restProps }: ApppointmentPickerProps) {
  const t = useIntl();

  const { data, loading } = useQuery(AppointmentsQuery, {
    variables: {
      relationId,
      locationId,
    },
  });

  return (
    <Select loading={loading} {...restProps}>
      {(data?.appointments ?? []).map(appointment => {
        const appointmentType = formatAppointmentType(appointment.appointmentType);
        const dayjsJsFormat = appointment.startTime ? "LLL" : "LL";

        const subItems = [
          t.formatMessage({ id: `appointment.status.${appointment.status}` }),
          dayjs(appointment.startTime ?? appointment.prospectiveDate).format(dayjsJsFormat),
          appointment.employee?.username,
        ];

        return (
          <Select.Option key={appointment.id} value={appointment.id} title={appointmentType}>
            <div>{appointmentType}</div>
            <small>{subItems.filter(v => !!v).join(" - ")}</small>
          </Select.Option>
        );
      })}
    </Select>
  );
}

const AppointmentsQuery = gql`
  query ($relationId: ID!, $locationId: ID!) {
    appointments(relationId: $relationId, locationId: $locationId, order: ORDER_DESC) {
      id
      appointmentType {
        id
        name
        category {
          id
          name
        }
      }
      employee {
        id
        username
      }
      status
      prospectiveDate
      startTime
      endTime
    }
  }
`;
