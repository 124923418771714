import { SwapOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Avatar, Button, Table } from "antd";
import dayjs from "dayjs";
import { useState } from "react";

import CreateUserModal from "@/components/CreateUserModal";
import UpdateUserModal from "@/components/UpdateUserModal";
import Constants from "@/constants";

import avatarImage from "../../../static/person-avatar.png";

interface LinkedUsersCardContainerProps {
  relationId: string;
}

export default function LinkedUsersTabPaneContainer({ relationId }: LinkedUsersCardContainerProps) {
  type QueryType = { linkedUsersByRelation: { users: Record<string, string>[] } };

  const { data, loading, refetch } = useQuery<QueryType>(LinkedUsersQuery, { variables: { relationId } });
  const [activeUserId, setActiveUserId] = useState<string | undefined>();
  const [isCreatingUser, setIsCreatingUser] = useState(false);

  const handleOnLogin = async (userEmail: string) => {
    window.open(`${Constants.clientPortalUrl}?_switch_user=${encodeURIComponent(userEmail)}`, "_blank");
  };

  return (
    <>
      {undefined !== activeUserId && (
        <UpdateUserModal onClose={() => setActiveUserId(undefined)} userId={activeUserId} userType="GROUP_CUSTOMERS" />
      )}
      {isCreatingUser && (
        <CreateUserModal
          onClose={() => {
            setIsCreatingUser(false);
            refetch();
          }}
          relationId={relationId}
          userType="GROUP_CUSTOMERS"
        />
      )}
      <Table
        bordered
        className="table-clickable-rows"
        columns={[
          { key: "avatar", render: () => <Avatar src={avatarImage} />, width: 55 },
          { dataIndex: "username", title: "Gebruikersnaam" },
          { dataIndex: "email", title: "E-mail" },
          {
            dataIndex: "lastSeenAt",
            title: "Laatste keer gezien",
            render: lastSeenAt => (lastSeenAt ? dayjs(lastSeenAt).format("LLLL") : "-"),
          },
          {
            key: "actions",
            render: user => (
              <Button
                disabled={user.enabled !== true}
                onClick={event => {
                  event.stopPropagation();
                  handleOnLogin(user.email);
                }}
                style={{ marginTop: 5 }}
                type="primary"
              >
                <SwapOutlined />
                Inloggen als
              </Button>
            ),
          },
        ]}
        dataSource={data?.linkedUsersByRelation.users ?? []}
        loading={loading}
        rowKey={record => record.id}
        onRow={record => ({
          onClick: () => setActiveUserId(record.id),
        })}
        title={() => <Button onClick={() => setIsCreatingUser(true)}>Gebruiker aanmaken</Button>}
      />
    </>
  );
}

const LinkedUsersQuery = gql`
  query ($relationId: ID!) {
    linkedUsersByRelation(relationId: $relationId) {
      users {
        id
        username
        email
        lastSeenAt
        enabled
      }
    }
  }
`;
