import { FormInstance } from "antd/lib";
import pickBy from "lodash/pickBy";

export function mapFormValuesToFlags<V extends object>(values: V, prefix = "flags", isFieldsTouched?: FormInstance<V>["isFieldsTouched"]) {
  const flags_ = pickBy(values, (_value, key) => key.startsWith(prefix));
  const flags = Object.entries(flags_)
    .filter(([_key, value]) => Boolean(value))
    .map(([key, _value]) => key.substring(prefix.length + 1));

  return [flags, isFieldsTouched?.(Object.keys(flags_))] as const;
}

export function removeFlagsFromFormValues<V extends object>(values: V, prefix = "flags") {
  const retVal = { ...values };
  const availableFlags = pickBy(retVal, (_value, key) => key.startsWith(prefix));

  for (const flag of Object.keys(availableFlags)) {
    delete retVal[flag as unknown as keyof retVal];
  }

  return retVal;
}
