import { gql, useQuery } from "@apollo/client";
import { Card, Descriptions } from "antd";
import { rrulestr } from "rrule";

import { formatAppointmentType } from "@/functions/appointment-util";

interface CondensedVisitInfoProps {
  worksheetId: string;
}

export default function CondensedVisitInfo({ worksheetId }: CondensedVisitInfoProps) {
  const { data, loading } = useQuery(WorksheetQuery, { variables: { worksheetId } });

  const visit = data?.worksheet?.appointment?.visit;
  const contract = visit?.contract;
  const resolvedFlags = (visit?.overrideDefaultFlags ? visit?.flags : contract?.defaultFlags) ?? [];

  return (
    <Card loading={loading} title="Afspraak en bezoek" bodyStyle={{ padding: 0, margin: "0 -1px -1px -1px" }}>
      {undefined !== data && (
        <Descriptions bordered layout="vertical">
          <Descriptions.Item label="Frequentie">{visit?.recurrence ? rrulestr(visit?.recurrence).toText() : "-"}</Descriptions.Item>
          <Descriptions.Item label="Inclusief disposables">
            {visit !== undefined ? (resolvedFlags.includes("FLAG_INCLUDES_DISPOSABLES") ? "Ja" : "Nee") : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Onderhoud apart factureren">
            {visit !== undefined ? (resolvedFlags.includes("FLAG_INVOICE_AFTERWARDS") ? "Ja" : "Nee") : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Soort afspraak" span={2}>
            {data.worksheet.appointment ? formatAppointmentType(data.worksheet.appointment.appointmentType) : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Dealer-klant">
            {data.worksheet.relation.belongsTo
              ? `(${data.worksheet.relation.belongsTo.afasCode}) ${data.worksheet.relation.belongsTo.name}`
              : "Nee"}
          </Descriptions.Item>
          {data.worksheet.publicComment && (
            <Descriptions.Item label="Opmerking publiek" span={3}>
              {data.worksheet.publicComment}
            </Descriptions.Item>
          )}
          {data.worksheet.internalComment && (
            <Descriptions.Item label="Opmerking intern" span={3}>
              {data.worksheet.internalComment}
            </Descriptions.Item>
          )}
          {data.worksheet.relation.invoiceComment && (
            <Descriptions.Item label="Facturatie opmerking (relatie)" span={3}>
              {data.worksheet.relation.invoiceComment}
            </Descriptions.Item>
          )}
          {data.worksheet.location.invoiceComment && (
            <Descriptions.Item label="Facturatie opmerking (locatie)" span={3}>
              {data.worksheet.location.invoiceComment}
            </Descriptions.Item>
          )}
        </Descriptions>
      )}
    </Card>
  );
}

const WorksheetQuery = gql`
  query ($worksheetId: ID!) {
    worksheet(id: $worksheetId) {
      id
      relation {
        id
        invoiceComment
        belongsTo {
          id
          afasCode
          name
        }
      }
      location {
        id
        invoiceComment
      }
      appointment {
        id
        appointmentType {
          id
          name
          category {
            id
            name
          }
        }
        visit {
          id
          recurrence
          flags
          overrideDefaultFlags
          contract {
            id
            defaultFlags
          }
        }
      }
      publicComment
      internalComment
    }
  }
`;
