import { Tabs } from "antd";

import RequestInstallationAppointment from "./components/RequestInstallationAppointment";
import RequestOtherAppointment from "./components/RequestOtherAppointment";

export default function RequestAppointment() {
  return (
    <Tabs
      items={[
        { key: "installation", label: "Installatie", children: <RequestInstallationAppointment /> },
        { key: "other", label: "Overige", children: <RequestOtherAppointment /> },
      ]}
    />
  );
}
