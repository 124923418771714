import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Button, Flex, notification, Popover, Spin } from "antd";
import { cloneElement } from "react";

import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import GenerateDirectInvoiceMutation from "../graphql/GenerateDirectInvoiceMutation";

interface GenerateInvoicePopoverProps {
  children: React.ReactElement<React.ComponentPropsWithoutRef<typeof Button>>;
  disabled: boolean;
  worksheetId: string;
}

export default function GenerateInvoicePopover({ children, disabled, worksheetId }: GenerateInvoicePopoverProps) {
  const [notificationApi] = notification.useNotification();

  const { data } = useQuery<AdministrationQueryData>(AdministrationQuery);
  const [generateInvoiceAsync, { loading: isGeneratingInvoice }] = useMutation(GenerateDirectInvoiceMutation);

  const handleClick = async (administrationId: string) => {
    try {
      await generateInvoiceAsync({
        variables: {
          worksheetId,
          administrationId,
        },
      });
      notificationApi.success({ message: "Baliedirectfactuur aangemaakt" });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const popoverContent =
    data === undefined ? (
      <Spin />
    ) : (
      <Flex vertical gap="small">
        {data.administrations.map(administration => (
          <Button
            block
            key={administration.id}
            onClick={event => {
              event.stopPropagation();
              handleClick(administration.id);
            }}
          >
            {administration.service} - {administration.name}
          </Button>
        ))}
      </Flex>
    );

  if (disabled) {
    return cloneElement(children, { disabled });
  }

  return (
    <Popover content={popoverContent} title="Kies een administratie">
      {cloneElement(children, { loading: isGeneratingInvoice, onClick: event => event.stopPropagation() })}
    </Popover>
  );
}

const AdministrationQuery = gql`
  query {
    administrations {
      id
      service
      name
    }
  }
`;

interface AdministrationQueryData {
  administrations: Array<{
    id: string;
    service: string;
    name: string;
  }>;
}
