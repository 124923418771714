import { gql, useQuery } from "@apollo/client";
import { Table } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";

interface RelatedIssuesTableProps {
  onClick: (issueId: string) => void;
  worksheetId: string;
}

const columns = [
  { title: "Onderwerp", dataIndex: "subject", width: "65%" },
  { title: "Toegewezen aan", dataIndex: ["assignedTo", "username"], render: username => username ?? "-" },
  { title: "Status", dataIndex: "status", render: status => <FormattedMessage id={`issue.status.${status}`} /> },
];

export default function RelatedIssuesTable({ onClick, worksheetId }: RelatedIssuesTableProps) {
  const { data, loading } = useQuery(RelatedIssuesQuery, { variables: { worksheetId } });

  return (
    <Table
      bordered
      columns={columns}
      className="table-clickable-rows"
      dataSource={data?.issues ?? []}
      loading={loading}
      onRow={record => ({
        onClick: () => onClick(record.id),
      })}
      title={() => "Meldingen toegevoegd aan onderhoudsbeurt"}
      pagination={false}
    />
  );
}

const RelatedIssuesQuery = gql`
  query ($worksheetId: ID!) {
    issues(relatedWorksheetId: $worksheetId) {
      id
      subject
      assignedTo {
        id
        username
      }
      status
    }
  }
`;
