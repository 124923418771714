import { EnvironmentOutlined } from "@ant-design/icons";
import { Button, Form, Popover, Select, Slider } from "antd";
import * as React from "react";

import MarkersContext, { MarkerType } from "../MarkersContext";

interface CustomMarkerPopoverProps {
  appointment: any;
}

export default function CustomMarkerPopover({ appointment }: CustomMarkerPopoverProps) {
  const { markers, setMarkerTo } = React.useContext(MarkersContext);

  const [opacity, setOpacity] = React.useState(markers[appointment.relation.id]?.opacity ?? 100);
  const [marker, setMarker] = React.useState<MarkerType | undefined>(markers[appointment.relation.id]?.marker ?? undefined);

  const handleOnSave = () => {
    if (undefined === marker) return;
    setMarkerTo(appointment.relation.id, { marker, opacity: opacity, relationName: appointment.relation.name });
  };

  const handleOnReset = () => {
    setMarkerTo(appointment.relation.id, undefined);

    setOpacity(100);
    setMarker(undefined);
  };

  return (
    <Popover
      content={
        <Form layout="vertical" style={{ width: 250 }}>
          <Form.Item label="Soort icoon">
            <Select<MarkerType> onChange={value => setMarker(value)} value={marker}>
              <Select.Option value="star">Ster</Select.Option>
              <Select.Option value="square">Vierkant</Select.Option>
              <Select.Option value="circle">Cirkel</Select.Option>
              <Select.Option value="hexagon">Hexagon</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Dekking">
            <EnvironmentOutlined style={{ fontSize: 16, opacity: opacity / 100 }} />
            <Slider onChange={value => setOpacity(value as number)} value={opacity} />
          </Form.Item>
          <Button type="primary" onClick={handleOnSave}>
            {undefined === markers[appointment.relation.id] ? "Toevoegen" : "Aanpassen"}
          </Button>
          {undefined !== markers[appointment.relation.id] && (
            <Button danger onClick={handleOnReset} style={{ marginLeft: 8 }}>
              Reset
            </Button>
          )}
        </Form>
      }
    >
      <EnvironmentOutlined /> Icoon wijzigen
    </Popover>
  );
}
