import { CalendarOutlined, ToolOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Button, List } from "antd";
import dayjs from "dayjs";
import * as React from "react";
import { useIntl } from "react-intl";

import { formatAppointmentType } from "@/functions/appointment-util";

interface RelatedObjectProps {
  avatar: React.ReactNode;
  onClick: () => void;
  description: string;
  title: string;
}

interface RelatedObjectsListProps {
  onRelatedAppointmentClick: (appointmentId: string) => void;
  onRelatedWorksheetClick: (worksheetId: string) => void;
  issueId: string;
}

export default function RelatedObjectsList({ onRelatedAppointmentClick, onRelatedWorksheetClick, issueId }: RelatedObjectsListProps) {
  const t = useIntl();
  const dataSource: RelatedObjectProps[] = [];

  const { data: appointmentData } = useQuery(RelatedAppointmentQuery, { variables: { issueId } });
  const appointment = appointmentData?.issue.relatedAppointment ?? null;

  const { data: worksheetData } = useQuery(RelatedWorksheetQuery, {
    variables: { appointmentId: appointment?.id ?? undefined },
    skip: null === appointment,
  });

  if (null !== appointment && undefined !== appointment) {
    const dayjsJsFormat = appointment.startTime ? "LLL" : "LL";

    const subItems = [
      t.formatMessage({ id: `appointment.status.${appointment.status}` }),
      dayjs(appointment.startTime ?? appointment.prospectiveDate).format(dayjsJsFormat),
      appointment.employee?.username,
    ];

    dataSource.push({
      avatar: <CalendarOutlined />,
      description: subItems.join(" - "),
      onClick: () => onRelatedAppointmentClick(appointment.id),
      title: formatAppointmentType(appointment.appointmentType),
    });
  }

  if (worksheetData?.worksheet) {
    const worksheet = worksheetData?.worksheet;

    const subItems = [dayjs(worksheet.completedOn).format("LLL"), worksheet.employee?.username, `${worksheet.numberOfItems} regels`];

    dataSource.push({
      avatar: <ToolOutlined />,
      description: subItems.join(" - "),
      onClick: () => onRelatedWorksheetClick(worksheet.id),
      title: formatAppointmentType(worksheet.appointment.appointmentType),
    });
  }

  return (
    <List
      bordered
      itemLayout="horizontal"
      size="small"
      dataSource={dataSource}
      header="Gerelateerde objecten"
      renderItem={element => {
        return (
          <List.Item
            actions={[
              <Button key="open-object" onClick={element.onClick} type="link">
                Bekijken
              </Button>,
            ]}
          >
            <List.Item.Meta avatar={element.avatar} description={element.description} title={element.title} />
          </List.Item>
        );
      }}
    />
  );
}

const RelatedWorksheetQuery = gql`
  query ($appointmentId: ID!) {
    worksheet(appointmentId: $appointmentId) {
      id
      appointment {
        id
        appointmentType {
          id
          name
          category {
            id
            name
          }
        }
      }
      completedOn
      numberOfItems
      numberOfRelatedIssues
      employee {
        id
        username
      }
    }
  }
`;

const RelatedAppointmentQuery = gql`
  query ($issueId: ID!) {
    issue(id: $issueId) {
      id
      relatedAppointment {
        id
        appointmentType {
          id
          name
          category {
            id
            name
          }
        }
        employee {
          id
          username
        }
        status
        prospectiveDate
        startTime
        endTime
      }
    }
  }
`;
