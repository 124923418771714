import md5 from "md5";
import * as React from "react";

interface GravatarProps {
  email: string;
  size?: number;
  rating?: string;
  _default?: string;
}

export default function Gravatar({
  email,
  size = 50,
  rating = "g",
  _default = "retro",
  ...restProps
}: GravatarProps & React.ImgHTMLAttributes<HTMLImageElement>) {
  const formattedEmail = email.trim().toLowerCase();
  const baseUrl = "//www.gravatar.com/avatar/" + md5(formattedEmail, { encoding: "binary" }) + `?s=${size}&r=${rating}&d=${_default}`;

  return <img {...restProps} alt={`Gravatar for ${formattedEmail}`} src={baseUrl} />;
}
