import { gql } from "@apollo/client";

export default gql`
  query IssuesQuery($status: [IssueStatus!]) {
    issues(status: $status) {
      id
      relation {
        id
        name
        afasCode
      }
      location {
        id
        name
      }
      createdOn
      createdBy {
        id
        username
      }
      assignedTo {
        id
        username
      }
      subject
      status
    }
  }
`;
