import * as React from "react";
import { Marker as LeafletMarker } from "react-leaflet";

import createIconFactory from "../markers";
import MarkersContext from "../MarkersContext";

// Adds a small offset to coordinates to prevent two appointments at the same location overlapping each other.
function randomizeCoordinates({ latitude, longitude }: { latitude: number; longitude: number }): [number, number] {
  return [latitude + (Math.random() > 0.5 ? 0.00005 : -0.00005), longitude + (Math.random() > 0.5 ? 0.00005 : -0.00005)];
}

interface MarkerProps {
  appointment: any;
  onClickAppointment: (id: string) => void;
  selected: boolean;
}

function Marker({ appointment, onClickAppointment, selected }: MarkerProps) {
  const { markers } = React.useContext(MarkersContext);

  const position = React.useMemo(() => randomizeCoordinates(appointment.location.address.coordinates), [appointment.location.address]);
  const icon = React.useMemo(() => {
    const marker = markers[appointment.relation.id];
    return createIconFactory(marker?.marker ?? "default", appointment, marker?.opacity, selected);
  }, [appointment, markers, selected]);

  const eventHandlers = React.useMemo(
    () => ({
      click: () => onClickAppointment(appointment.id),
    }),
    [appointment, onClickAppointment]
  );

  return <LeafletMarker eventHandlers={eventHandlers} icon={icon} position={position} />;
}

export default React.memo(Marker, (prevProps, nextProps) => {
  return (
    prevProps.appointment.id === nextProps.appointment.id &&
    prevProps.appointment.status === nextProps.appointment.status &&
    prevProps.appointment.startTime === nextProps.appointment.startTime &&
    prevProps.appointment.prospectiveDate === nextProps.appointment.prospectiveDate &&
    prevProps.selected === nextProps.selected
  );
});
