import { Select, SelectProps } from "antd";
import { useEffect, useState } from "react";

import formatAddress, { Address } from "@/functions/format-address";
import useDebounce from "@/hooks/use-debounce";

interface AddressCompleterProps extends Omit<SelectProps<string>, "onChange" | "onSelect" | "onDeselect"> {
  onChange?: (value: Address) => void;
  onSelect?: (value: google.maps.places.PlaceResult) => void;
}

const autocompleteService = new google.maps.places.AutocompleteService();
const placesService = new google.maps.places.PlacesService(document.createElement("div"));
const sessionToken = new google.maps.places.AutocompleteSessionToken();

export default function AddressCompleter({ onChange, onSelect, value, ...restProps }: AddressCompleterProps) {
  const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [query, setQuery] = useState(value ? formatAddress(value as unknown as Address) : "");
  const debouncedQuery = useDebounce(query);

  useEffect(() => {
    if (!debouncedQuery || debouncedQuery.length < 3) return;

    async function main() {
      const response = await autocompleteService.getPlacePredictions({ sessionToken, input: debouncedQuery });
      setSuggestions(response.predictions);
    }

    main();
  }, [debouncedQuery]);

  useEffect(() => {
    if (value !== null && value !== undefined) {
      setQuery(formatAddress(value as unknown as Address));
    }
  }, [value]);

  const handleOnSelect = (value: string) => {
    const prediction = suggestions.find(s => s.place_id === value);
    if (undefined === prediction) return;

    placesService.getDetails({ sessionToken, placeId: prediction.place_id }, response => {
      if (null === response) return;

      if (undefined !== onSelect) onSelect(response);
      else if (undefined !== onChange) onChange(parseMapsAddrComponents(response.address_components ?? []));
    });

    setQuery(prediction.description);
  };

  return (
    <Select
      {...restProps}
      style={{ width: "100%" }}
      defaultActiveFirstOption
      onSearch={value => {
        setQuery(value as string);
      }}
      onSelect={value => {
        handleOnSelect(value as string);
      }}
      options={suggestions.map(suggestion => ({
        value: suggestion.place_id,
        label: suggestion.description,
      }))}
      filterOption={false}
      placeholder="Vul naam of klantcode in"
      suffixIcon={null}
      showSearch
      value={query}
    />
  );
}

function parseMapsAddrComponents(components: google.maps.GeocoderAddressComponent[]): Address {
  const extractedProps = {
    street: null as string | null,
    postalCode: null as string | null,
    city: null as string | null,
    country: null as string | null,
  };

  components.forEach(value => {
    switch (value.types[0]) {
      case "street_number":
        if (extractedProps.street) extractedProps.street = `${extractedProps.street} ${value.long_name}`;
        else extractedProps.street = value.long_name;

        break;

      case "route":
        if (extractedProps.street) extractedProps.street = `${value.long_name} ${extractedProps.street}`;
        else extractedProps.street = value.long_name;

        break;

      case "locality":
        extractedProps.city = value.long_name;
        break;

      case "country":
        extractedProps.country = value.short_name;
        break;

      case "postal_code":
        extractedProps.postalCode = value.long_name;
        break;

      default:
      // Do nothing..
    }
  });

  return extractedProps;
}
