import { CheckOutlined, DeleteOutlined, QuestionOutlined, WarningOutlined } from "@ant-design/icons";
import { Radio, RadioGroupProps } from "antd";
import { forwardRef } from "react";

const conditions = [
  { value: "CONDITION_GOOD", label: "Goed", icon: <CheckOutlined /> },
  { value: "CONDITION_ACTION_REQUIRED", label: "Actie vereist", icon: <WarningOutlined /> },
  { value: "CONDITION_DESTROYED", label: "Verwijderd", icon: <DeleteOutlined /> },
  { value: "CONDITION_UNKNOWN", label: "Onbekend", icon: <QuestionOutlined /> },
];

function ProductConditionPicker(props: Omit<RadioGroupProps, "ref">, ref: React.Ref<HTMLDivElement> | undefined) {
  return (
    <Radio.Group {...props} ref={ref} buttonStyle="solid" style={{ display: "flex" }}>
      {conditions.map(condition => (
        <Radio.Button value={condition.value} key={condition.value} style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
          {condition.icon}
          <span style={{ marginLeft: 4 }}>{condition.label}</span>
        </Radio.Button>
      ))}
    </Radio.Group>
  );
}

export default forwardRef(ProductConditionPicker);
