import { gql, useQuery } from "@apollo/client";
import { Select, SelectProps } from "antd";
import { useEffect, useMemo } from "react";

export default function AdministrationPicker(props: Omit<SelectProps, "onChange"> & { onChange?: (administrationId: string) => void }) {
  const { data, loading } = useQuery<AdministrationQueryData>(AdministrationQuery);

  const dataSource = useMemo(
    () =>
      (data?.administrations ?? []).map(element => ({
        value: element.id,
        label: ` ${element.service} - ${element.name}`,
      })),
    [data]
  );

  useEffect(() => {
    const administrations = data?.administrations ?? [];

    if ((props.value !== null && props.value !== undefined) || administrations.length < 1) {
      return;
    }

    const defaultAdministrationId = administrations.find(a => a.isDefault === true)?.id;

    if (defaultAdministrationId === undefined && dataSource.length === 1) {
      const administrationId = dataSource[0].value;
      props.onChange?.(administrationId);
    } else if (defaultAdministrationId !== undefined) {
      props.onChange?.(defaultAdministrationId);
    }
  }, [dataSource]);

  return <Select {...props} options={dataSource} loading={loading} />;
}

const AdministrationQuery = gql`
  query {
    administrations {
      id
      service
      name
      isDefault
    }
  }
`;

interface AdministrationQueryData {
  administrations: Array<{
    id: string;
    service: string;
    name: string;
    isDefault: boolean;
  }>;
}
