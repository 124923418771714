import * as React from "react";
import { useParams } from "react-router-dom";

import WorksheetTableItem from "./components/WorksheetTableItem";

export default function ViewWorksheets() {
  const worksheetId = useParams<Record<string, string>>().worksheetId;
  if (!worksheetId) throw new Error("Cannot access page without worksheetId");

  return <WorksheetTableItem mode="extended" worksheetId={worksheetId} />;
}
