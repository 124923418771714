import { FileExcelOutlined } from "@ant-design/icons";
import { Button, DatePicker, Divider, Space, Switch } from "antd";
import dayjs from "dayjs";
import * as React from "react";

import Downloader from "@/components/Downloader";
import downloadAttachment from "@/functions/download-attachment";

import WorksheetTable from "./components/WorksheetTable";

export default function ViewWorksheets() {
  const [downloaderOpen, setDownloaderOpen] = React.useState(false);
  const [worksheetDocs, setWorksheetDocs] = React.useState([]);

  const [filterOnlyWithActionableItems, setFilterOnlyWithActionableItems] = React.useState(true);
  const [filterNotProcessed, setFilterNotProcessed] = React.useState(true);
  const [period, setPeriod] = React.useState();

  const handleOnCreateExcelExport = async () => {
    if (!period) return;
    const [completedAfter, completedBefore] = period;

    const startDate = dayjs(completedAfter).format("YYYYMMDD");
    const endDate = dayjs(completedBefore).format("YYYYMMDD");

    await downloadAttachment(
      `reports/worksheets-period?start-date=${startDate}&end-date=${endDate}&only-unprocessed=${filterNotProcessed}&only-actionable-items=${filterOnlyWithActionableItems}`
    );
  };

  const handleOnFilesClick = async (worksheetFilesOrWorksheetId, openType = "downloader") => {
    if (openType === "downloader") {
      setDownloaderOpen(c => !c);
      setWorksheetDocs(worksheetFilesOrWorksheetId);

      return;
    }

    await downloadAttachment("reports/worksheets/" + worksheetFilesOrWorksheetId);
  };

  return (
    <>
      {/* eslint-disable-next-line prettier/prettier */}
      {downloaderOpen && (
        <Downloader
          files={worksheetDocs}
          onClose={() => setDownloaderOpen(false)}
      />)}
      <WorksheetTable
        forPeriod={period}
        onFilesClick={handleOnFilesClick}
        onlyWithActionableItems={filterOnlyWithActionableItems}
        header={
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Space direction="horizontal">
              <DatePicker.RangePicker
                allowClear={filterNotProcessed && filterOnlyWithActionableItems}
                onChange={dates => {
                  setPeriod(dates);
                }}
                value={period}
              />
              <Divider type="vertical" />
              <Switch
                onChange={() => {
                  const nextValue = !filterOnlyWithActionableItems;

                  setFilterOnlyWithActionableItems(nextValue);
                  if (!nextValue && !period) setPeriod([dayjs().subtract(1, "week"), dayjs()]);
                }}
                checked={filterOnlyWithActionableItems}
              />
              <span style={{ marginLeft: 10 }}>Alleen met actiebehoevende items</span>
              <Divider type="vertical" />
              <Switch
                onChange={() => {
                  const nextValue = !filterNotProcessed;

                  setFilterNotProcessed(nextValue);
                  if (!nextValue && !period) setPeriod([dayjs().subtract(1, "week"), dayjs()]);
                }}
                checked={filterNotProcessed}
              />
              <span style={{ marginLeft: 10 }}>Alleen onverwerkt</span>
            </Space>
            <Button disabled={!period} type="primary" onClick={handleOnCreateExcelExport} icon={<FileExcelOutlined />}>
              {period ? "Exporteren" : "Export alleen beschikbaar als datumperiode is geselecteerd"}
            </Button>
          </div>
        }
        isProcessed={!filterNotProcessed}
      />
    </>
  );
}
