import { Card, DatePicker } from "antd";
import dayjs from "dayjs";
import * as React from "react";

import downloadAttachment from "@/functions/download-attachment";

import DownloadButton from "./DownloadButton";

export default function InventoryRequirementsSpreadsheet() {
  const [{ start, end }, setPeriod] = React.useState<Record<string, dayjs.Dayjs | null | undefined>>({
    start: dayjs().startOf("month"),
    end: null,
  });

  const handleOnDownload = () => {
    if (!start || !end) return Promise.resolve();

    return downloadAttachment(
      `reports/inventory-requirements?start-date=${start.format("YYYY-MM-DD")}&end-date=${end.format("YYYY-MM-DD")}`
    );
  };

  return (
    <Card title="Voorraadbehoefte onderhoud">
      <div style={{ marginBottom: 8 }}>
        <span>Periode: </span>
        <DatePicker.RangePicker
          onChange={period => {
            setPeriod({
              start: period?.[0] ?? start,
              end: period?.[1] ?? end,
            });
          }}
        />
      </div>

      <DownloadButton type="primary" onClick={handleOnDownload} disabled={!start || !end}>
        Downloaden
      </DownloadButton>
    </Card>
  );
}
