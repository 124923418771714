import { gql, useQuery } from "@apollo/client";

interface BrandSuggestionsVariables {
  relationId: string;
  locationId?: string;
  field: string;
  productTypeId?: string;
  skipDestroyed?: boolean;
}

export default function useSuggestions(variables: BrandSuggestionsVariables) {
  return useQuery<{ servicedProductsSuggestions: string[] }>(ServicedProductsSuggestionsQuery, {
    variables,
    skip: !variables.productTypeId,
    fetchPolicy: "cache-and-network",
  });
}

const ServicedProductsSuggestionsQuery = gql`
  query ($relationId: ID!, $locationId: ID, $productTypeId: ID!, $field: String!, $skipDestroyed: Boolean) {
    servicedProductsSuggestions(
      relationId: $relationId
      locationId: $locationId
      field: $field
      productTypeId: $productTypeId
      skipDestroyed: $skipDestroyed
    )
  }
`;
