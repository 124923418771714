import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";

type ProductType = { id: string; code: string; description: string };
type DataSource = Array<{ productType: ProductType; amount: number }>;

interface ConsumableGoodsTableProps {
  caption: React.ReactNode;
  data: DataSource;
  onChange: React.Dispatch<React.SetStateAction<DataSource>>;
}

export default function ConsumableGoodsTable({ caption, data, onChange }: ConsumableGoodsTableProps) {
  return (
    <Table
      bordered
      title={() => caption}
      columns={[
        { title: "Product", dataIndex: "productType", render: productType => `(${productType.code}) ${productType.description}` },
        { title: "Aantal", dataIndex: "amount" },
        {
          title: null,
          render: record => {
            const handleOnChange = (change: number) => {
              onChange(current => {
                return current.map(value => {
                  if (value.productType.id !== record.productType.id) {
                    return value;
                  }

                  return { ...value, amount: Math.max(value.amount + change, 0) };
                });
              });
            };

            return (
              <Button.Group>
                <Button onClick={() => handleOnChange(1)} icon={<PlusOutlined />} />
                <Button onClick={() => handleOnChange(-1)} icon={<MinusOutlined />} />
              </Button.Group>
            );
          },
        },
      ]}
      dataSource={data}
      rowKey={record => record.productType.id}
      pagination={false}
    />
  );
}
