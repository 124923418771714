import { Radio, RadioGroupProps } from "antd";
import * as React from "react";

function RescheduleFromPicker(props: Omit<RadioGroupProps, "ref">, ref: React.Ref<HTMLDivElement> | undefined) {
  return (
    <Radio.Group {...props} ref={ref} buttonStyle="solid" style={{ display: "flex" }}>
      <Radio.Button value="CURRENT_CALENDAR_YEAR" style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
        Huidig kalenderjaar
      </Radio.Button>
      <Radio.Button value="NEXT_CALENDAR_YEAR" style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
        Volgend kalenderjaar
      </Radio.Button>
    </Radio.Group>
  );
}

export default React.forwardRef(RescheduleFromPicker);
