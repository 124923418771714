import { DeleteOutlined, EditOutlined, RetweetOutlined, WarningOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Button, Card, Col, Popconfirm, Row } from "antd";
import { FormattedMessage } from "react-intl";

import OptionalInformation from "@/components/OptionalInformation";
import PhoneNumber from "@/components/PhoneNumber";
import formatAddress from "@/functions/format-address";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import RelationQuery from "../../graphql/RelationQuery";

interface LocationCardProps {
  relationId: string;
  locationId: string;
  onEditPress: () => void;
  onMovePress: () => void;
}

export default function LocationCard({ relationId, locationId, onEditPress, onMovePress }: LocationCardProps) {
  const { data } = useQuery(RelationQuery, { variables: { id: relationId } });
  const location = data?.relation.locations.find(location => location.id === locationId);
  const [discardLocationAsync] = useMutation(DiscardLocationMutation);

  const handleOnDiscardPress = async () => {
    try {
      await discardLocationAsync({ variables: { relationId, locationId } });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Card
      actions={
        location !== undefined
          ? [
              <span key="location-card-on-move" onClick={onMovePress} role="button">
                <RetweetOutlined />
                <FormattedMessage id="moveLocation" />
              </span>,
              <span key="location-card-on-edit" onClick={onEditPress} role="button">
                <EditOutlined />
                <FormattedMessage id="editLocation" />
              </span>,
              <Popconfirm
                key="location-card-on-delete"
                disabled={location.deletedAt !== null}
                onConfirm={handleOnDiscardPress}
                title={<FormattedMessage id="genericConfirmation" />}
              >
                <span style={{ opacity: (location.deletedAt === null && 1) || 0.2 }}>
                  <DeleteOutlined />
                  <FormattedMessage id="discardLocation" />
                </span>
              </Popconfirm>,
            ]
          : []
      }
      extra={
        location !== undefined &&
        null !== location.deletedAt && (
          <Button disabled style={{ backgroundColor: "red", borderColor: "red" }}>
            <WarningOutlined style={{ color: "#fff", marginRight: 2 }} />
            <FormattedMessage id="location.locationIsDeletedWarning">
              {locationIsDeletedWarning => <span style={{ color: "white" }}>{locationIsDeletedWarning}</span>}
            </FormattedMessage>
          </Button>
        )
      }
      loading={undefined === location}
      title={<FormattedMessage id="location" />}
    >
      {location !== undefined ? (
        <>
          <Row justify="space-between">
            <Col md={11}>
              <OptionalInformation id="location.name" value={location.name} />
              <OptionalInformation id="location.afasCode" value={location.afasCode} />
              <OptionalInformation id="location.contactPerson" value={location.contactPerson} />
              <OptionalInformation id="location.locale" value={<FormattedMessage id={location.locale} />} />
              <OptionalInformation id="location.address" value={formatAddress(location.address)} />
            </Col>
            <Col md={11}>
              <OptionalInformation id="location.primaryEmail" value={location.primaryEmail} />
              <OptionalInformation id="location.secondaryEmail" value={location.secondaryEmail} />
              <OptionalInformation id="location.phoneNumber" component={PhoneNumber} value={location.phoneNumber} />
              <OptionalInformation id="location.mobilePhoneNumber" component={PhoneNumber} value={location.mobilePhoneNumber} />
            </Col>
          </Row>
          {(location.planningComment !== null || location.onSiteComment !== null) && (
            <Row justify="space-between">
              {location.planningComment !== null && (
                <Col md={11}>
                  <OptionalInformation id="location.planningComment" value={location.planningComment} />
                </Col>
              )}
              {location.onSiteComment !== null && (
                <Col md={11}>
                  <OptionalInformation id="location.onSiteComment" value={location.onSiteComment} />
                </Col>
              )}
            </Row>
          )}
          {location.invoiceComment && (
            <Row justify="space-between" gutter={1}>
              <Col span={12}>
                <OptionalInformation label="Facturatie notities" value={location.invoiceComment} />
              </Col>
            </Row>
          )}
        </>
      ) : null}
    </Card>
  );
}

const DiscardLocationMutation = gql`
  mutation ($relationId: ID!, $locationId: ID!) {
    discardLocation(input: { relationId: $relationId, locationId: $locationId }) {
      relation {
        id
        locations {
          id
          deletedAt
        }
      }
    }
  }
`;
