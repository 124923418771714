import { Card, DatePicker } from "antd";
import dayjs from "dayjs";
import * as React from "react";

import downloadAttachment from "@/functions/download-attachment";

import DownloadButton from "./DownloadButton";

export default function InventoryRequirementsSpreadsheetV2() {
  const [endDate, setEndDate] = React.useState<dayjs.Dayjs | null | undefined>();
  const [currentDate, setCurrentDate] = React.useState<dayjs.Dayjs | null | undefined>(dayjs());

  const handleOnDownload = () => {
    if (!endDate || !currentDate) return Promise.resolve();

    return downloadAttachment(
      `reports/inventory-requirements-v2?current-date=${currentDate.format("YYYY-MM-DD")}&end-date=${endDate.format("YYYY-MM-DD")}`
    );
  };

  return (
    <Card title="Voorraadbehoefte onderhoud (v2, experimental)">
      <div style={{ marginBottom: 8 }}>
        <span>Einddatum: </span>
        <DatePicker onChange={value => setEndDate(value)} value={endDate as any} />
      </div>
      <div style={{ marginBottom: 8 }}>
        <span>IJkdatum: </span>
        <DatePicker onChange={value => setCurrentDate(value)} value={currentDate as any} />
      </div>

      <DownloadButton type="primary" onClick={handleOnDownload} disabled={!currentDate || !endDate}>
        Downloaden
      </DownloadButton>
    </Card>
  );
}
