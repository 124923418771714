import { gql, useQuery } from "@apollo/client";
import { Cascader, CascaderProps } from "antd";
import { DefaultOptionType } from "antd/lib/cascader";
import { forwardRef, useMemo } from "react";

interface ProductTypePickerProps {
  onChange?: (productTypeId: string) => void;
  value?: string;
}

// eslint-disable-next-line prettier/prettier
function ProductTypePicker(
  { onChange, value, ...restProps }: ProductTypePickerProps & Omit<CascaderProps<string>, "onChange" | "value">,
  ref
) {
  const { data, loading } = useQuery(ProductCategoriesQuery);

  const handleOnChange = async (path: string[]) => {
    if (path === undefined) {
      return;
    }

    const productTypeId = path[path.length - 1];
    onChange?.(productTypeId);
  };

  const handleOnSearchFilter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const { options, productTypeToPath } = useMemo(() => {
    return mapElementsToOptionsTrampoline(data?.productCategories ?? []);
  }, [data]);

  return (
    <Cascader
      {...restProps}
      ref={ref}
      loading={loading}
      onChange={handleOnChange}
      options={options}
      showSearch={useMemo(() => ({ filter: handleOnSearchFilter }), [])}
      value={undefined !== value && value in productTypeToPath ? productTypeToPath[value] : undefined}
    />
  );
}

function mapElementsToOptionsTrampoline(values: Record<string, any>[]) {
  const productTypeToPath: Record<string, string[]> = {};

  const mapElementsToOptions = (options: Record<string, any>[], parent: string[]): DefaultOptionType[] => {
    return [...options]
      .filter(value => undefined === value.isHidden || false === value.isHidden)
      .sort((a, b) => {
        if (typeof a.code !== typeof b.code) {
          if (typeof a.code === "string") return 1;
          if (typeof b.code === "string") return -1;

          return 0;
        }

        if (typeof a.code === "string" && typeof b.code === "string") {
          return Number(a.code) - Number(b.code);
        }

        if (undefined !== a.description && undefined !== b.description) {
          return (a.description as string).localeCompare(b.description as string);
        }

        return (a.name as string).localeCompare(b.name as string);
      })
      .map(value => {
        const categories = undefined !== value.children ? mapElementsToOptions(value.children, [...parent, value.id]) : [];
        const productTypes = undefined !== value.productTypes ? mapElementsToOptions(value.productTypes, [...parent, value.id]) : [];
        let label = undefined !== value.description ? value.description : value.name;

        if (typeof value.description === "string") {
          if (typeof value.code === "string") label = `(${value.code}) ${label}`;
          productTypeToPath[value.id] = [...parent, value.id];
        }

        return {
          label,
          value: value.id,
          children: [...categories, ...productTypes],
        };
      });
  };

  return { options: mapElementsToOptions(values, []), productTypeToPath };
}

const ProductCategoriesQuery = gql`
  query {
    productCategories {
      id
      name
      isHidden
      children {
        id
        name
        isHidden
        productTypes {
          ...ProductTypeFields
        }
      }
      productTypes {
        ...ProductTypeFields
      }
    }
  }

  fragment ProductTypeFields on ProductType {
    id
    code
    description
  }
`;

export default forwardRef(ProductTypePicker);
