import { gql } from "@apollo/client";

export default gql`
  mutation ($id: ID!, $showSpecificTimeInInvitation: Boolean!) {
    sendNotification(input: { id: $id, showSpecificTimeInInvitation: $showSpecificTimeInInvitation }) {
      appointment {
        id
        invitationSentOn
      }
    }
  }
`;
