import { gql } from "@apollo/client";

export default gql`
  query ($productTypeId: ID!) {
    productType(id: $productTypeId) {
      id
      rootCategory {
        id
        name
      }
      code
      description
      serialCodeExpr
      serialCodeHint
      productionBatchExpr
      productionBatchHint
      isExpirationDateRequired
      isManufacturingDateRequired
      hasConsumables
      hasBrand
      majorMaintenanceInterval
      expectedLifespan
    }
  }
`;
