import { gql, useQuery } from "@apollo/client";
import { Table } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import RelationPicker from "@/components/RelationPicker";

const COLUMNS = [
  { title: "AFAS", dataIndex: "afasCode" },
  { title: "Relatie", dataIndex: "name" },
  { title: "Vestigingslocatie", dataIndex: "place" },
];

export default function DealerRelations() {
  const navigation = useNavigate();
  const [belongsToId, setBelongsToId] = useState<string>();

  const { data, loading } = useQuery(DealerRelationsQuery, {
    variables: { belongsToId },
    skip: belongsToId === undefined,
  });

  const handleClick = (relationId: string) => {
    navigation(`/relations/${relationId}`);
  };

  return (
    <Table
      bordered
      columns={COLUMNS}
      className="table-clickable-rows"
      dataSource={data?.relations ?? []}
      onRow={record => ({
        onClick: () => handleClick(record.id),
      })}
      loading={loading}
      title={() => (
        <div style={{ display: "flex", alignItems: "center", columnGap: 12 }}>
          <span>Zoek een dealer</span>
          <RelationPicker onChange={value => setBelongsToId(value)} style={{ width: 300 }} value={belongsToId} />
        </div>
      )}
    />
  );
}

const DealerRelationsQuery = gql`
  query ($belongsToId: ID!) {
    relations(belongsToId: $belongsToId) {
      id
      afasCode
      name
      place
    }
  }
`;
