import { gql } from "@apollo/client";

export default gql`
  mutation ($appointmentId: ID!) {
    confirmAppointment(input: { id: $appointmentId }) {
      appointment {
        id
        status
      }
    }
  }
`;
