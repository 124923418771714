import { gql } from "@apollo/client";

export default gql`
  query ContractVisitsQuery($contractId: ID!) {
    contract(id: $contractId) {
      id
      relation {
        id
      }
      startingOn
      endingOn
      visits {
        id
        appointmentType {
          id
          name
          category {
            id
            name
          }
        }
        startingOn
        endingOn
        location {
          id
          name
          address {
            street
            postalCode
            city
            country
          }
        }
        overrideDefaultFlags
        flags
        recurrence
        comment
      }
    }
  }
`;
