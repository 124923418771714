import { Map as LeafletMap } from "leaflet/dist/leaflet";
import * as React from "react";
import { MapContainer, TileLayer } from "react-leaflet";

import Marker from "./Marker";

interface MapProps {
  appointments: any[];
  onClickAppointment: (id: string) => void;
  selectedAppointmentIds: string[];
}

function Map({ appointments, onClickAppointment, selectedAppointmentIds }: MapProps, ref: React.Ref<LeafletMap>) {
  return (
    <MapContainer ref={ref} center={[52.132633, 5.291266]} style={{ height: "100%", width: "100%" }} zoom={8} zoomControl={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {appointments.map(appointment => (
        <Marker
          key={appointment.id}
          appointment={appointment}
          onClickAppointment={onClickAppointment}
          selected={selectedAppointmentIds.includes(appointment.id)}
        />
      ))}
    </MapContainer>
  );
}

export default React.forwardRef(Map);
