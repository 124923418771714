import { ArrowDownOutlined, ArrowLeftOutlined, ArrowRightOutlined, ArrowUpOutlined, PlusOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Button, Checkbox, DatePicker, Divider, Form, Input, InputNumber, Radio, Space } from "antd";
import { FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

import LocationPicker from "@/components/LocationPicker";
import ProductConditionPicker from "@/components/ProductConditionPicker";
import ProductTypePicker from "@/components/ProductTypePicker";
import formatDuration from "@/functions/format-duration";

import BrandInput from "./BrandInput";
import OptionalDescriptionInput from "./OptionalDescriptionInput";
import ProductTypeQuery from "./ProductTypeQuery";
import RegisterProductPartCard from "./RegisterProductPartCard";
import WarnForExistingSerialCode from "./WarnForExistingSerialCode";

interface ProductFormProps {
  form: FormInstance;
  onChangeAllowMissingFields: (index: number, value: boolean) => void;
  onSubmit: (values: Record<string, string | Array<string>>) => Promise<void>;
  initialProduct?: any;
  initialValues?: object;
  relationId: string;
}

function isEmergencyLighting(productType: any) {
  return productType.rootCategory.name === "Noodverlichting";
}

// eslint-disable-next-line prettier/prettier
export default function ProductForm({ form, onChangeAllowMissingFields, onSubmit, initialProduct, initialValues, relationId }: ProductFormProps) {
  const productTypeId = Form.useWatch("productTypeId", form);

  const { data } = useQuery(ProductTypeQuery, {
    variables: { productTypeId },
    skip: undefined === productTypeId || initialProduct?.productType.id === productTypeId,
  });

  const [isSerialCodeUnknown, setIsSerialCodeUnknown] = useState(false);
  const [isBrandUnknown, setIsBrandUnknown] = useState(false);
  const [isProductionBatchUnknown, setIsProductionBatchUnknown] = useState(false);
  const [isExpirationDateUnknown, setIsExpirationDateUnknown] = useState(false);
  const [isManufacturingDateUnknown, setIsManufacturingDateUnknown] = useState(false);

  const productType =
    undefined !== initialProduct && initialProduct.productType.id === productTypeId ? initialProduct.productType : data?.productType;
  const routeType = undefined !== productType && isEmergencyLighting(productType) ? "NV" : "BHV";

  useEffect(() => {
    const allowMissingFields =
      isSerialCodeUnknown || isBrandUnknown || isProductionBatchUnknown || isExpirationDateUnknown || isManufacturingDateUnknown;
    onChangeAllowMissingFields(0, allowMissingFields);
  }, [isSerialCodeUnknown, isBrandUnknown, isProductionBatchUnknown, isExpirationDateUnknown, isManufacturingDateUnknown]);

  useEffect(() => {
    if (undefined === productType) return;

    form.resetFields([
      "serialCode",
      "productionBatch",
      "expirationDate",
      "manufacturingDate",
      "lastMajorMaintenanceOn",
      "optionalProperties.direction",
      "optionalProperties.construction",
      "parts",
    ]);
  }, [productType]);

  const numberOfParts = Form.useWatch("parts", form)?.length ?? 0;
  const hasBrand = (productType?.hasBrand ?? false) as boolean;
  const hasSerialCode = typeof productType?.serialCodeExpr === "string";
  const hasProductionBatch = typeof productType?.productionBatchExpr === "string";
  const isExpirationDateRequired = (productType?.isExpirationDateRequired ?? false) as boolean;
  const isManufacturingDateRequired = (productType?.isManufacturingDateRequired ?? false) as boolean;
  const hasLastMajorMaintenanceOn = (productType?.majorMaintenanceInterval ?? false) as boolean;
  const hasConsumables = (productType?.hasConsumables ?? false) as boolean;

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      initialValues={initialValues}
      layout="vertical"
      scrollToFirstError={{
        behavior: actions =>
          actions.forEach(({ el, top }) => {
            el.scrollTop = top + 200;
          }),
      }}
    >
      <Form.Item name="locationId" label="Locatie" required rules={[{ required: true }]}>
        <LocationPicker relationId={relationId} />
      </Form.Item>
      <Form.Item name="productTypeId" label="Product-type" required rules={[{ required: true }]}>
        <ProductTypePicker dropdownMatchSelectWidth={false} />
      </Form.Item>
      <SmallDivider>Productinformatie</SmallDivider>
      <FormItemGroup>
        <Form.Item
          name="brand"
          label="Merk/ type"
          style={{ width: "35%" }}
          required={hasBrand}
          help={
            hasBrand && (
              <Checkbox
                checked={isBrandUnknown}
                onChange={() => {
                  setIsBrandUnknown(!isBrandUnknown);
                  if (!isBrandUnknown) form.setFieldValue("brand", null);
                }}
              >
                Onbekend
              </Checkbox>
            )
          }
          rules={[{ required: hasBrand && !isBrandUnknown }]}
        >
          <BrandInput disabled={isBrandUnknown} productTypePath="productTypeId" relationId={relationId} />
        </Form.Item>
        <Form.Item
          name="serialCode"
          label="Serienummer"
          style={{ width: "65%" }}
          required={hasSerialCode}
          help={
            hasSerialCode && (
              <Checkbox
                checked={isSerialCodeUnknown}
                onChange={() => {
                  setIsSerialCodeUnknown(!isSerialCodeUnknown);
                  if (!isSerialCodeUnknown) form.setFieldValue("serialCode", null);
                }}
              >
                Onbekend
              </Checkbox>
            )
          }
          rules={
            hasSerialCode
              ? [
                  {
                    pattern: new RegExp((productType.serialCodeExpr as string).slice(1, -1)),
                    message: "Serienummer niet correct",
                  },
                  {
                    required: hasSerialCode && !isSerialCodeUnknown,
                  },
                ]
              : undefined
          }
        >
          <Input disabled={isSerialCodeUnknown} placeholder={productType?.serialCodeHint ?? undefined} />
        </Form.Item>
      </FormItemGroup>
      {hasSerialCode && <WarnForExistingSerialCode productTypePath="productTypeId" serialCodePath="serialCode" />}
      {routeType === "NV" && (
        <>
          <FormItemGroup>
            <Form.Item name="optionalProperties.direction" label="Richting pijl" style={{ flexGrow: 1 }}>
              <Radio.Group buttonStyle="solid" style={{ display: "flex" }}>
                <Radio.Button value="ARROW_UP" style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
                  <ArrowUpOutlined />
                </Radio.Button>
                <Radio.Button value="ARROW_DOWN" style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
                  <ArrowDownOutlined />
                </Radio.Button>
                <Radio.Button value="ARROW_LEFT" style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
                  <ArrowLeftOutlined />
                </Radio.Button>
                <Radio.Button value="ARROW_RIGHT" style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
                  <ArrowRightOutlined />
                </Radio.Button>
                <Radio.Button value="ARROW_LEFTRIGHT" style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
                  <ArrowLeftOutlined />
                  <span style={{ width: 4, display: "inline-block" }} />
                  <ArrowRightOutlined />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="optionalProperties.construction" label="Constructie" style={{ flexGrow: 0.4 }}>
              <Radio.Group buttonStyle="solid" style={{ display: "flex" }}>
                <Radio.Button value="BUILT_IN" style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
                  Inbouw
                </Radio.Button>
                <Radio.Button value="BUILT_OUT" style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
                  Opbouw
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </FormItemGroup>
        </>
      )}
      <FormItemGroup>
        <Form.Item
          name="productionBatch"
          label="Productiebatch"
          style={{ width: "34.5%" }}
          required={hasProductionBatch}
          help={
            hasProductionBatch && (
              <Checkbox
                checked={isProductionBatchUnknown}
                onChange={() => {
                  setIsProductionBatchUnknown(!isProductionBatchUnknown);
                  if (!isProductionBatchUnknown) form.setFieldValue("productionBatch", null);
                }}
              >
                Onbekend
              </Checkbox>
            )
          }
          rules={
            hasProductionBatch
              ? [
                  {
                    pattern: new RegExp((productType.productionBatchExpr as string).slice(1, -1)),
                    message: "Productiebatch niet correct",
                  },
                  { required: hasProductionBatch && !isProductionBatchUnknown },
                ]
              : undefined
          }
        >
          <Input disabled={isProductionBatchUnknown} placeholder={productType?.productionBatchHint ?? undefined} />
        </Form.Item>
        <Form.Item
          name="manufacturingDate"
          label="Bouwdatum"
          style={{ flexGrow: 1 }}
          rules={[{ required: isManufacturingDateRequired && !isManufacturingDateUnknown }]}
          help={
            isManufacturingDateRequired && (
              <Checkbox
                checked={isManufacturingDateUnknown}
                onChange={() => {
                  setIsManufacturingDateUnknown(!isManufacturingDateUnknown);
                  if (!isManufacturingDateUnknown) form.setFieldValue("manufacturingDate", null);
                }}
              >
                Onbekend
              </Checkbox>
            )
          }
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name={isExpirationDateRequired ? "expirationDate" : "replacementDate"}
          label={isExpirationDateRequired ? "Verloopdatum" : "Vervangdatum"}
          required={isExpirationDateRequired}
          help={
            isExpirationDateRequired && (
              <Checkbox
                checked={isExpirationDateUnknown}
                onChange={() => {
                  setIsExpirationDateUnknown(!isExpirationDateUnknown);
                  if (!isExpirationDateUnknown) form.setFieldValue("expirationDate", null);
                }}
              >
                Onbekend
              </Checkbox>
            )
          }
          rules={[{ required: isExpirationDateRequired && !isExpirationDateUnknown }]}
          style={{ flexGrow: 1 }}
        >
          <DatePicker disabled={isExpirationDateUnknown} style={{ width: "100%" }} />
        </Form.Item>
      </FormItemGroup>
      <Form.Item name="optionalDescription" label="Optionele beschrijving">
        <OptionalDescriptionInput productTypePath={["productTypeId"]} relationId={relationId} />
      </Form.Item>
      <SmallDivider>Onderhoud</SmallDivider>
      <FormItemGroup>
        <Form.Item name="position" label="Looproute" style={{ width: "30%" }}>
          <InputNumber addonBefore={routeType} />
        </Form.Item>
        <Form.Item name="locationDescription" label="Ophanglocatie" style={{ width: "70%" }}>
          <Input />
        </Form.Item>
      </FormItemGroup>
      <FormItemGroup>
        <Form.Item name="installedOn" label="Installatiedatum" style={{ width: "50%" }}>
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="lastMajorMaintenanceOn"
          label="Laatste groot onderhoud"
          style={{ width: "50%" }}
          help={
            undefined !== productType && null !== productType.majorMaintenanceInterval
              ? `Product heeft elke ${formatDuration(productType.majorMaintenanceInterval)} groot onderhoud`
              : undefined
          }
        >
          <DatePicker disabled={!hasLastMajorMaintenanceOn} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name="lastCheckedOn" label="Laatst gecontroleerd" style={{ width: "50%" }}>
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
      </FormItemGroup>
      <Form.Item name="condition" label="Conditie" required rules={[{ required: true }]}>
        <ProductConditionPicker />
      </Form.Item>

      {undefined !== productTypeId && hasConsumables && (
        <>
          <SmallDivider>{`Onderdelen (${numberOfParts} toegevoegd)`}</SmallDivider>
          <Form.List name="parts">
            {(elements, { add, remove }) => (
              <Space direction="vertical">
                {elements.map(({ key, name: formIndex }) => {
                  const partId = form.getFieldValue(["parts", formIndex, "id"]);

                  // eslint-disable-next-line prettier/prettier
                  const initialProduct_ = undefined !== partId && undefined !== initialProduct
                    ? initialProduct.parts.find(p => p.id === partId)
                    : undefined;

                  return (
                    <RegisterProductPartCard
                      key={key}
                      formIndex={formIndex}
                      onChangeAllowMissingFields={value => {
                        onChangeAllowMissingFields(formIndex, value);
                      }}
                      onRemoveClick={() => {
                        if (undefined === initialProduct_) {
                          onChangeAllowMissingFields(formIndex, false);
                          return remove(formIndex);
                        }

                        form.setFieldValue(["parts", formIndex, "condition"], "CONDITION_DESTROYED");
                      }}
                      onReplaceClick={() => {
                        form.setFieldValue(["parts", formIndex, "condition"], "CONDITION_DESTROYED");

                        add({
                          ...DEFAULT_PART_VALUE,
                          productTypeId: form.getFieldValue(["parts", formIndex, "productTypeId"]),
                          brand: form.getFieldValue(["parts", formIndex, "brand"]),
                          optionalDescription: form.getFieldValue(["parts", formIndex, "optionalDescription"]),
                          previousProductId: partId,
                        });
                      }}
                      initialProduct={initialProduct_}
                      parentProductTypeId={productTypeId}
                      relationId={relationId}
                    />
                  );
                })}
                <Button icon={<PlusOutlined />} onClick={() => add(DEFAULT_PART_VALUE)} style={{ alignSelf: "flex-end" }}>
                  Onderdeel toevoegen
                </Button>
              </Space>
            )}
          </Form.List>
        </>
      )}
    </Form>
  );
}

function FormItemGroup({ children }: { children: React.ReactNode }) {
  return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>{children}</div>;
}

function SmallDivider({ children }: { children: string }) {
  return <Divider style={{ marginBlockStart: 0, marginBlockEnd: 12 }}>{children}</Divider>;
}

const DEFAULT_PART_VALUE = {
  productTypeId: undefined,
  condition: "CONDITION_GOOD",
  installedOn: dayjs(),
  lastCheckedOn: dayjs(),
};
