import { LeftOutlined, RedoOutlined, RightOutlined } from "@ant-design/icons";
import { Button, DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { Views } from "react-big-calendar";

import EmployeePicker from "@/components/EmployeePicker";
import RelationPicker from "@/components/RelationPicker";
import Constants from "@/constants";

interface ScheduleToolbarProps {
  date: Date;
  employeeId: string | undefined;
  onChangeDate: (value: Date) => void;
  onChangeEmployeeId: (value: string) => void;
  onChangeView: (value: (typeof Views)[keyof typeof Views]) => void;
  onChangeRelationId: (value: string | undefined) => void;
  onReload: () => void;
  relationId: string | undefined;
  reloading: boolean;
  view: (typeof Views)[keyof typeof Views];
}

export default function ScheduleToolbar({
  date,
  employeeId,
  onChangeDate,
  onChangeEmployeeId,
  onChangeView,
  onChangeRelationId,
  onReload,
  relationId,
  reloading,
  view,
}: ScheduleToolbarProps) {
  const handleOnPrevious = () => {
    const prevValue = dayjs(date).subtract(1, "week").toDate();
    onChangeDate(prevValue);
  };

  const handleOnNext = () => {
    const nextValue = dayjs(date).add(1, "week").toDate();
    onChangeDate(nextValue);
  };

  return (
    <Space
      align="start"
      direction="horizontal"
      style={{
        borderBottom: 0,
        borderColor: "#f0f0f0",
        borderStyle: "solid",
        borderRadius: "8 8 0 0",
        borderWidth: 1,
        padding: 16,
        justifyContent: "space-between",
      }}
    >
      <Space direction="vertical">
        <Space direction="horizontal">
          <DatePicker.WeekPicker
            onChange={value => {
              if (value === null) return;

              const nextValue = value.startOf("week");
              onChangeDate(nextValue.toDate());
            }}
            value={dayjs(date)}
          />
          <EmployeePicker
            groupId={Constants.groupIds.GROUP_SERVICE_EMPLOYEES}
            onChange={onChangeEmployeeId}
            value={employeeId}
            style={{ minWidth: 200 }}
          />
        </Space>
        <div style={{ columnGap: 8, display: "flex", alignItems: "center" }}>
          Filter op relatie:
          <RelationPicker
            allowClear
            onClear={() => {
              onChangeRelationId(undefined);
            }}
            onChange={onChangeRelationId}
            placeholder="Filter op relatie"
            value={relationId}
            style={{ minWidth: 200 }}
          />
        </div>
      </Space>
      <div>
        <Button onClick={() => handleOnPrevious()} size="small">
          <LeftOutlined style={{ fontSize: 14 }} />
        </Button>
        <span style={{ fontSize: 16, marginInline: 12 }}>
          {`${dayjs(date).format("LL")} - ${dayjs(date).endOf("week").add(-2, "days").format("LL")}`}
        </span>
        <Button onClick={() => handleOnNext()} size="small">
          <RightOutlined style={{ fontSize: 14 }} />
        </Button>
      </div>
      <Space direction="horizontal" size="small">
        <Button loading={reloading} icon={<RedoOutlined />} onClick={() => onReload()} />
        {relationId === undefined ? (
          <Button.Group>
            <Button disabled={view === Views.WORK_WEEK} onClick={() => onChangeView(Views.WORK_WEEK)}>
              Werkweek
            </Button>
            <Button disabled={view === Views.AGENDA} onClick={() => onChangeView(Views.AGENDA)}>
              Agenda
            </Button>
          </Button.Group>
        ) : (
          <Button onClick={() => onChangeRelationId(undefined)} icon={<LeftOutlined />}>
            Terug
          </Button>
        )}
      </Space>
    </Space>
  );
}
