import { CheckOutlined, LoadingOutlined } from "@ant-design/icons";
import { ApolloError, gql, useApolloClient, useMutation } from "@apollo/client";
import { Button, Card, DatePicker, Form, Input, notification, Space } from "antd";
import debounce from "lodash/debounce";
import { useMemo, useRef, useState } from "react";

import AdministrationPicker from "@/components/AdministrationPicker";
import AppointmentTypePicker from "@/components/AppointmentTypePicker";
import LocationPicker from "@/components/LocationPicker";
import MarkdownTextInput from "@/components/MarkdownTextInput";
import ProductTypePicker from "@/components/ProductTypePicker";
import RelationPicker from "@/components/RelationPicker";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import ConsumableGoodsTable from "./ConsumableGoodsTable";

interface RequestAppointmentInput {
  relationId: string;
  locationId: string;
  appointmentTypeId: string;
  prospectiveDate: Date;
  plannerComment: string | null;
  comment: string | null;
}

type ProductType = { id: string; code: string; description: string };
export default function RequestInstallationAppointment() {
  const [form] = Form.useForm();
  const client = useApolloClient();

  const [importOrderAsync, { error: importOrderError, called: importedOrder, loading: importingOrder }] = useMutation(ImportOrderMutation);
  const [requestAppointmentAsync, { loading }] = useMutation(RequestAppointmentMutation);

  const productTypePickerRef = useRef<React.ElementRef<typeof ProductTypePicker>>(null);
  const [isSelectingProductType, setIsSelectingProductType] = useState(false);
  const [consumableGoods, setConsumableGoods] = useState<Array<{ productType: ProductType; amount: number }>>([]);

  const handleOnChangeOrderNumber = useMemo(
    () =>
      debounce(async () => {
        try {
          const administrationId = form.getFieldValue("administrationId");
          const orderNumber = parseInt(form.getFieldValue("orderNumber"), 10);

          if (!administrationId || !orderNumber || Number.isNaN(orderNumber)) {
            return;
          }

          const response = await importOrderAsync({
            variables: {
              administrationId,
              orderNumber,
            },
          });

          if (response.data === undefined) throw new Error("Could not fetch order information");
          const order = response.data.importOrder.order;

          form.setFieldsValue({
            relationId: order.relation.id,
            locationId: order.location.id,
          });

          setConsumableGoods(order.orderLines);
        } catch (error) {
          if (error instanceof ApolloError) {
            mapGraphQLErrorsToNotifications(error as ApolloError);
          } else if (error instanceof Error) {
            notification.error({
              message: "Fout tijdens het ophalen van de gegevens.",
              description: error.message,
            });
          }
        }
      }, 1000),
    []
  );

  const handleOnSubmit = async (values: RequestAppointmentInput) => {
    try {
      await requestAppointmentAsync({
        variables: {
          ...values,
          consumableGoods: consumableGoods
            .filter(element => element.amount > 0)
            .map(element => ({
              productTypeId: parseInt(element.productType.id, 10),
              amount: element.amount,
            })),
        },
      });

      notification.success({
        message: "Afspraak aangevraagd",
        description: "Uw afspraak is aangevraagd.",
      });

      form.resetFields();
      setConsumableGoods([]);
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const handleOnSelectProductType = (productTypeId: string) => {
    const productType = client.readFragment({
      id: "ProductType:" + productTypeId,
      fragment: gql`
        fragment ConsumableGood_ProductType on ProductType {
          id
          code
          description
        }
      `,
    });

    setConsumableGoods(current => [...current, { productType, amount: 1 }]);
    setIsSelectingProductType(false);
  };

  return (
    <Form onFinish={handleOnSubmit} form={form} layout="horizontal" labelCol={{ span: 4 }}>
      <Space direction="vertical">
        <Card bodyStyle={{ display: "flex", flexDirection: "row", gap: 8 }}>
          <div style={{ flex: 1 }}>
            <Form.Item name="administrationId" label="Administratie" required rules={[{ required: true }]}>
              <AdministrationPicker />
            </Form.Item>
            <Form.Item name="orderNumber" label="Ordernummer" required rules={[{ required: true }]}>
              <Input
                className="input-number-hidden-spinbox"
                type="number"
                min={1}
                addonBefore="#"
                onKeyUp={handleOnChangeOrderNumber}
                suffix={importingOrder ? <LoadingOutlined /> : <span />}
              />
            </Form.Item>
            <Form.Item name="relationId" label="Relatie" required rules={[{ required: true }]}>
              <RelationPicker disabled />
            </Form.Item>
            <Form.Item name="locationId" label="Locatie" required rules={[{ required: true }]}>
              <LocationPicker relationId={Form.useWatch("relationId", form)} />
            </Form.Item>
            <Form.Item name="appointmentTypeId" label="Soort" required rules={[{ required: true }]}>
              <AppointmentTypePicker />
            </Form.Item>
            <Form.Item name="prospectiveDate" label="Beoogde datum" required rules={[{ required: true }]}>
              <DatePicker />
            </Form.Item>
          </div>
          <div style={{ flex: 1 }}>
            <Form.Item name="plannerComment" label="Planning notities" labelCol={{ span: 6 }}>
              <MarkdownTextInput />
            </Form.Item>
            <Form.Item label="Buitendienst notities" name="onSiteComment" labelCol={{ span: 6 }}>
              <Input.TextArea rows={3} />
            </Form.Item>
          </div>
        </Card>
        {importedOrder && importOrderError === undefined && (
          <>
            <Card bodyStyle={{ padding: 0 }} title="Producten om mee te nemen">
              <div className="ant-table-title">
                {!isSelectingProductType ? (
                  <Button
                    onClick={() => {
                      setIsSelectingProductType(true);
                      setTimeout(() => productTypePickerRef.current?.focus(), 500);
                    }}
                  >
                    {consumableGoods.length < 1 ? "Eerste product toevoegen" : "Nog een product toevoegen"}
                  </Button>
                ) : (
                  <ProductTypePicker
                    ref={productTypePickerRef}
                    dropdownMatchSelectWidth={false}
                    onChange={handleOnSelectProductType}
                    onClear={() => setIsSelectingProductType(false)}
                    style={{ width: 350 }}
                  />
                )}
              </div>
              {consumableGoods.length > 0 && <ConsumableGoodsTable data={consumableGoods} onChange={setConsumableGoods} />}
            </Card>
            <Button loading={loading} htmlType="submit" type="primary" icon={<CheckOutlined />}>
              Aanvragen
            </Button>
          </>
        )}
      </Space>
    </Form>
  );
}

const ImportOrderMutation = gql`
  mutation ($administrationId: ID!, $orderNumber: Int!) {
    importOrder(input: { administrationId: $administrationId, orderNumber: $orderNumber }) {
      order {
        relation {
          id
        }
        location {
          id
        }
        orderLines {
          productType {
            id
            code
            description
          }
          amount
        }
      }
    }
  }
`;

const RequestAppointmentMutation = gql`
  mutation (
    $appointmentTypeId: ID!
    $relationId: ID!
    $locationId: ID!
    $prospectiveDate: DateTime!
    $onSiteComment: String
    $plannerComment: String
    $consumableGoods: [ConsumableGoodInput!]
  ) {
    requestAppointment(
      input: {
        appointmentTypeId: $appointmentTypeId
        relationId: $relationId
        locationId: $locationId
        prospectiveDate: $prospectiveDate
        onSiteComment: $onSiteComment
        plannerComment: $plannerComment
        consumableGoods: $consumableGoods
      }
    ) {
      appointment {
        id
      }
    }
  }
`;
