import { InboxOutlined, MailOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Form, Input, Modal, notification, Skeleton, Switch, Upload } from "antd";
import * as React from "react";
import ContentEditable from "react-contenteditable";

import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

interface PrepareInviteModalProps {
  appointmentId: string;
  onClose: () => void;
}

interface InviteFormProps {
  subject: string;
  content: string;
}

export default function PrepareInviteModal({ appointmentId, onClose }: PrepareInviteModalProps) {
  const [form] = Form.useForm();

  const [sendInvitationAsync, { loading: isSendingAsync }] = useMutation(SendInvitationMutation);
  const [attachments, setAttachments] = React.useState<FileList | null>(null);
  const [timeWindowInsteadOfExactTimeInInvite, setTimeWindowInsteadOfExactTimeInInvite] = React.useState(false);

  const { data } = useQuery(PrepareAppointmentInvitationQuery, {
    variables: { appointmentId, timeWindowInsteadOfExactTimeInInvite },
    fetchPolicy: "network-only",
    onCompleted: data => {
      form.setFieldsValue({
        subject: data.prepareAppointmentInvitation.subject,
        content: data.prepareAppointmentInvitation.content,
      });
    },
    onError: error => {
      mapGraphQLErrorsToNotifications(error);
    },
  });

  const handleOnSubmit = async ({ subject, content }: InviteFormProps) => {
    try {
      await sendInvitationAsync({ variables: { appointmentId, timeWindowInsteadOfExactTimeInInvite, subject, content, attachments } });
      notification.success({ message: "Uitnodiging is verzonden" });

      onClose();
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Modal
      centered
      confirmLoading={isSendingAsync}
      onCancel={onClose}
      onOk={() => form.submit()}
      okButtonProps={{ icon: <MailOutlined /> }}
      okText="Versturen"
      open
      styles={{
        body: {
          overflowY: "auto",
          maxHeight: window.innerHeight - 168,
        },
      }}
      title="Uitnodigingsmail aanpassen"
      width={650}
    >
      {undefined === data ? (
        <Skeleton />
      ) : (
        <Form<InviteFormProps> form={form} layout="vertical" onFinish={handleOnSubmit}>
          <Form.Item name="subject" label="Onderwerp">
            <Input />
          </Form.Item>
          <div className="ant-form-item ant-row" style={{ justifyContent: "space-between" }}>
            <span>Tijdsvak in uitnodiging</span>
            <Switch checked={timeWindowInsteadOfExactTimeInInvite} onChange={value => setTimeWindowInsteadOfExactTimeInInvite(value)} />
          </div>
          <Form.Item name="content" label="Inhoud" valuePropName="html" getValueFromEvent={event => event.target.value}>
            {/* @ts-expect-error props are passed in via Form.Item */}
            <ContentEditable className="ant-input" style={{ padding: 0 }} />
          </Form.Item>
          <Form.Item label="Bijlages">
            <Upload.Dragger
              beforeUpload={() => false}
              multiple
              onChange={({ fileList }) => {
                if (fileList.length < 1) return setAttachments(null);

                const dataTransfer = new DataTransfer();
                fileList.forEach(f => dataTransfer.items.add(f.originFileObj as never));
                setAttachments(dataTransfer.files);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Klik of sleep een bestand om toe te voegen als bijlage</p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}

const PrepareAppointmentInvitationQuery = gql`
  query ($appointmentId: ID!, $timeWindowInsteadOfExactTimeInInvite: Boolean!) {
    prepareAppointmentInvitation(
      appointmentId: $appointmentId
      timeWindowInsteadOfExactTimeInInvite: $timeWindowInsteadOfExactTimeInInvite
    ) {
      subject
      content
    }
  }
`;

const SendInvitationMutation = gql`
  mutation (
    $appointmentId: ID!
    $timeWindowInsteadOfExactTimeInInvite: Boolean!
    $subject: String!
    $content: String!
    $attachments: [UploadedFile!]
  ) {
    sendAppointmentInvitation(
      input: {
        id: $appointmentId
        timeWindowInsteadOfExactTimeInInvite: $timeWindowInsteadOfExactTimeInInvite
        subject: $subject
        content: $content
        attachments: $attachments
      }
    ) {
      appointment {
        id
        invitationSentOn
      }
    }
  }
`;
