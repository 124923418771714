import { gql } from "@apollo/client";

const ServicedProductsQuery = gql`
  query (
    $relationId: ID!
    $locationId: ID
    $locationDescription: String
    $productCategoryId: ID
    $productTypeId: ID
    $serialCode: String
    $condition: [ProductCondition!]
    $routePositionSort: OrderMode
    $offset: Int!
    $limit: Int!
  ) {
    servicedProducts(
      relationId: $relationId
      locationId: $locationId
      locationDescription: $locationDescription
      productCategoryId: $productCategoryId
      productTypeId: $productTypeId
      serialCode: $serialCode
      condition: $condition
      routePositionSort: $routePositionSort
      offset: $offset
      limit: $limit
    ) {
      totalCount
      edges {
        id
        productType {
          id
          code
          description
          category {
            id
            name
          }
          rootCategory {
            id
            name
          }
        }
        brand
        serialCode
        locationDescription
        location {
          id
          name
          address {
            street
            postalCode
            city
            country
            coordinates {
              latitude
              longitude
            }
          }
        }
        route
        position
        condition
        lastCheckedOn
      }
    }
  }
`;

export default ServicedProductsQuery;
