import { QuestionCircleOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Form, Modal, notification } from "antd";
import dayjs from "dayjs";

import MonthPicker from "@/components/MonthPicker";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

interface ExtendVisitModalProps {
  contractId: string;
  onClose: () => void;
  visitIds: string[];
}

export default function ExtendVisitModal({ contractId, onClose, visitIds }: ExtendVisitModalProps) {
  const [formInstance] = Form.useForm();
  const { data } = useQuery(ContractQuery, { variables: { contractId } });
  const [extendVisitAsync, { loading }] = useMutation(ExtendVisitMutation);
  const isSingular = visitIds.length === 1;

  const handleOnCancel = async values => {
    try {
      await Promise.all(visitIds.map(visitId => extendVisitAsync({ variables: { contractId, visitId, endingOn: values.endingOn } })));

      notification.success({ message: isSingular ? "Einddatum is aangepast" : "Einddata zijn aangepast" });
      onClose();
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Modal confirmLoading={loading} onCancel={onClose} onOk={() => formInstance.submit()} okType="danger" title="Einddatum aanpassen" open>
      <p>
        <QuestionCircleOutlined />
        <span style={{ marginInline: 8 }}>
          Weet u zeker dat u {isSingular ? "dit terugkerend bezoek" : "deze terugkerende bezoeken"} wil verlengen?
        </span>
      </p>
      <p>
        Als u {isSingular ? "dit terugkerend bezoek" : "deze terugkerende bezoeken"} wil verlengen kunt u een nieuwe einddatum opgeven. De
        datum kan ook leeg gelaten worden. Afspraken worden vervolgens automatisch aangemaakt.
      </p>
      {data?.contract.endingOn && (
        <p style={{ fontStyle: "italic" }}>
          Kan niet later zijn dan de einddatum van het contract ({dayjs(data.contract.endingOn).format("MMMM, YYYY")})
        </p>
      )}
      <Form form={formInstance} onFinish={handleOnCancel}>
        <Form.Item label="Stopzetten per" name="endingOn" help="Laat leeg voor geen einddatum">
          <MonthPicker format="MMMM, YYYY" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

const ContractQuery = gql`
  query {
    contract(id: $contractId) {
      id
      endingOn
    }
  }
`;

const ExtendVisitMutation = gql`
  mutation ($contractId: ID!, $visitId: ID!, $endingOn: DateTime) {
    extendVisit(input: { contractId: $contractId, visitId: $visitId, endingOn: $endingOn }) {
      contract {
        id
        visits {
          id
          endingOn
        }
      }
    }
  }
`;
