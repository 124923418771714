import { AutoComplete, AutoCompleteProps } from "antd";
import { useState } from "react";

interface TableFilterAutoCompleteProps {
  confirm: () => void;
  dataSource: Array<{ label: string; value: string }> | undefined;
  onClear?: () => void;
  onSearch?: (query: string) => void;
  initialValue?: string;
}

export default function TableFilterAutoComplete({ confirm, dataSource, onClear, onSearch, initialValue }: TableFilterAutoCompleteProps) {
  const [query, setQuery] = useState<string | undefined>(initialValue);

  const handleOnSearch = () => {
    if (undefined !== query) onSearch?.(query);
    confirm();
  };

  const handleOnResetSearch = () => {
    setQuery(undefined);

    if (undefined !== onClear) {
      onClear();
      confirm();
    }
  };

  const handleOnFilter: AutoCompleteProps["filterOption"] = (input, option) => {
    return (option?.label as string).toLocaleLowerCase().includes(input.toLocaleLowerCase());
  };

  return (
    <div className="ant-table-filter-dropdown" style={{ minWidth: 250 }}>
      <div style={{ background: "#fff", padding: 8, borderRadius: "6px 6px 0px 0px" }}>
        <AutoComplete
          onChange={value => setQuery(value)}
          filterOption={handleOnFilter}
          options={dataSource}
          placeholder="Vul iets in"
          showSearch
          value={query}
          style={{ width: "100%" }}
        />
      </div>
      <div className="ant-table-filter-dropdown-btns">
        <a className="ant-table-filter-dropdown-link confirm" onClick={handleOnSearch}>
          Zoeken
        </a>
        <a className="ant-table-filter-dropdown-link clear" onClick={() => handleOnResetSearch()}>
          Reset
        </a>
      </div>
    </div>
  );
}
