export default function prepareIssueComment(value: string, username: string) {
  // eslint-disable-next-line prettier/prettier
  const lines = [
    "> " + value,
    ">",
    `> -- *${username}*`,
  ];

  return lines.join("\n");
}
