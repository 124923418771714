import { gql, useQuery } from "@apollo/client";
import { captureException } from "@sentry/react";
import { notification } from "antd";
import dayjs from "dayjs";
import { Icon, Layer, Map as LeafletMap, Marker } from "leaflet";
import { AntPath } from "leaflet-ant-path";
import * as React from "react";

import { rootAppointmentType } from "@/functions/appointment-util";
import useQueryParams from "@/hooks/use-query-params";

import bigPinkMarker from "./big-pink-marker.png";
import AppointmentPane from "./components/AppointmentPane";
import Map from "./components/Map";
import OptionsPane from "./components/OptionsPane";
import RoutingModePane from "./components/RoutingModePane";
import Spinner from "./components/Spinner";
import MarkersContext, { MarkerRecord } from "./MarkersContext";

export default function Scheduler() {
  const queryParams = useQueryParams();
  const leafletMapRef = React.useRef<LeafletMap | null>(null);
  const renderedAntPath = React.useRef(false);
  const locationMarkerLayer = React.useRef<Layer>();

  const [mode, setMode] = React.useState<"scheduling" | "routing">("scheduling");
  const [optionsPaneVisible, setOptionsPaneVisible] = React.useState(true);
  const [selectedAppointmentIds, setSelectedAppointmentIds] = React.useState<string[]>([]);

  const routeEmployeeId = queryParams.get("routeEmployeeId");
  const routeDate = queryParams.get("routeDate");

  // custom markers
  const [customMarkers, setCustomMarkers] = React.useState<Record<string, MarkerRecord>>(() => {
    let markers: Record<string, MarkerRecord> = {};
    const persistedCustomMarkers = localStorage.getItem("customMarkersMap");
    if (null !== persistedCustomMarkers) markers = JSON.parse(persistedCustomMarkers);

    return markers;
  });

  // applied filters
  const [appointmentTypeIds, setAppointmentTypeIds] = React.useState<string[] | undefined>(undefined);
  const [relationIds, setRelationIds] = React.useState<string[] | undefined>(undefined);
  const [includeOnlyRequested, setIncludeOnlyRequested] = React.useState(false);
  const [includeOnlyFireExtinguishers, setIncludeOnlyFireExtinguishers] = React.useState(false);
  const [[startTime, endTime], setPeriod] = React.useState<[dayjs.Dayjs, dayjs.Dayjs]>(() => {
    if (null == routeEmployeeId || null === routeDate) return [dayjs().startOf("month"), dayjs().add(2, "months")];
    return [dayjs(routeDate).startOf("month"), dayjs(routeDate).add(2, "months")];
  });

  const { data: overviewData, loading: overviewDataLoading } = useQuery(AppointmentsQuery, {
    skip: !!relationIds && relationIds.length > 0,
    variables: {
      startTime: startTime.format(),
      endTime: endTime.format(),
    },
  });

  const { data: relationData, loading: relationDataLoading } = useQuery(AppointmentsForRelationQuery, {
    skip: !relationIds || relationIds.length < 1,
    variables: {
      relationIds,
      endTime: endTime.format(),
      startTime: startTime.format(),
    },
  });

  React.useEffect(() => {
    if (true === renderedAntPath.current) return;
    if (!leafletMapRef.current) return;
    if (null === routeEmployeeId || null === routeDate) return;
    if (undefined === overviewData || overviewData.appointmentsForScheduler.length < 1) return;

    const parsedRouteDate = dayjs(routeDate);
    const appointmentsOnPath = [...overviewData.appointmentsForScheduler]
      .filter(appointment => {
        return (
          appointment.employee?.id === routeEmployeeId &&
          dayjs(appointment.startTime).isSame(parsedRouteDate, "date") &&
          // ensure that location has coordinates
          !!appointment.location.address.coordinates.latitude &&
          !!appointment.location.address.coordinates.longitude
        );
      })
      .sort((a, b) => {
        const startTimeA = dayjs(a.startTime);
        const startTimeB = dayjs(b.startTime);

        if (startTimeA.isAfter(startTimeB)) return 1;
        if (startTimeB.isAfter(startTimeA)) return -1;

        return 0;
      })
      .map(appointment => {
        const { latitude, longitude } = appointment.location.address.coordinates;
        return [latitude, longitude] as [number, number];
      });

    if (appointmentsOnPath.length === 0) {
      notification.warning({
        message: "Kon route niet op kaart laten zien",
        description: "Geen afspraken voor monteur op geselecteerde datum",
      });

      renderedAntPath.current = true;
      return;
    }

    try {
      // eslint-disable-next-line prettier/prettier
      new AntPath(appointmentsOnPath, { color: "black", paused: false, delay: 1000 }).addTo(leafletMapRef.current);

      leafletMapRef.current.flyToBounds(appointmentsOnPath);
    } catch (error) {
      notification.warning({
        message: "Kon route niet op kaart laten zien",
        description: "Een of meerdere locaties heeft ongeldig adresgegevens",
      });

      captureException(error, {
        tags: {
          url: window.location.toString(),
        },
        contexts: {
          route: {
            route_date: routeDate,
            route_employee_id: routeEmployeeId,
            coordinates: appointmentsOnPath,
          },
        },
      });
    } finally {
      renderedAntPath.current = true;
    }
  }, [overviewData]);

  const appointments = React.useMemo(
    () =>
      filterAppointments(
        overviewData?.appointmentsForScheduler ?? relationData?.appointmentsForScheduler ?? [],
        includeOnlyRequested,
        includeOnlyFireExtinguishers,
        appointmentTypeIds
      ),
    [overviewData, relationData, includeOnlyRequested, includeOnlyFireExtinguishers, appointmentTypeIds]
  );

  const markersContextVal = React.useMemo(
    () => ({
      markers: customMarkers,
      setMarkerTo: (relationId: string, v: MarkerRecord | undefined) => {
        setCustomMarkers(value => {
          const retVal = { ...value };

          if (undefined === v) delete retVal[relationId];
          else if (undefined !== v.marker) retVal[relationId] = v;

          setTimeout(() => localStorage.setItem("customMarkersMap", JSON.stringify(retVal)), 0);

          return retVal;
        });
      },
    }),
    [customMarkers]
  );

  const handleOnFocusCoordinates = ({ lat, lng }: { lat: number; lng: number }) => {
    if (undefined !== locationMarkerLayer.current) leafletMapRef.current?.removeLayer(locationMarkerLayer.current);

    locationMarkerLayer.current = new Marker([lat, lng], {
      icon: new Icon({
        iconAnchor: [30, 30],
        iconUrl: bigPinkMarker,
        iconSize: [60, 60],
      }),
    });

    leafletMapRef.current?.addLayer(locationMarkerLayer.current);
    leafletMapRef.current?.flyTo({ lat, lng }, 12);
  };

  const handleOnClickAppointment = (appointmentId: string) => {
    if ("scheduling" === mode) return setSelectedAppointmentIds([appointmentId]);

    setSelectedAppointmentIds(curr => {
      if (curr.length === 0) return [appointmentId];
      return [curr.pop() as string, appointmentId];
    });
  };

  const selectedAppointmentsMapped = React.useMemo(() => {
    // eslint-disable-next-line prettier/prettier
    return selectedAppointmentIds.map(id => appointments.find(a => a.id === id)).filter(value => value !== undefined);
  }, [selectedAppointmentIds, appointments]);

  return (
    <MarkersContext.Provider value={markersContextVal}>
      <Map
        ref={leafletMapRef}
        appointments={appointments}
        onClickAppointment={handleOnClickAppointment}
        selectedAppointmentIds={selectedAppointmentIds}
      />
      {(overviewDataLoading || relationDataLoading) && <Spinner />}
      <OptionsPane
        appointmentTypeIds={appointmentTypeIds}
        onClickRoutingMode={() => {
          setOptionsPaneVisible(false);
          setMode("routing");
        }}
        onClickVisible={setOptionsPaneVisible}
        onChangeAppointmentTypeIds={setAppointmentTypeIds}
        onChangeIncludeOnlyFireExtinguishers={setIncludeOnlyFireExtinguishers}
        onChangeIncludeOnlyRequested={setIncludeOnlyRequested}
        onChangePeriod={setPeriod}
        onChangeRelationIds={setRelationIds}
        onFocusCoordinates={handleOnFocusCoordinates}
        includeOnlyFireExtinguishes={includeOnlyFireExtinguishers}
        includeOnlyRequested={includeOnlyRequested}
        period={[startTime, endTime]}
        visible={optionsPaneVisible}
      />
      {mode === "scheduling" && selectedAppointmentsMapped.length === 1 && (
        <AppointmentPane
          key={selectedAppointmentIds[0]}
          appointmentId={selectedAppointmentIds[0]}
          onClose={() => setSelectedAppointmentIds([])}
        />
      )}
      {mode === "routing" && selectedAppointmentsMapped.length === 2 && (
        <RoutingModePane
          appointments={selectedAppointmentsMapped as [any, any]}
          onCloseClick={() => {
            setMode("scheduling");
            setSelectedAppointmentIds([]);
            setOptionsPaneVisible(true);
          }}
        />
      )}
    </MarkersContext.Provider>
  );
}

function filterAppointments(
  appointments: any[],
  includeOnlyRequested: boolean,
  includeOnlyFireExtinguishers: boolean,
  appointmentTypeIds?: string[]
) {
  return appointments.filter(appointment => {
    if (includeOnlyRequested && appointment.status !== "STATUS_REQUESTED") return false;
    if (includeOnlyFireExtinguishers && appointment.defaultOverviewImage !== "fire_extinguisher") return false;

    if (undefined !== appointmentTypeIds) {
      const rootAppointmentTypeId = rootAppointmentType(appointment.appointmentType).id;
      return appointmentTypeIds.includes(rootAppointmentTypeId) || appointmentTypeIds.includes(appointment.appointmentType.id);
    }

    return true;
  });
}

const AppointmentFields = gql`
  fragment AppointmentFragment on Appointment {
    id
    appointmentType {
      id
      name
      category {
        id
        name
      }
    }
    relation {
      id
      name
      afasCode
    }
    employee {
      id
    }
    location {
      id
      name
      address {
        street
        postalCode
        city
        country
        coordinates {
          latitude
          longitude
        }
      }
      locale
    }
    prospectiveDate
    startTime
    status
    defaultOverviewImage
  }
`;

const AppointmentsForRelationQuery = gql`
  query ($relationIds: [ID!]!, $startTime: DateTime!, $endTime: DateTime!) {
    appointmentsForScheduler(relationIds: $relationIds, startTime: $startTime, endTime: $endTime) {
      ...AppointmentFragment
    }
  }

  ${AppointmentFields}
`;

const AppointmentsQuery = gql`
  query ($startTime: DateTime!, $endTime: DateTime!) {
    appointmentsForScheduler(startTime: $startTime, endTime: $endTime) {
      ...AppointmentFragment
    }
  }

  ${AppointmentFields}
`;
