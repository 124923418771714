import { Select, SelectProps } from "antd";
import dayjs from "dayjs";

const MONTHS = dayjs.months();
export default function MonthInYearPicker({ mode, ...props }: SelectProps<number[]>) {
  return (
    <Select {...props} mode={mode}>
      {MONTHS.map((month, index) => (
        <Select.Option key={month} value={String(index + 1)}>
          {month}
        </Select.Option>
      ))}
    </Select>
  );
}
