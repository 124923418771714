import { ApolloClient, InMemoryCache, Operation, split } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { RetryLink } from "@apollo/client/link/retry";
import { createUploadLink } from "apollo-upload-client";

import { refreshJwtToken } from "@/authorization";

import resetSession from "./functions/reset-session";

function isUploadRequest({ variables }: Operation) {
  return Object.values(variables).some(value => value instanceof File || value instanceof Blob || value instanceof FileList);
}

export default function configureApollo(batchUri: string, singleUri: string) {
  let refreshInFlight = false;
  let refreshDidFail = false;

  // enables batching multiple requests
  const batch = new BatchHttpLink({
    uri: batchUri,
    credentials: "include",
  });

  // enables retrying if credentials expired
  const retry = new RetryLink({
    delay: {
      initial: 2000,
      max: Infinity,
      jitter: true,
    },
    attempts: (count, _operation, error) => {
      const isCredError = error.toString().includes("401") || error.toString().includes("403") || error.toString().includes("Unauthorized");

      if (!refreshInFlight && !refreshDidFail && isCredError) {
        refreshInFlight = true;

        refreshJwtToken()
          .then(succeeded => {
            refreshInFlight = false;
            refreshDidFail = !succeeded;

            if (false === succeeded) resetSession();
          })
          .catch(() => {
            refreshInFlight = false;
            refreshDidFail = true;
            resetSession();
          });
      }

      // only re-attempt if the error was because the token is expired.
      return !refreshDidFail && count < 3 && isCredError;
    },
  });

  const upload = createUploadLink({
    uri: singleUri,
    credentials: "include",
  });

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: split(isUploadRequest, retry.concat(upload), retry.concat(batch)),
  });
}
