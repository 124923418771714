import { ApolloError } from "@apollo/client";
import { Alert, Button, Input } from "antd";
import { useRef, useState } from "react";

import MarkdownTextInput from "@/components/MarkdownTextInput";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import AssignedEmployeePicker from "./AssignedEmployeePicker";

interface CommentEditorProps {
  currentlyAssignedUserId: string | undefined;
  lastCommentingUserId: string;
  onSubmit: (text: string | undefined, assignToEmployeeId: string | undefined) => Promise<void>;
  isPubliclyAccessible: boolean;
  submitting: boolean;
}

// eslint-disable-next-line prettier/prettier
export default function CommentEditor({ currentlyAssignedUserId, lastCommentingUserId, onSubmit, isPubliclyAccessible, submitting }: CommentEditorProps) {
  const content = useRef<string>("");
  const editorHandle = useRef<React.ElementRef<typeof MarkdownTextInput>>(null);

  const [isSubmittingUsing, setIsSubmittingUsing] = useState<"post-only" | "assign-and-post">();
  const [hasContent, setHasContent] = useState(false);
  const [isEditorExpanded, setIsEditorExpanded] = useState(false);
  const [assignUserId, setAssignUserId] = useState(lastCommentingUserId);

  const handleOnChange = (value: string) => {
    content.current = value;
    setHasContent(value.trim().length > 0);
  };

  const handleOnComment = async () => {
    try {
      if (content.current.trim().length < 1) return;
      setIsSubmittingUsing("post-only");
      await onSubmit(content.current, undefined);

      editorHandle.current?.reset();
      content.current = "";
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const handleOnAssignAndComment = async () => {
    try {
      setIsSubmittingUsing("assign-and-post");
      const value = content.current.length > 0 ? content.current : undefined;
      await onSubmit(value, assignUserId);

      editorHandle.current?.reset();
      content.current = "";
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const handleOnFocus = () => {
    setIsEditorExpanded(true);
    setTimeout(() => editorHandle.current?.focus(), 300);
  };

  return (
    <div
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        borderColor: "rgba(0, 0, 0, 0.12)",
        borderRadius: 4,
        borderStyle: "solid",
        borderWidth: 1,
        padding: 4,
      }}
    >
      {isEditorExpanded ? (
        <MarkdownTextInput ref={editorHandle} onChange={handleOnChange} />
      ) : (
        <Input.TextArea onFocus={handleOnFocus} rows={1} placeholder="Schrijf een bericht" style={{ borderRadius: 4 }} />
      )}
      <div style={{ marginTop: isPubliclyAccessible ? -4 : 12, display: "flex", gap: 12, flexDirection: "column" }}>
        {isPubliclyAccessible && (
          <Alert
            showIcon
            message="Deze melding is zichtbaar voor de klant"
            type="warning"
            style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, marginInline: -0.5 }}
          />
        )}
        <div style={{ display: "flex", justifyContent: "space-between", paddingInline: 4 }}>
          {hasContent && (
            <Button loading={isSubmittingUsing === "post-only" ? submitting : false} onClick={() => handleOnComment()}>
              Plaatsen
            </Button>
          )}
          <div style={{ display: "flex", flexDirection: "row", columnGap: 4 }}>
            <AssignedEmployeePicker
              currentlyAssignedUserId={currentlyAssignedUserId}
              onChange={nextValue => setAssignUserId(nextValue)}
              value={assignUserId}
            />
            <Button
              loading={isSubmittingUsing === "assign-and-post" ? submitting : false}
              onClick={() => handleOnAssignAndComment()}
              type="primary"
            >
              {hasContent ? "Toewijzen en plaatsen" : "Alleen toewijzen"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
