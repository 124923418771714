import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Modal, notification, Skeleton, Tabs, TabsProps, Tag, Typography } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import LocalePicker from "@/components/LocalePicker";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import EmailTemplatesQuery from "../EmailTemplatesQuery";
import EmailEditorTab from "./EmailEditorTab";

interface EmailTemplateEditorProps {
  emailTemplateId: string;
  onClose: () => void;
}

export default function EmailTemplateEditor({ emailTemplateId, onClose }: EmailTemplateEditorProps) {
  const [addTemplateAsync, { loading: isAddLoading }] = useMutation(AddEmailTemplateMutation, {
    refetchQueries: [EmailTemplatesQuery, EmailTemplateQuery],
  });

  const [updateTemplateAsync, { loading: isUpdateLoading }] = useMutation(UpdateEmailTemplateMutation, {
    refetchQueries: [EmailTemplatesQuery, EmailTemplateQuery],
  });

  const { data } = useQuery(EmailTemplateQuery, { variables: { emailTemplateId } });

  const editorTabRefs = React.useRef<Array<React.ElementRef<typeof EmailEditorTab> | null>>([]);
  const [tabItems, setTabItems] = React.useState<EmailTemplateContent[]>([]);

  React.useEffect(() => {
    if (tabItems.length > 0) return;
    setTabItems((data?.emailTemplate.contents ?? []).map((value: any) => ({ ...value, isFromServer: true })));
  }, [data]);

  const handleOnEdit = (event: Parameters<NonNullable<TabsProps["onEdit"]>>[0], action: "add" | "remove") => {
    if ("remove" === action) {
      const removableIndex = tabItems.findIndex(v => v.locale === event);
      if (-1 === removableIndex) return;

      tabItems.splice(removableIndex, 1);
      return setTabItems([...tabItems]);
    }

    setTabItems(current => [
      ...current,
      {
        id: undefined,
        locale: undefined,
        subject: "",
        content: "",
      },
    ]);
  };

  const handleOnSaveTemplates = async () => {
    const updateableItems = editorTabRefs.current.reduce((acc, curr, index) => {
      if (null === curr || false === curr.isChanged) return acc;
      const { id, locale } = tabItems[index];
      if (undefined === locale) return acc;

      return [...acc, { id, locale, ...curr.getEditorValues() }];
    }, [] as Array<{ id: string | undefined; locale: string; subject: string; content: string }>);

    try {
      if (updateableItems.length > 0) {
        await Promise.all(
          updateableItems.map(({ id, ...item }) =>
            undefined === id
              ? addTemplateAsync({ variables: { emailTemplateId, ...item } })
              : updateTemplateAsync({ variables: { emailTemplateId, ...item } })
          )
        );

        notification.success({ message: "E-mail template(s) zijn aangepast" });
      }

      onClose();
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const handleOnSelectLocale = (index: number, locale: string) => {
    setTabItems(current => {
      current[index].locale = locale;
      return [...current];
    });
  };

  return (
    <Modal
      centered
      confirmLoading={isUpdateLoading || isAddLoading}
      onCancel={onClose}
      onOk={handleOnSaveTemplates}
      okText="Opslaan"
      title="E-mail template aanpassen"
      open
      width="90%"
    >
      {undefined === data ? (
        <Skeleton />
      ) : (
        <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
          <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography.Title level={5}>Beschikbare waardes</Typography.Title>
            {data?.emailTemplate.variables.map(variable => (
              <Tag key={variable.key}>
                {variable.key} <br />
                <i style={{ fontSize: 10 }}>{variable.value}</i>
              </Tag>
            ))}
          </div>
          <Tabs
            items={tabItems.map((element, index) => ({
              key: index.toString(),
              label:
                undefined !== element.locale ? (
                  <FormattedMessage id={element.locale} />
                ) : (
                  <LocalePicker onChange={value => handleOnSelectLocale(index, value)} style={{ width: 200 }} />
                ),
              children: (
                <EmailEditorTab
                  ref={e => (editorTabRefs.current[index] = e)}
                  emailTemplateId={emailTemplateId}
                  subject={element.subject}
                  content={element.content}
                />
              ),
              closable: undefined === element.locale,
            }))}
            size="small"
            onEdit={handleOnEdit}
            type="editable-card"
            style={{ flex: 1 }}
          />
        </div>
      )}
    </Modal>
  );
}

interface EmailTemplateContent {
  id: string | undefined;
  locale: string | undefined;
  subject: string;
  content: string;
}

const EmailTemplateQuery = gql`
  query ($emailTemplateId: ID!) {
    emailTemplate(emailTemplateId: $emailTemplateId) {
      id
      name
      locales
      variables {
        key
        value
      }
      contents {
        id
        locale
        subject
        content
      }
    }
  }
`;

const AddEmailTemplateMutation = gql`
  mutation ($emailTemplateId: ID!, $locale: String!, $subject: String!, $content: String!) {
    addEmailTemplateContent(input: { emailTemplateId: $emailTemplateId, locale: $locale, subject: $subject, content: $content }) {
      emailTemplate {
        id
        locales
        contents {
          id
          locale
          subject
          content
        }
      }
    }
  }
`;

const UpdateEmailTemplateMutation = gql`
  mutation ($emailTemplateId: ID!, $locale: String!, $subject: String!, $content: String!) {
    updateEmailTemplateContent(input: { emailTemplateId: $emailTemplateId, locale: $locale, subject: $subject, content: $content }) {
      emailTemplateContent {
        id
        subject
        content
      }
    }
  }
`;
